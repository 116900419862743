import {staffConstants} from '../../constants/staff.constants'

const initialState = {
	isEdit: false,
	editItemId: 0,
	mainTabs: [
		{
			title: 'Опыт работы',
			idx: 0,
		},
		{
			title: 'Отзывы',
			idx: 1,
		},
	],
	mainActiveTabIdx: 0,
	editTabs: [
		{
			title: 'Личная информация',
			idx: 0,
		},
		{
			title: 'Документы',
			idx: 1,
		},
		{
			title: 'Безопасность',
			idx: 2,
		},
		{
			title: 'Назначение',
			idx: 3,
		},
		{
			title: 'Доступы',
			idx: 4,
		},
	],
	activeTabIdx: 0,
	data: {
		fio: '',
		phone_numbers: '',
		bin: '',
		birth_date: '',
		site: '',
		email: '',
		category: '',
		f_address: '',
		password: '',
		newPassword: '',
		newPasswordRepeat: '',
		generalInfo: '',
		equipment: '',
		newDocDate: '',
		newDocNumber: '',
		workExperience: '',
		position: '',
		vacation: '',
		object: '',
		doc: '',
	},
}

const staffReducer = (state = initialState, action) => {
	switch (action.type) {
		case staffConstants.SET_DATA:
			return {
				...state,
				data: action.payload,
			}
		case staffConstants.SET_IS_EDIT:
			return {
				...state,
				isEdit: action.payload,
			}
		case staffConstants.SET_ACTIVE_EDIT_TAB_IDX:
			return {
				...state,
				activeTabIdx: action.payload,
			}
		case staffConstants.SET_ACTIVE_MAIN_TAB_IDX:
			return {
				...state,
				mainActiveTabIdx: action.payload,
			}
		case staffConstants.SET_EDIT_ITEM_ID:
			return {
				...state,
				editItemId: action.payload,
			}
		default:
			return state
	}
}

export default staffReducer
