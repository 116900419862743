import React from 'react'
import '../App.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar/Sidebar'
import Ncalayer from '../components/Ncalayer/Ncalayer'

import {
	Route,
	useLocation,
	Redirect,
} from 'react-router-dom'


const DefaultLayout = ({isMainPage, children}) => {

	const location = useLocation()
	const [isSidebarOpen, setIsSidebarOpen] = React.useState(true)
	const [variant] = React.useState('sidebar')
	const [width, setWidth] = React.useState(0)
	const [height, setHeight] = React.useState(0)


	const updateDimensions = () => {
		setWidth(window.innerWidth)
		setHeight(window.innerHeight)
	}

	let sidebar = null

	const handle = () => {
		setIsSidebarOpen(!isSidebarOpen)
	}

	if (location.pathname !== '/auth' && location.pathname !== '/') {
		sidebar = <Sidebar variant={variant}
			isOpen={isSidebarOpen}
			currentPage={location.pathname}
			onClose={handle} />
	}

	React.useEffect(() => {
		window.addEventListener('resize', updateDimensions)
		return () => {
			window.removeEventListener('resize', updateDimensions)
		}
	}, [])

	return (
		<div>
			<div className={`App wrapper ${isMainPage ? 'main-page' : ''}`}>
				<Header />
				{sidebar}
				<div className={`content ${sidebar ? '' : 'no_sidebar'}`} >
					{children}
				</div>
				<Ncalayer />
				<Footer />
			</div>
		</div>
	)
}

const Default = ({component: Component, privateRoute, isMainPage, ...rest}) => (
	<Route
		{...rest}
		render={props => {
			if (!localStorage.getItem('user') && privateRoute) {
				return <Redirect to={{pathname: '/auth', state: {from: props.location}}} />
			}
			return (
				<DefaultLayout isMainPage={isMainPage}>
					<Component {...props} />
				</DefaultLayout>
			)
		}}
	/>
)

export default Default
