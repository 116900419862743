import React from 'react'
import StaffTable from '../components/Staff/StaffTable'

const Staff = props => (
	<div className='lift__staff_container'>
		<StaffTable isPage={true}></StaffTable>
	</div>
)

export default Staff
