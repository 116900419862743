export const authConstants = {
	SET_AUTH_RESPONSE: 'SET_AUTH_RESPONSE',
	SET_AUTH_DATA: 'SET_AUTH_DATA',
	SET_USER: 'SET_USER',
	SET_REGISTRATION_DATA: 'SET_REGISTRATION_DATA',
	SET_REGISTRATION_SIGN_OK: 'SET_REGISTRATION_SIGN_OK',
	SET_REGISTRATION_SIGN_RESPONSE: 'SET_REGISTRATION_SIGN_RESPONSE',
	SET_AUTH_LOADED: 'SET_AUTH_LOADED',
	GET_DATA_BY_SIGN_SUCCESS: 'GET_DATA_BY_SIGN_SUCCESS',
	GET_DATA_BY_SIGN_STARTED: 'GET_DATA_BY_SIGN_STARTED',
	GET_DATA_BY_SIGN_FAILURE: 'GET_DATA_BY_SIGN_FAILURE',
	REGISTER_SUCCESS: 'REGISTER_SUCCESS',
	REGISTER_STARTED: 'REGISTER_STARTED',
	REGISTER_FAILURE: 'REGISTER_FAILURE',
	AUTH_SUCCESS: 'AUTH_SUCCESS',
	AUTH_STARTED: 'AUTH_STARTED',
	AUTH_FAILURE: 'AUTH_FAILURE',
	SET_AUTH_VIEW: 'SET_AUTH_VIEW',
	AUTH_BY_SIGN_STARTED: 'AUTH_BY_SIGN_STARTED',
	AUTH_BY_SIGN_SUCCESS: 'AUTH_BY_SIGN_SUCCESS',
	AUTH_BY_SIGN_FAILURE: 'AUTH_BY_SIGN_FAILURE',
	SET_LOGOUT: 'SET_LOGOUT',
	SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
}
