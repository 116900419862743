export const validationHelper = {
	checkIIN,
	passwordRules,
	emailRules,
}

function checkIIN(value) {
	let iin = value.toString().toUpperCase().replace(/[^0-9]/g, '')

	const b1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
	const b2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2]
	let a = []
	let controll = 0
	for (var i = 0; i < 12; i++) {
		a[i] = parseInt(iin.substring(i, i + 1))
		if (i < 11) controll += a[i] * b1[i]
	}
	controll %= 11
	if (controll === 10) {
		controll = 0
		for (let j = 0; j < 11; j++)
			controll += a[j] * b2[j]
		controll %= 11
	}
	if (controll !== a[11]) {
		return false
	}

	return true
}

function passwordRules(value) {
	return /(?=.*[0-9])(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]{8,}/g.test(value)
}

function emailRules(value) {
	return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
}
