import React from 'react'
import {Grid, GridItem, Box, Text, SimpleGrid, Icon} from '@chakra-ui/react'
import {MdModeEdit, MdAddCircleOutline, MdStar, MdStarBorder} from 'react-icons/md'
import {useSelector, useDispatch} from 'react-redux'

import {staffActions} from '../redux/actions'
import Edit from '../components/Staff/Edit/Edit'
import Plus from '../components/Icons/Plus'

const StaffProfile = props => {

	const dispatch = useDispatch()

	if (typeof props.match.params.id === 'undefined') {
		console.log('add')
	} else {
		console.log('edit')
		dispatch(staffActions.setIsEdit(true))
	}

	const isEdit = useSelector(state => state.staff.isEdit)
	const mainTabs = useSelector(state => state.staff.mainTabs)
	const activeTabIdx = useSelector(state => state.staff.mainActiveTabIdx)

	const editRef = React.useRef()

	const openEdit = () => {
		if (editRef.current) {
			editRef.current.openModal()
		}
	}

	const setActiveTabIdx = idx => {
		dispatch(staffActions.setActiveMainTabIdx(idx))
	}

	return (
		<div className='lift__profile_container'>
			<Grid
				templateColumns='repeat(12, 1fr)'
				gap={4}
			>
				<GridItem colSpan={9}>
					<Box className='lift_profile__left_slider lift__shadow' height='100%' p={5}>
					</Box>
				</GridItem>
				<GridItem colSpan={3}>
					<Box className='lift_profile__right_side_box lift__shadow'>
						<div className='lift__profile__right_info'>
							<Box className='lift__profile__right_info_count'>
								50
							</Box>
							<Box className='lift__profile__right_info_text' textAlign='center' mt='3'>
								лифтов
							</Box>

							<Box className='lift__profile__right_info_text' textAlign='center' mt='1'>
								<Text color='primary.800' display='inline'>500</Text> лифтов за весь опыт работы
							</Box>

						</div>
					</Box>
				</GridItem>
			</Grid>

			<Grid
				templateColumns='repeat(12, 1fr)'
				gap={4}
				mt={4}
				mb={4}
			>
				<GridItem colSpan={9}>
					<Box className='lift__profile__left_tabs' mb='4'>
						{mainTabs.map(tab =>
							<button key={tab.idx} className={`lift__profile__left_tab_button ${activeTabIdx === tab.idx && 'active'}`}
								onClick={() => setActiveTabIdx(tab.idx)}>
								{tab.title}
							</button>,
						)}
					</Box>

					{activeTabIdx === 0
						? (<Box className='lift__profile_left_container lift__shadow' >
							<Box className='lift__profile_left_block lift__profile_left_info_container'>
								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										Контакты
									</div>

									<div className='lift__profile_left_info_text'>
										<p>+ (7172) 61-57-57</p>
										<p>+ (701) 202 92 04</p>
										<p>info@lsgroup.kz</p>
									</div>
								</div>

								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										Опыт работы
									</div>

									<div className='lift__profile_left_info_text'>
										<p>5 лет</p>
									</div>
								</div>

								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										Основное место работы
									</div>

									<div className='lift__profile_left_info_text'>
										<p>ТОО “ЛС ГРУПП”</p>
									</div>
								</div>

								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										Руководитель
									</div>

									<div className='lift__profile_left_info_text'>
										<p>ГЕСС АЛЕКСЕЙ ВЛАДИМИРОВИЧ</p>
									</div>
								</div>

								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										Регион обслуживания
									</div>

									<div className='lift__profile_left_info_text'>
										<p>г.Астана</p>
									</div>
								</div>

								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										Квалификация
									</div>

									<div className='lift__profile_left_info_text'>
										<p>III Группа</p>
									</div>
								</div>

								{isEdit && <div className='lift__profile_edit' onClick={openEdit}>
									<Icon as={MdModeEdit} />
								</div>
								}
							</Box>

							<Grid
								templateColumns='repeat(12, 1fr)'
								gap={4}
								mt={4}
							>
								<GridItem colSpan={12}>
									<Box className='lift__profile_left_block bottom'>
										<div className='lift__profile_left_simple_table'>
											<div className='lift__profile_left_simple_table_header'>
												с 2012 по текущее время
											</div>
											<div className='lift__profile_left_simple_table_text'>
												<div className='lift__profile_left_simple_table_place'>
													ТОО “ЛС ГРУПП”
												</div>
												<div>
													Электромеханник
												</div>
											</div>
										</div>

										{isEdit && <div className='lift__profile_edit' onClick={openEdit}>
											<Icon as={MdModeEdit} />
										</div>
										}
									</Box>

									<Box className='lift__profile_left_block bottom' mt={2}>
										<div className='lift__profile_left_simple_table'>
											<div className='lift__profile_left_simple_table_header'>
												Образование
											</div>
											<div className='lift__profile_left_simple_table_text'>
												<div>
													Высшее. Евразийский университет
												</div>
											</div>
										</div>

										{isEdit && <div className='lift__profile_edit' onClick={openEdit}>
											<Icon as={MdModeEdit} />
										</div>
										}
									</Box>

									<Box className='lift__profile_left_block bottom' mt={2}>
										<div className='lift__profile_left_simple_table'>
											<div className='lift__profile_left_simple_table_header'>
												2000-2012
											</div>
											<div className='lift__profile_left_simple_table_text'>
												<div className='lift__profile_left_simple_table_place'>
													ТОО “ЛифтМонтажСервис”
												</div>
												<div>
													Электромеханник
												</div>
											</div>
										</div>

										{isEdit && <div className='lift__profile_edit' onClick={openEdit}>
											<Icon as={MdModeEdit} />
										</div>
										}
									</Box>

									<Box className='lift__profile_left_block bottom' mt={2}>
										<div className='lift__profile_left_simple_table'>
											<div className='lift__profile_left_simple_table_header'>
												О себе\Рекомендации
											</div>
											<div className='lift__profile_left_simple_table_text'>
												<div>
													Текст в свободной форме. заполняется самим специалистом
												</div>
											</div>
										</div>

										{isEdit && <div className='lift__profile_edit' onClick={openEdit}>
											<Icon as={MdModeEdit} />
										</div>
										}
									</Box>

									<Box className='lift__profile_left_block bottom' mt={2}>
										<div className='lift__profile_left_simple_table'>
											<div className='lift__profile_left_simple_table_header'>
												Ключевые навыки
											</div>
											<div className='lift__profile_left_simple_table_text'>
												<div className='lift__profile_left_simple_table_block'>
													Лифты
												</div>
												<div className='lift__profile_left_simple_table_block'>
													Монтаж
												</div>

												<div className='lift__profile_left_simple_table_block'>
													Ремонт электроники
												</div>
											</div>
										</div>

										{isEdit && <div className='lift__profile_edit' onClick={openEdit}>
											<Icon as={MdModeEdit} />
										</div>
										}
									</Box>
								</GridItem>

							</Grid>

						</Box>) : activeTabIdx === 1 ? (

							<Grid
								templateColumns='repeat(12, 1fr)'
								gap={4}
								mt={4}
								mb={4}
							>
								<GridItem colSpan={[12, 6, 6, 6]}>
									<Box className='lift__review'>
										<div className='lift__review_header'>
											<div className='lift__review_header_text'>
												Отзыв
											</div>
											<div className='lift__review_header_star'>
												<Icon as={MdStar} className='lift__star active' />
												<span>4.5/5</span>
											</div>
										</div>
										<div className='lift__review_text'>
											<div className='lift__review_text_stars'>
												<Icon as={MdStar} className='lift__star active' />
												<Icon as={MdStar} className='lift__star active' />
												<Icon as={MdStar} className='lift__star active' />
												<Icon as={MdStar} className='lift__star active' />
												<Icon as={MdStarBorder} className='lift__star active' />
											</div>
											<div className='lift__review_text_info'>
												<div className='lift__review_text_avatar'>
													<img src={window.location.origin + '/images/avatar.png'} alt='' />
												</div>
												Спасибо за вашу работу, отлично все прошло.
												Буду рекомендовать вашу компанию, как надежного партнера.
											</div>

											<div className='lift__review_text_author'>
												Имя фамилия автора
											</div>
										</div>
									</Box>
								</GridItem>
							</Grid>

						) : ''
					}

				</GridItem>
				<GridItem colSpan={3}>
					<Box className='lift_profile__right_side_info lift__shadow'>
						<div className='lift_profile__right_side_info_header'>
							Документы
						</div>

						<div className='lift_profile__right_side_info_items' display='flex' justify='center'>
							<SimpleGrid columns={2} spacing={4} mt={7}>
								<Box className='lift_profile__right_side_info_item'>
									<img src={window.location.origin + '/images/cert.png'} alt='' />
									<p>Справка о
										соответствии
										с профстандартами</p>
								</Box>
								<Box className='lift_profile__right_side_info_item'>
									<img src={window.location.origin + '/images/cert.png'} alt='' />
									<p>Аттестат на
										право монтажа</p>
								</Box>
							</SimpleGrid>
						</div>
					</Box>
				</GridItem>
			</Grid>

			<Edit ref={editRef} isEdit={true} />
		</div>
	)

}

export default StaffProfile
