import {Base64} from 'js-base64'
import {passportConstants} from '../../constants/passport.constants'
import {passportService} from '../../services'

export const passportActions = {
	setSection,
	addField,
	getPassportList,
	setPassportById,
	addPassport,
	setItems,
	getPassportById,
	setSectionDataInitial,
	setCurrentPassportId,
	setActivePassportEditTabIdx,
	setActivePassportActEditTabIdx,
	setPassportActInfoData,
	setPassportActSpecificationsData,
	setPassportActStatusData,
	getPassportActPreviewHtml,
	getPassportActsList,
}

function setSection(payload) {
	return {
		type: passportConstants.SET_SECTION,
		payload: {
			property: payload.property,
			values: payload.values,
		},
	}
}

function addField(payload) {
	return {
		type: passportConstants.ADD_FIELD,
		payload: {
			section: payload.section,
		},
	}
}


function setItems(payload) {
	return {
		type: passportConstants.ADD_FIELD,
		payload: {
			section: payload.section,
		},
	}
}

function setSectionDataInitial() {
	return {
		type: passportConstants.SET_SECTION_DATA_INITITAL,
	}
}

function setCurrentPassportId(payload) {
	return {
		type: passportConstants.SET_CURRENT_PASSPORT_ID,
		payload,
	}
}

function getPassportList(payload) {
	return dispatch => {
		dispatch(request(payload))

		passportService.getPassportList(payload).then(
			res => {
				if (res && res.data.code === '0') {
					dispatch(success({
						items: res.data.data,
					}))
				} else {
					dispatch(failure('Что то пошло не так'))
				}


			},
			error => {
				dispatch(failure(error.toString()))
			},
		)

		function request(requestPayload) { return {type: passportConstants.GET_PASSPORT_LIST_STARTED, payload: requestPayload} }
		function success(successPayload) { return {type: passportConstants.GET_PASSPORT_LIST_SUCCESS, payload: successPayload} }
		function failure(failurePayload) { return {type: passportConstants.GET_PASSPORT_LIST_FAILURE, payload: failurePayload} }
	}
}

function getPassportById(payload) {
	return (dispatch, getState) => {
		dispatch(request(payload))

		passportService.getPassportById(payload).then(
			res => {
				if (res && res.data.code === '0') {
					console.log('JSON.parse(Base64.decode(res.data.data.content))', JSON.parse(Base64.decode(res.data.data.content)))
					dispatch(success({
						currentPassportData: {
							id: res.data.data.id,
							changed: res.data.data.changed,
							created: res.data.data.created,
							createdUserId: res.data.data.createdUserId,
							createdUser: res.data.data.createdUser,
							editedUserId: res.data.data.editedUserId,
							editedUser: res.data.data.editedUser,
							dictStatusId: res.data.data.dictStatusId,
							dictStatus: res.data.data.dictStatus,
						},
						sectionData: JSON.parse(Base64.decode(res.data.data.content)),
					}))
				} else {
					dispatch(failure('Что то пошло не так'))
				}
			},
			error => {
				dispatch(failure(error.toString()))
			},
		)

		function request(requestPayload) { return {type: passportConstants.GET_PASSPORT_BY_ID_STARTED, payload: requestPayload} }
		function success(successPayload) { return {type: passportConstants.GET_PASSPORT_BY_ID_SUCCESS, payload: successPayload} }
		function failure(failurePayload) { return {type: passportConstants.GET_PASSPORT_BY_ID_FAILURE, payload: failurePayload} }
	}
}

function addPassport(payload, toast) {

	return (dispatch, getState) => {
		payload = {
			...payload,
			createdUserId: getState().auth.user.id,
			sectionData: getState().passport.sectionData,
		}

		dispatch(request(payload))

		passportService.setPassport(payload).then(
			res => {
				if (res) {
					console.log('add passport success')
					console.log(res)
					dispatch(success())

					toast({
						title: 'Паспорт',
						description: 'Элемент успешно добавлен',
						status: 'success',
						duration: 4000,
						isClosable: true,
					})

					dispatch(setSectionDataInitial())
				} else {
					dispatch(failure('Что то пошло не так'))
				}
			},
			error => {
				dispatch(failure(error.toString()))
				toast({
					title: 'Паспорт',
					description: error.toString(),
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			},
		)

		function request(requestPayload) { return {type: passportConstants.SET_PASSPORT_BY_ID_STARTED, payload: requestPayload} }
		function success(successPayload) { return {type: passportConstants.SET_PASSPORT_BY_ID_SUCCESS, payload: successPayload} }
		function failure(failurePayload) { return {type: passportConstants.SET_PASSPORT_BY_ID_FAILURE, payload: failurePayload} }
	}
}

function setPassportById(payload, toast) {

	return (dispatch, getState) => {
		payload = {
			...payload,
			passportId: getState().passport.currentPassportId,
			sectionData: getState().passport.sectionData,
		}

		const authToken = getState().auth.authToken

		dispatch(request(payload))

		passportService.setPassport(payload, authToken).then(
			res => {
				if (res) {
					console.log('set passport by ID success')
					console.log(res)
					dispatch(success())

					toast({
						title: 'Паспорт',
						description: 'Элемент успешно сохранен',
						status: 'success',
						duration: 4000,
						isClosable: true,
					})
				} else {
					dispatch(failure('Что то пошло не так'))
				}
			},
			error => {
				dispatch(failure(error.toString()))
				toast({
					title: 'Паспорт',
					description: error.toString(),
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			},
		)

		function request(requestPayload) { return {type: passportConstants.SET_PASSPORT_BY_ID_STARTED, payload: requestPayload} }
		function success(successPayload) { return {type: passportConstants.SET_PASSPORT_BY_ID_SUCCESS, payload: successPayload} }
		function failure(failurePayload) { return {type: passportConstants.SET_PASSPORT_BY_ID_FAILURE, payload: failurePayload} }
	}
}

function setActivePassportEditTabIdx(payload) {
	return {
		type: passportConstants.SET_ACTIVE_PASSPORT_EDIT_TAB_IDX,
		payload,
	}
}

function setActivePassportActEditTabIdx(payload) {
	return {
		type: passportConstants.SET_ACTIVE_PASSPORT_ACT_EDIT_TAB_IDX,
		payload,
	}
}

function setPassportActInfoData(payload) {
	return {
		type: passportConstants.SET_PASSPORT_ACT_INFO_DATA,
		payload: {
			data: payload.data,
		},
	}
}

function setPassportActSpecificationsData(payload) {
	return {
		type: passportConstants.SET_PASSPORT_ACT_SPECIFICATIONS_DATA,
		payload: {
			data: payload.data,
		},
	}
}

function setPassportActStatusData(payload) {
	return {
		type: passportConstants.SET_PASSPORT_ACT_STATUS_DATA,
		payload: {
			data: payload.data,
		},
	}
}

function getPassportActPreviewHtml(payload, toast) {
	return (dispatch, getState) => {
		dispatch(request(payload))

		const passportAct = {
			passportActInfo: getState().passport.passportActInfoData,
			passportActSpecification: getState().passport.passportActSpecificationData,
		}

		passportService.getPassportActPreviewHtml(passportAct).then(
			res => {
				if (res && res.data) {
					dispatch(success({
						passportActsPreviewData: res.data,
					}))
				} else {
					dispatch(failure('Что то пошло не так'))
				}
			},
			error => {
				dispatch(failure(error.toString()))
			},
		)

		function request(requestPayload) { return {type: passportConstants.GET_PASSPORT_ACT_PREVIEW_STARTED, payload: requestPayload} }
		function success(successPayload) { return {type: passportConstants.GET_PASSPORT_ACT_PREVIEW_SUCCESS, payload: successPayload} }
		function failure(failurePayload) { return {type: passportConstants.GET_PASSPORT_ACT_PREVIEW_FAILURE, payload: failurePayload} }
	}
}

function getPassportActsList(payload) {
	return dispatch => {
		dispatch(request(payload))

		passportService.getPassportActsList(payload).then(
			res => {
				if (res && res.data.code === '0') {
					dispatch(success({
						items: res.data.data,
					}))
				} else {
					dispatch(failure('Что то пошло не так'))
				}


			},
			error => {
				dispatch(failure(error.toString()))
			},
		)

		function request(requestPayload) { return {type: passportConstants.GET_PASSPORT_ACTS_LIST_STARTED, payload: requestPayload} }
		function success(successPayload) { return {type: passportConstants.GET_PASSPORT_ACTS_LIST_SUCCESS, payload: successPayload} }
		function failure(failurePayload) { return {type: passportConstants.GET_PASSPORT_ACTS_LIST_FAILURE, payload: failurePayload} }
	}
}


