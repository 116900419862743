import React from 'react'
import {
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	FormErrorMessage,
	SimpleGrid,
	Textarea,
	Box,
	Button,
} from '@chakra-ui/react'
import {useSelector, useDispatch} from 'react-redux'
import {Formik, Form, Field} from 'formik'

import Plus from '../../../components/Icons/Plus'

const GeneralInfo = props => {
	const dispatch = useDispatch()
	const data = useSelector(state => state.profile.data)

	const submit = submitData => {
		console.log(submitData)
	}

	return (
		<>

			<Formik
				initialValues={data}
				enableReinitialize

				onSubmit={(values, actions) => {
					setTimeout(() => {
						submit(values)
						actions.setSubmitting(false)
					}, 1000)
				}}
			>
				<Form className='lift__profile_edit_formik'>
					<SimpleGrid columns={2} spacing={4} mt={4}>
						<div>

							<Field name='generalInfo'>
								{({
									field,
									form: {touched, errors},
								}) => (
									<FormControl isInvalid={errors.generalInfo && touched.generalInfo} mt={4}>
										<FormLabel>Общая информация</FormLabel>
										<Textarea type='text' placeholder='' bg='white' autoComplete='off' {...field} />
										<FormErrorMessage>{errors.generalInfo}</FormErrorMessage>
									</FormControl>
								)}
							</Field>

							<Field name='equipment'>
								{({
									field,
									form: {touched, errors},
								}) => (
									<FormControl isInvalid={errors.equipment && touched.equipment} mt={4}>
										<FormLabel>Оборудования</FormLabel>
										<InputGroup>
											<Input type='text' placeholder='' bg='white' autoComplete='off' {...field} />
										</InputGroup>
										<FormErrorMessage>{errors.equipment}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
						</div>
					</SimpleGrid>

					<Box mt='4' display='flex' justifyContent='flex-end'>
						<Button type='submit' colorScheme='blue' mr={3} isLoading={props.isSubmitting}>
						Сохранить
						</Button>
					</Box>
				</Form>
			</Formik>
		</>
	)
}

export default GeneralInfo
