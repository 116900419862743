import React, {useEffect, useState} from 'react'
import './App.css'
import Default from './layouts/Default'
import Auth from './pages/Auth'
import Main from './pages/Main'
import Equipment from './pages/Equipment'
import Passport from './pages/Passport'
import Profile from './pages/Profile'
import Staff from './pages/Staff'
import StaffProfile from './pages/StaffProfile'
import Requests from './pages/Requests'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {useSelector, useDispatch} from 'react-redux'
import {authActions} from './redux/actions'

const App = props => {
	const dispatch = useDispatch()
	const user = useSelector(state => state.auth.user)
	const company = useSelector(state => state.auth.company)

	if (localStorage.getItem('user') && localStorage.getItem('company') && (!user || !company || !company.name)) {
		localStorage.clear()
		dispatch(authActions.logout())
	}

	// 860405401153

	return (
		<Router>
			<Switch>
				{
					(!user || user && !user.id) && <Route exact path='/'><Redirect to='/auth' /></Route>
				}
				<Default exact path='/auth' component={Auth} privateRoute={false} />
				{
					user && user.id > 0 && <>
						<Default exact path='/' component={Main} privateRoute={true} isMainPage={true} />
						<Default exact path='/equipment' component={Equipment} privateRoute={true} />
						<Default exact path='/passport/:type/:id?' component={Passport} privateRoute={true} />
						<Default exact path='/profile' component={Profile} privateRoute={true} />
						<Default exact path='/profile/edit/:id?' component={Profile} privateRoute={true} />
						<Default exact path='/staff' component={Staff} privateRoute={true} />
						<Default exact path='/staff/profile/:id?' component={StaffProfile} privateRoute={true} />
						<Default exact path='/requests' component={Requests} privateRoute={true} />
					</>
				}
				<Redirect from='*' to='/' />
			</Switch>
		</Router>
	)
}

export default App
