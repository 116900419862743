import React from 'react'
import {
	Box,
	Drawer,
	DrawerOverlay,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	DrawerContent,
	Avatar,
	Text,
	Icon,
} from '@chakra-ui/react'
import {
	Link as RouterLink,
} from 'react-router-dom'
import {MdViewQuilt, MdRecentActors, MdStorage, MdWork} from 'react-icons/md'
import {useSelector} from 'react-redux'

const Sidebar = props => {
	const user = useSelector(state => state.auth.user)
	const company = useSelector(state => state.auth.company)

	const SidebarContent = <div>
		<div className='lift___sidebar_user_container' mt={3}>
			<Avatar src='' className='lift__sidebar_user_avatar' mr={3} width='48px' height='48px' bg='#C2C2C2' />
			{
				!user || !company
					? (
						<div>
							<Text borderBottom={1} borderColor='gray.300' borderBottomStyle='solid' className='lift__sidebar_user_name' color='primary.800'>Саулембеков Е.А.</Text>
							<Text className='lift__sidebar_user_place' mt={1}></Text>
						</div>
					)
					: (
						<div>
							<Box borderBottom={1} borderColor='gray.300' borderBottomStyle='solid' className='lift__sidebar_user_name' color='primary.800'>
								<RouterLink to={`/profile/edit/${user.id}`}>
									<Text className='lastname'>
										<span>{user.name} </span>
										<span className='firstname'>{user.surname[0] ? user.surname[0] + '. ' : ' '}</span>
										<span className='middlename'>{user.patronym[0] ? user.patronym[0] + '. ' : ' '}</span>
									</Text>
								</RouterLink>
							</Box>
							{
								company
									? <Text className='lift__sidebar_user_place' mt={1}>{company.name.replaceAll(company.name.match(/"+/), '')}</Text>
									: <Text className='lift__sidebar_user_place' mt={1}></Text>

							}

						</div>
					)
			}
		</div>

		<div className='lift__menu'>
			<div className='lift__menu_container'>
				<div className='lift__menu_item'>
					<Box className='lift__menu_item_link' color='primary.800'>
						<Icon as={MdViewQuilt} className='lift__menu_item_icon' /> Реестр оборудовании
					</Box>
					<RouterLink to='/equipment'>
						<Box
							className={`lift__menu_sub_item ${props.currentPage == '/equipment' ? 'active' : ''}`}
							bg={props.currentPage == '/equipment' ? 'primary.500' : null}
							color={props.currentPage == '/equipment' ? 'white' : null} >
							Лифты
						</Box>
					</RouterLink>
					{/* <RouterLink to="/equipment">
                        <Box className="lift__menu_sub_item" bg="primary">Экскалаторы</Box>
                    </RouterLink> */}
				</div>
				<div className='lift__menu_item'>
					<RouterLink to='/staff'>
						<Box
							className='lift__menu_item_link'
							bg={props.currentPage == '/staff' ? 'primary.500' : null}
							color={props.currentPage == '/staff' ? 'white' : null}>
							<Icon as={MdRecentActors} className='lift__menu_item_icon' /> Специалисты
						</Box>
					</RouterLink>
				</div>
				<div className='lift__menu_item'>
					<Box
						className='lift__menu_item_link'
						bg={props.currentPage == '/123' ? 'primary.500' : null}
						color={props.currentPage == '/123' ? 'white' : null}>
						<Icon as={MdStorage} className='lift__menu_item_icon' /> Аналитика
					</Box>
				</div>
				<div className='lift__menu_item'>
					<RouterLink to='/requests'>
						<Box
							className='lift__menu_item_link'
							bg={props.currentPage == '/requests' ? 'primary.500' : null}
							color={props.currentPage == '/requests' ? 'white' : null}>
							<Icon as={MdWork} className='lift__menu_item_icon' /> Задачи и заявки
						</Box>
					</RouterLink>
				</div>
			</div>
		</div>
	</div>

	return props.variant === 'sidebar' ? (
		<Box
			position='fixed'
			left={0}
			p={5}
			w='220px'
			top={47}
			h='100%'
			bg='white'
			padding='20px 0'
			className='lift__shadow'
		>
			{SidebarContent}
		</Box>
	) : (
		<Drawer isOpen={props.isOpen} placement='left' onClose={props.onClose}>
			<DrawerOverlay>
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Chakra-UI</DrawerHeader>
					<DrawerBody>
						{SidebarContent}
					</DrawerBody>
				</DrawerContent>
			</DrawerOverlay>
		</Drawer>
	)
}

export default Sidebar
