import React from 'react'
import {
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	FormErrorMessage,
	SimpleGrid,
	Textarea,
	Box,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from '@chakra-ui/react'
import {useSelector, useDispatch} from 'react-redux'
import {Formik, Form, Field} from 'formik'

import Plus from '../../../components/Icons/Plus'

const DocsInfo = props => {
	const dispatch = useDispatch()
	const data = useSelector(state => state.profile.data)

	const {isOpen, onOpen, onClose} = useDisclosure()

	const initialRef = React.useRef()
	const finalRef = React.useRef()

	const submit = submitData => {
		console.log(submitData)
	}

	return (
		<>
			<Box className='lift__profile_edit_add_items'>
				<div className='lift__profile_edit_add_items_plus'>
					<Plus />
				</div>
				<p>Добавить еще документ</p>
			</Box>

			<Formik
				initialValues={data}
				enableReinitialize

				onSubmit={(values, actions) => {
					setTimeout(() => {
						submit(values)
						actions.setSubmitting(false)
					}, 1000)
				}}
			>
				<Form className='lift__profile_edit_formik'>
					<SimpleGrid columns={2} spacing={4} mt={4}>
						<div>
							<Box className='lift__doc_item'>
								<div className='lift__doc_item_header'>
								Аттестат  на право монтажа *
								</div>
								<div className='lift__doc_item_data'>
									<img src={window.location.origin + '/images/cert.png'} alt='' />
									<div className='lift__doc_item_data_plus' onClick={onOpen}>
										<Plus />
									</div>
									<div className='lift__doc_item_data_text'>
										<p>Наименование документа</p>
									</div>
								</div>
							</Box>
							<Box className='lift__doc_item'>
								<div className='lift__doc_item_header'>
								Документ 2 *
								</div>
								<div className='lift__doc_item_data'>
									<img src={window.location.origin + '/images/cert.png'} alt='' />
									<div className='lift__doc_item_data_plus' onClick={onOpen}>
										<Plus />
									</div>
									<div className='lift__doc_item_data_text'>
										<p>Наименование документа</p>
									</div>
								</div>
							</Box>
						</div>
					</SimpleGrid>

					<Box mt='4' display='flex' justifyContent='flex-end'>
						<Button type='submit' colorScheme='blue' mr={3} isLoading={props.isSubmitting}>
						Сохранить
						</Button>
					</Box>
				</Form>
			</Formik>

			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
				size='sm'
			>
				<ModalOverlay />
				<ModalContent>

					<ModalHeader>Данные документа</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>

						<Formik
							initialValues={data}
							enableReinitialize

							onSubmit={(values, actions) => {
								setTimeout(() => {
									submit(values)
									actions.setSubmitting(false)
								}, 1000)
							}}
						>
							<Form className='lift__profile_edit_formik'>
								<div>

									<Box className='lift__doc_item'>
										<div className='lift__doc_item_header'>
										Срок действия (период)
										</div>
										<div className='lift__doc_item_data'>
											<img src={window.location.origin + '/images/cert.png'} alt='' />
											<div className='lift__doc_item_data_plus' onClick={onOpen}>
												<Plus />
											</div>
											<div className='lift__doc_item_data_text'>
												<p>Наименование документа</p>
											</div>
										</div>
									</Box>

									<Field name='newDocDate'>
										{({
											field,
											form: {touched, errors},
										}) => (
											<FormControl isInvalid={errors.newDocDate && touched.newDocDate} mt={4}>
												<FormLabel>Срок действия (период)</FormLabel>
												<InputGroup>
													<Input type='text' placeholder='' bg='white' autoComplete='off' {...field} />
												</InputGroup>
												<FormErrorMessage>{errors.newDocDate}</FormErrorMessage>
											</FormControl>
										)}
									</Field>

									<Field name='newDocNumber'>
										{({
											field,
											form: {touched, errors},
										}) => (
											<FormControl isInvalid={errors.newDocNumber && touched.newDocNumber} mt={4}>
												<FormLabel>Номер документа</FormLabel>
												<InputGroup>
													<Input type='text' placeholder='' bg='white' autoComplete='off' {...field} />
												</InputGroup>
												<FormErrorMessage>{errors.newDocNumber}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
								</div>

								<Box mt='4' display='flex' justifyContent='flex-end'>
									<Button type='submit' colorScheme='blue' mr={3} isLoading={props.isSubmitting}>
									Сохранить
									</Button>
								</Box>
							</Form>
						</Formik>

					</ModalBody>
				</ModalContent>

			</Modal>
		</>
	)
}

export default DocsInfo
