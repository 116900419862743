import React from 'react'
import {Input} from '@chakra-ui/react'

const TextInput = props => props.isEditMode
	? <Input
		autoComplete='off'
		placeholder={props.placeholder || ''}
		value={props.itemValue}
		onChange={e => props.onChange(e.target.value)} />
	: !props.itemValue
		? <div>-</div>
		: props.itemValue

export default TextInput
