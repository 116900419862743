import axios from 'axios'
import {apiConstants} from '../constants/api.constants'

export const passportService = {
	getPassportList,
	getPassportById,
	setPassport,
	getPassportActPreviewHtml,
	getPassportActsList,
	setPassportAct,
	getPassportActById,
	sendPassportActToSign,
	signPassportAct,
	rejectPassportAct,
	getNextActNumber,
}

function getPassportList(requestData, authToken) {
	return axios
		.post(`${apiConstants.apiUrl}/api/Elevator/GetPassports`, requestData, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(error => {
			if (error && (error.response && error.response.status == 401 || error.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function getPassportById(requestData, authToken) {
	return axios
		.post(`${apiConstants.apiUrl}/api/Elevator/GetPassportById`, requestData, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function setPassport(requestData, authToken) {
	return axios
		.post(`${apiConstants.apiUrl}/api/Elevator/SavePassport`, requestData, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function getPassportActPreviewHtml(requestData, authToken) {
	return axios
		.post(`${apiConstants.apiUrl}/api/Elevator/GetPassportActPreviewHtml`, requestData, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function getPassportActsList(requestData, authToken) {
	return axios
		.post(`${apiConstants.apiUrl}/api/Elevator/GetPassportActs`, requestData, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function setPassportAct(requestData, authToken) {
	return axios
		.post(`${apiConstants.apiUrl}/api/Elevator/SavePassportAct`, requestData, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function getPassportActById(id, authToken) {
	return axios
		.get(`${apiConstants.apiUrl}/api/Elevator/GetPassportActById?id=${id}`, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function sendPassportActToSign(requestData, authToken) {
	return axios
		.post(`${apiConstants.apiUrl}/api/Elevator/SendPassportActToSign`, requestData, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function signPassportAct(requestData, authToken) {
	return axios
		.post(`${apiConstants.apiUrl}/api/Elevator/SignPassportAct`, requestData, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function rejectPassportAct(requestData, authToken) {
	return axios
		.post(`${apiConstants.apiUrl}/api/Elevator/RejectPassportAct`, requestData, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function getNextActNumber(authToken) {
	return axios
		.get(`${apiConstants.apiUrl}/api/Elevator/GetNextActNumber`, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}
