import React, {useState} from 'react'
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Icon,
	Spinner,
	Alert,
	AlertIcon,
	IconButton,
	useToast,
	useDisclosure,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from '@chakra-ui/react'
import {AddIcon} from '@chakra-ui/icons'
import {MdExpandMore, MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'
import EditActsModal from './EditActsModal'
import {passportActions} from '../../../redux/actions'

const EditActs = React.forwardRef((props, ref) => {
	const toast = useToast()
	const dispatch = useDispatch()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [selectedPassportAct, setSelectedPassportAct] = useState(null)
	const [selectedActType, setSelectedActType] = useState(null)

	const passportActsItems = useSelector(state => state.passport.passportActsItems)
	const getPassportActsListLoading = useSelector(state => state.passport.getPassportActsListLoading)
	const getPassportActsListLoaded = useSelector(state => state.passport.getPassportActsListLoaded)
	const authToken = useSelector(state => state.auth.authToken)
	const isPage = props.isPage

	const {isOpen, onOpen, onClose} = useDisclosure()

	React.useEffect(() => {
		let payload = {
			passportId: props.match.params.id,
		}
		dispatch(passportActions.getPassportActsList(payload, authToken))
	}, [])

	const openActsEdit = (value, actType) => {
		if (props.match.params.id) {
			setSelectedPassportAct(value || null)
			setSelectedActType(actType)
			setIsOpenModal(true)
		} else {
			toast({
				title: 'Акты',
				description: 'Необходимо сохранить паспорт лифта',
				status: 'error',
				duration: 4000,
				isClosable: true,
			})
		}
		return false
	}

	const setDateTimeFormat = date => {
		if (date) {
			return moment(date.toString()).format('DD.MM.YYYY HH:mm')
		} else {
			return '-'
		}
	}

	const getActTypeName = actType => {
		switch (actType) {
			case 1:
				return 'Акт приемки лифта в эксплуатацию'
			default:
				return '-'
		}
	}

	const getStatusName = (status, passportActApprovements) => {
		if (status && status.name) {
			return status.name
		} else {
			return '-'
		}
	}

	const onModalClose = () => {
		let payload = {
			passportId: props.match.params.id,
		}
		dispatch(passportActions.getPassportActsList(payload, authToken))
		setIsOpenModal(false)
		setSelectedActType(null)
		setSelectedPassportAct(null)
	}

	return (
		<>
			<div className='lift__passport_acts_edit'>
				<Box className='lift__passport_content' p='4' bg='white' mb={4} mt={3}>
					<Box className='lift__passport_acts_left_block lift__passport_acts_left_info_container'>
						{/* <IconButton variant='outline' aria-label='Добавить подписанта' icon={<AddIcon />} onClick={() => { openActsEdit() }}/> */}
						<Menu>
							<MenuButton
								as={IconButton}
								aria-label='Options'
								icon={<AddIcon />}
								variant='outline'
							/>
							<MenuList>
								<MenuItem onClick={() => { openActsEdit(null, 1) }}>
									Акт технической готовности лифта
								</MenuItem>
								<MenuItem>
									Акт 2
								</MenuItem>
								<MenuItem>
									Акт 3
								</MenuItem>
							</MenuList>
						</Menu>
					</Box>
				</Box>
			</div>

			<Box className={`lift__profile_left_block lift__shadow ${!isPage && 'lift__profile_staff'}`}>
				<Box bg='white' borderRadius='4px' mt={5} mb={5}>
					{getPassportActsListLoading && <Box p={4} textAlign='center'>
						<Spinner />
					</Box>
					}

					{
						!getPassportActsListLoading && getPassportActsListLoaded && <div>
							<Table variant='simple' className='lift__eq_table'>
								<Thead>
									<Tr>
										<Th isNumeric width='20px'>№</Th>
										<Th>Номер <Icon as={MdExpandMore} /></Th>
										<Th>Наименование <Icon as={MdExpandMore} /></Th>
										<Th>Дата создания <Icon as={MdExpandMore} /></Th>
										<Th>Редактор <Icon as={MdExpandMore} /></Th>
										<Th>Статус </Th>
									</Tr>
								</Thead>
								<Tbody>
									{
										passportActsItems.length > 0
											? passportActsItems.map((x, idx) => (
												<Tr key={idx} onClick={() => openActsEdit(x, x.passportActType)}>
													<Td isNumeric>{idx + 1}</Td>
													<Td>{x.number ? x.number : '-'}</Td>
													<Td>{getActTypeName(x.passportActType)}</Td>
													<Td>{setDateTimeFormat(x.created)}</Td>
													<Td>{x.createdUser && x.createdUser.fullname ? x.createdUser.fullname : '-'}</Td>
													<Td>{getStatusName(x.dictStatus, x.passportActApprovements) && x.dictStatus.name ? x.dictStatus.name : '-'}</Td>
												</Tr>
											))
											: <Tr>
												<Td colSpan='6'>
													<Alert status='info'>
														<AlertIcon />
														На данный момент список пуст
													</Alert>
												</Td>
											</Tr>
									}

								</Tbody>
							</Table>
						</div>
					}
					{
						!getPassportActsListLoading && getPassportActsListLoaded && passportActsItems.length > 0 && <Box mt={5} mb={5} className='lift__pagination'>
							<Box borderRadius='4px' className='lift__pagination_item'>
								<Icon as={MdKeyboardArrowLeft} className='lift__eq_filter_icon' />
							</Box>
							{/* <Box borderRadius="4px" className="lift__pagination_item">1</Box>
						<Box borderRadius="4px" className="lift__pagination_item">2</Box> */}
							<Box borderRadius='4px' className='lift__pagination_item active' border='1px' borderStyle='solid' borderColor='primary.500' color='primary.500'>1</Box>
							{/* <Box borderRadius="4px" className="lift__pagination_item">4</Box>
						<Box borderRadius="4px" className="lift__pagination_item">5</Box> */}
							<Box borderRadius='4px' className='lift__pagination_item'>
								<Icon as={MdKeyboardArrowRight} className='lift__eq_filter_icon' />
							</Box>
						</Box>
					}

					{!getPassportActsListLoading && !getPassportActsListLoaded && passportActsItems.length === 0 && (
						<Box>
							<Alert status='info'>
								<AlertIcon />
									Упс.. Что-то пошло не так. Не удалось получить данные с сервера. Попробуйте позже
							</Alert>

						</Box>
					)}
				</Box>

				{
					isOpenModal && <EditActsModal
						selectedPassportAct={selectedPassportAct}
						selectedActType={selectedActType}
						isOpenModal={isOpenModal}
						passportId={props.match.params.id}
						onModalClose={onModalClose} />
				}
			</Box>
		</>
	)
})

export default EditActs
