import React, {useEffect, useState} from 'react'
import {
	Grid,
	GridItem,
	Box,
	Spinner,
	useToast,
} from '@chakra-ui/react'
import {ThemeProvider, createTheme} from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import ruLocale from 'date-fns/locale/ru'
import {
	TextField,
	Button,
	Link,
} from '@mui/material'
import {
	Link as RouterLink,
	useHistory,
} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'
import {requestsService} from '../services'

moment.locale('ru')

const theme = createTheme({
	palette: {
		mode: 'light',
	},
})

const Main = () => {
	const [newRequestsCount, setNewRequestsCount] = useState(0)
	const [requestsCount, setRequestsCount] = useState(0)
	const [loading, setLoading] = useState(true)
	const [dateValue, setDateValue] = useState(new Date())
	const authToken = useSelector(state => state.auth.authToken)
	const company = useSelector(state => state.auth.company)
	const history = useHistory()
	const toast = useToast()

	useEffect(() => {
		requestsService.getRequestsCount(authToken).then(res => {
			if (res.data.code === '0') {
				setNewRequestsCount(res.data.data || 0)
				setRequestsCount(res.data.totalRecords || 0)
			} else {
				toast({
					title: 'Получение количества заявок',
					description: res.data.message,
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			}
			setLoading(false)
		},
		error => {
			console.log(error)
			setLoading(false)
		})
	}, [])

	const setDateTimeFormat = (date, format) => {
		if (date) {
			return moment(date.toString()).format(format || 'DD.MM.YYYY HH:mm')
		} else {
			return '-'
		}
	}

	return (
		<Grid
			templateColumns='repeat(12, 1fr)'
			gap={4}
			mt={4}
			mb={4}
		>
			{
				loading && <Box p={4} textAlign='center'>
					<Spinner />
				</Box>
			}
			<GridItem colSpan={2}>
			</GridItem>
			<GridItem colSpan={8}>
				<Box className='lift__shadow' >

					<Grid
						templateColumns='repeat(12, 1fr)'
						gap={4}
						mt={4}
					>
						<GridItem colSpan={4}>
							<Box className='lift__profile_left_block bottom' mt={4} style={{overflow: 'hidden', overflowX: 'auto'}}>
								<ThemeProvider theme={theme}>
									<LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
										<Button fullWidth variant='outlined' onClick={() => setDateValue(new Date())}>
											Сегодня
										</Button>
										<StaticDatePicker
											displayStaticWrapperAs='desktop'
											label='Week picker'
											showTodayButton={true}
											value={dateValue}
											minDate={new Date('2020-01-01')}
											maxDate={new Date('2050-01-01')}
											onChange={val => setDateValue(val)}
											renderInput={params => <TextField fullWidth {...params} />}
										/>
									</LocalizationProvider>
								</ThemeProvider>
							</Box>
						</GridItem>
						<GridItem colSpan={4}>
							<Box className='lift__profile_left_block bottom' mt={4}>
								<div className='lift__profile_left_simple_header' style={{cursor: 'pointer'}}>
									<RouterLink to='/requests'>
										<ThemeProvider theme={theme}>
											<Link underline='hover'>
												Заявки и задачи
											</Link>
										</ThemeProvider>
									</RouterLink>
								</div>
								<div className='lift__profile_left_simple_info'>
									<RouterLink to='/requests'>
										<ThemeProvider theme={theme}>
											<Link underline='hover'>
												{
													newRequestsCount
												}
												<span>/</span>
												{
													requestsCount
												}
											</Link>
										</ThemeProvider>
									</RouterLink>
								</div>
							</Box>
							<Box className='lift__profile_left_block bottom' mt={4}>
								<div className='lift__profile_left_simple_header'>
									Новости
								</div>
								<div className='lift__profile_left_simple_info'>

								</div>
							</Box>
						</GridItem>
						<GridItem colSpan={4}>
							<Box className='lift__profile_left_block bottom' mt={4}>
								<div className='lift__profile_left_simple_header'>
									Акты
								</div>
								<div className='lift__profile_left_simple_info'>

								</div>
							</Box>
							<Box className='lift__profile_left_block bottom' mt={4}>
								<div className='lift__profile__right_info'>
									<Box className='lift__profile__right_info_count' mt={6}>
										300
										<span style={{left: 'inherit'}}>/ 5000</span>
									</Box>
									<Box className='lift__profile__right_info_count' mt={6} style={{fontSize: '14px'}}>
										Специалистов
									</Box>
								</div>
							</Box>
							<Box className='lift__profile_left_block bottom' mt={4}>
								<div className='lift__profile__right_info'>
									<Box className='lift__profile__right_info_count'>
										15000
										<span style={{left: 'inherit'}}>/ 25000</span>
									</Box>
									<Box className='lift__profile__right_info_text' textAlign='center' mt='3'>
										лифтов
									</Box>
								</div>
							</Box>
							<Box className='lift__profile_left_block bottom' mt={4}>
								<div className='lift__profile_left_simple_header'>
								Доп
								</div>
								<div className='lift__profile_left_simple_info'>

								</div>
							</Box>
						</GridItem>
					</Grid>

				</Box>
			</GridItem>
		</Grid>
	)

}

export default Main
