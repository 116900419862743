import React, {useEffect, useState} from 'react'
import {
	Box,
	FormControl,
	FormLabel,
	InputGroup,
	FormErrorMessage,
	SimpleGrid,
	Button,
	IconButton,
	useToast,
} from '@chakra-ui/react'
import {AddIcon, DeleteIcon} from '@chakra-ui/icons'
import {AsyncSelect} from 'chakra-react-select'
import {useSelector, useDispatch} from 'react-redux'
import {Stepper, Step, StepLabel} from '@mui/material'
import {styled, ThemeProvider, createTheme} from '@mui/material/styles'
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector'
import SettingsIcon from '@mui/icons-material/Settings'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import VideoLabelIcon from '@mui/icons-material/VideoLabel'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import {passportActions, userActions} from '../../../redux/actions'
import uuid from 'react-uuid'

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage: 'linear-gradient(95deg, rgba(124, 254, 4, 0.5) 0%, rgba(5, 83, 32, 0.5) 50%, rgba(5, 137, 32, 0.5) 100%)',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage: 'linear-gradient(95deg, rgba(124, 254, 4, 0.5) 0%, rgba(5, 83, 32, 0.5) 50%, rgba(5, 137, 32, 0.5) 100%)',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}))

const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...ownerState.active && {
		backgroundImage: 'linear-gradient(136deg, rgba(124, 254, 4, 0.5) 0%, rgba(5, 83, 32, 0.5) 50%, rgba(5, 137, 32, 0.5) 100%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	...ownerState.completed && {
		backgroundImage: 'linear-gradient(136deg, rgba(124, 254, 4, 0.5) 0%, rgba(5, 83, 32, 0.5) 50%, rgba(5, 137, 32, 0.5) 100%)',
	},
}))

const theme = createTheme({
	palette: {
		mode: 'light',
	},
})

function ColorlibStepIcon(props) {
	const {active, completed, className} = props

	const icons = {
		1: <PersonAddAlt1Icon />,
		2: <PersonRemoveAlt1Icon />,
		4: <PersonRemoveAlt1Icon />,
		// 4: <CheckCircleIcon />,
		5: <VerifiedUserIcon />,
	}

	return (
		<ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
			{icons[String(props.iconType)]}
		</ColorlibStepIconRoot>
	)
}

const EditActsStatus = React.forwardRef((props, ref) => {
	const [isSubmit, setSubmit] = useState(false)
	const [signers, setSigners] = useState([{key: uuid(), value: null, isAdditionalSigner: true}])
	const toast = useToast()
	const passportActsStatusData = useSelector(state => state.passport.passportActsStatusData)
	const authToken = useSelector(state => state.auth.authToken)

	const [step, setStep] = useState(-1)
	const [completed, setCompleted] = useState(false)
	const childrenArray = [{label: '1', step: 0}, {label: '4', step: 1}, {label: '5', step: 2}]
	const [stepsArray, setStepsArray] = useState([])

	React.useImperativeHandle(ref, () => ({
		onTabChange() {
			console.log('signers', signers)
			props.setSigners(signers, null)
		},
	}))

	useEffect(() => {
		if (props.actData && props.actData.id && props.actData.id > 0) {
			if (Array.isArray(props.actData.passportActSigns) && props.actData.passportActSigns.length > 0) {
				let savedStepsArray = [
					...props.actData.passportActSigns.filter(p => p.isAdditionalSigner != true).map(s => ({
						key: s.id,
						dictStatusId: s.dictStatusId,
						label: <span>{s.signerUser.fullname}<br/> {s.dictStatusId != 1 && s.dictStatus ? s.dictStatus.name : ''}</span>,
					})),
				]
				let savedAdditionalSigners = []
				if (props.actData.passportActSigns.some(p => p.isAdditionalSigner == true)) {
					savedAdditionalSigners = [
						...props.actData.passportActSigns.filter(p => p.isAdditionalSigner == true)
							.map(s => ({
								...s,
								key: s.id,
								value: {
									value: s.signerUser.id,
									label: s.signerUser.fullname,
								},
							})),
					]
					savedStepsArray = [
						...savedStepsArray,
						...props.actData.passportActSigns.filter(p => p.isAdditionalSigner == true).map(s => ({
							key: s.id,
							dictStatusId: s.dictStatusId,
							label: s.signerUser.fullname + '\n' + (s.dictStatusId != 1 && s.dictStatus ? s.dictStatus.name : ''),
						})),
					]
				}

				if (Array.isArray(props.actAdditionalSigners) && props.actAdditionalSigners.length > 0
					&& props.actAdditionalSigners.some(a => a.value && a.key.toString().indexOf('-') > -1)) {
					if (props.selectedActType && props.selectedActType > 1) {
						setSigners([
							...savedAdditionalSigners,
							...props.actAdditionalSigners.filter(a => a.value && a.key.toString().indexOf('-') > -1),
						])
					}
					setStepsArray([
						...savedStepsArray,
						...props.actAdditionalSigners.filter(a => a.value && a.key.toString().indexOf('-') > -1).map(s => ({
							key: s.key,
							dictStatusId: 1,
							label: s.value.label,
						})),
					])
				} else {
					if (savedAdditionalSigners.length == 0) {
						savedAdditionalSigners = [{key: uuid(), value: null, isAdditionalSigner: true}]
					}
					if (props.selectedActType && props.selectedActType > 1) {
						setSigners([
							...savedAdditionalSigners,
						])
					}
					setStepsArray([
						...savedStepsArray,
					])
				}

				const filledIx = getIndex(1, props.actData.passportActSigns, 'dictStatusId')
				const toSignIx = getIndex(4, props.actData.passportActSigns, 'dictStatusId')
				const signedIx = getIndex(5, props.actData.passportActSigns, 'dictStatusId')
				if (toSignIx == -1 && filledIx == -1) {
					setStep(props.actData.passportActSigns.length - 1)
				} else if (signedIx != props.actData.passportActSigns.length - 1) {
					setStep(signedIx)
				} else {
					setStep(-1)
				}
			} else {
				let steps = [{
					dictStatusId: props.actData.dictStatusId,
					label: props.actData.dictStatus
						? <span>{props.actData.electromechanic}<br/> {props.actData.dictStatus.label}</span>
						: <span>{props.actData.electromechanic}<br/> Создал документ</span>,
				}]
				if (props.actData.installationOrganizationRepresentative) {
					steps.push({
						dictStatusId: 2,
						label: <span>{props.actData.installationOrganizationRepresentative.label}<br/>На подписании</span>,
					})
				}
				if (props.actData.buildingOrganizationRepresentative) {
					steps.push({
						dictStatusId: 2,
						label: <span>{props.actData.buildingOrganizationRepresentative.label}<br/> На подписании</span>,
					})
				}
				setStepsArray([...steps])
				// setStepsArray([{
				// 	dictStatusId: props.actData.dictStatusId,
				// 	label: props.actData.dictStatus.name,
				// }])
			}
		} else if (Array.isArray(props.actAdditionalSigners) && props.actAdditionalSigners.length > 0
			&& props.actAdditionalSigners.some(a => a.value && a.key.toString().indexOf('-') > -1)) {
			if (props.selectedActType && props.selectedActType > 1) {
				setSigners([...props.actAdditionalSigners.filter(a => a.value && a.key.toString().indexOf('-') > -1)])
			}
			setStepsArray([
				...stepsArray,
				...props.actAdditionalSigners.filter(a => a.value && a.key.toString().indexOf('-') > -1).map(s => ({
					key: s.key,
					dictStatusId: 1,
					label: s.value.label,
				})),
			])
		} else {
			let steps = [{
				dictStatusId: props.actData.dictStatusId,
				label: props.actData.dictStatus
					? <span>{props.actData.electromechanic}<br/> {props.actData.dictStatus.label}</span>
					: <span>{props.actData.electromechanic}<br/> Создал документ</span>,
			}]
			if (props.actData.installationOrganizationRepresentative) {
				steps.push({
					dictStatusId: 2,
					label: <span>{props.actData.installationOrganizationRepresentative.label}<br/>На подписание</span>,
				})
			}
			if (props.actData.buildingOrganizationRepresentative) {
				steps.push({
					dictStatusId: 2,
					label: <span>{props.actData.buildingOrganizationRepresentative.label}<br/> На подписание</span>,
				})
			}
			setStepsArray([...steps])
		}
	}, [])

	const getIndex = (value, arr, prop) => {
		for (var i = 0; i < arr.length; i++) {
			if (arr[i][prop] === value) {
				return i
			}
		}
		return -1 //to handle the case where the value doesn't exist
	}

	const onNextClick = () => {
		setSubmit(true)
		// if (!signers.some(s => !s.value)) {
		props.setSigners(null, 3)
		// }
	}

	const onPrevClick = () => {
		setSubmit(true)
		props.setSigners(null, 1)
	}

	const loadOptions = (searchValue, callback) => {
		setTimeout(async () => {
			callback(await filterUsers(searchValue))
		}, 1000)
	}

	const filterUsers = async searchValue => await userActions.getUsersByFilter({inputFilter: searchValue}, toast, authToken)

	const onAsyncSelectChange = (signerKey, value) => {
		signers.map(signer => {
			if (signer.key == signerKey) {
				signer.value = value
			}
			return signer
		})
		setSigners([...signers])
		if (value) {
			setStepsArray([...stepsArray, ...signers.filter(s => s.value != null && !stepsArray.some(a => a.key == s.key)).map(s => ({
				key: s.key,
				dictStatusId: 1,
				label: s.value.label, //<span>{s.value.label}<br/> На подписании</span>,
			}))])
		} else {
			setStepsArray([...stepsArray.filter(s => s.key != signerKey)])
		}
		setSubmit(false)
	}

	const onAddClick = () => {
		setSigners([...signers, {key: uuid(), value: null}])
		setSubmit(false)
	}

	const onRemoveClick = signerKey => {
		setSigners([...signers.filter(s => s.key != signerKey)])
		setStepsArray([...stepsArray.filter(s => s.key != signerKey)])
		setSubmit(false)
	}

	return (<>
		{
			props.selectedActType && props.selectedActType > 1 && <SimpleGrid columns={2} spacing={4} mt={4}>
				{
					signers.map((signer, ix) => <FormControl key={`signer-${ix}`} isInvalid={isSubmit && !signer.value}>
						<FormLabel>Добавить еще подписанта</FormLabel>
						<InputGroup>
							<AsyncSelect
								defaultOptions
								isClearable
								isDisabled={props.isDisabled}
								defaultValue={signer.value}
								value={signer.value}
								loadOptions={loadOptions}
								placeholder='Выберите из списка'
								onChange={value => onAsyncSelectChange(signer.key, value)} />
							<div>
								<IconButton
									variant='outline'
									aria-label='Добавить подписанта'
									icon={<AddIcon />}
									onClick={onAddClick}/>
							</div>
							{
								ix != signers.length - 1 && <div>
									<IconButton
										variant='outline'
										aria-label='Удалить подписанта'
										icon={<DeleteIcon />}
										onClick={() => onRemoveClick(signer.key)}/>
								</div>
							}
						</InputGroup>
						<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
					</FormControl>)
				}
			</SimpleGrid>
		}

		<Box mt='12' display='flex' style={{marginTop: '100px', overflow: 'hidden', overflowX: 'auto'}}>
			<ThemeProvider theme={theme}>
				<Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
					{stepsArray.map((child, index) => (
						<Step key={index + child.label} completed={step > index || completed}>
							<StepLabel
								StepIconComponent={stepProps => <ColorlibStepIcon {...stepProps} iconType={child.dictStatusId}></ColorlibStepIcon>}>
								{child.label}
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</ThemeProvider>
		</Box>

		<Box mt='8' display='flex' justifyContent='flex-end'>
			<Button colorScheme='gray' mr={3} onClick={onPrevClick}>
				Назад
			</Button>
			<Button colorScheme='blue' mr={3} onClick={onNextClick}>
				Далее
			</Button>
		</Box>
	</>)
})

export default EditActsStatus
