export const apiConstants = {
	/* eslint-disable*/
	apiUrl: process.env.NODE_ENV == 'development' ? 'http://localhost:5000' : 'https://lsb.lsgroup.kz',
	/* eslint-enable*/
	authHeaders: token => ({
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}),
}
