import React from 'react'
import {
	Grid,
	GridItem,
	Box,
	Text,
	SimpleGrid,
	Icon,
} from '@chakra-ui/react'
import {MdModeEdit, MdAddCircleOutline, MdStar, MdStarBorder} from 'react-icons/md'
import {useSelector, useDispatch} from 'react-redux'

import {profileActions} from '../redux/actions'
import Slider from '../components/Profile/Slider'
import Edit from '../components/Profile/Edit/Edit'
import StaffTable from '../components/Staff/StaffTable'
import Plus from '../components/Icons/Plus'

const Profile = props => {

	const dispatch = useDispatch()

	if (typeof props.match.params.id === 'undefined') {
		console.log('add')
	} else {
		console.log('edit')
		dispatch(profileActions.setIsEdit(true))
	}

	const isEdit = useSelector(state => state.profile.isEdit)
	const mainTabs = useSelector(state => state.profile.mainTabs)
	const activeTabIdx = useSelector(state => state.profile.mainActiveTabIdx)

	const editRef = React.useRef()

	const openEdit = () => {
		if (editRef.current) {
			editRef.current.openModal()
		}
	}

	const setActiveTabIdx = idx => {
		dispatch(profileActions.setActiveMainTabIdx(idx))
	}

	return (
		<div className='lift__profile_container'>
			<Grid
				templateColumns='repeat(12, 1fr)'
				gap={4}
			>
				<GridItem colSpan={9}>
					<Box className='lift_profile__left_slider lift__shadow' p={5}>
						<Slider></Slider>
					</Box>
				</GridItem>
				<GridItem colSpan={3}>
					<Box className='lift_profile__right_side_box lift__shadow'>
						<div className='lift__profile__right_info'>
							<Box className='lift__profile__right_info_count'>
								15000
								<span>/ 25000</span>
							</Box>
							<Box className='lift__profile__right_info_text' textAlign='center' mt='3'>
								лифтов
							</Box>

							<Box className='lift__profile__right_info_text' textAlign='center' mt='1'>
								<Text color='primary.800' display='inline'>4800</Text> партнеров
							</Box>

						</div>
					</Box>
				</GridItem>
			</Grid>

			<Grid
				templateColumns='repeat(12, 1fr)'
				gap={4}
				mt={4}
				mb={4}
			>
				<GridItem colSpan={9}>
					<Box className='lift__profile__left_tabs' mb='4'>
						{mainTabs.map(tab =>
							<button key={tab.idx} className={`lift__profile__left_tab_button ${activeTabIdx === tab.idx && 'active'}`}
								onClick={() => setActiveTabIdx(tab.idx)}>
								{tab.title}
							</button>,
						)}
					</Box>

					{activeTabIdx === 0
						? (<Box className='lift__profile_left_container lift__shadow' >
							<Box className='lift__profile_left_block lift__profile_left_info_container'>
								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										Контакты
									</div>

									<div className='lift__profile_left_info_text'>
										<p>+ (7172) 61-57-57</p>
										<p>+ (701) 202 92 04</p>
										<p>info@lsgroup.kz</p>
									</div>
								</div>

								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										БИН
									</div>

									<div className='lift__profile_left_info_text'>
										<p>090440010146</p>
									</div>
								</div>

								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										Дата регистрации
									</div>

									<div className='lift__profile_left_info_text'>
										<p>2018-11-27</p>
									</div>
								</div>

								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										Руководитель
									</div>

									<div className='lift__profile_left_info_text'>
										<p>ГЕСС АЛЕКСЕЙ ВЛАДИМИРОВИЧ</p>
									</div>
								</div>

								<div className='lift__profile_left_info'>
									<div className='lift__profile_left_info_header'>
										Адрес
									</div>

									<div className='lift__profile_left_info_text'>
										<p>г.Астана, район Сарыарка,
											ул. К. Кумисбекова, 9/1</p>
									</div>
								</div>

								{isEdit && <div className='lift__profile_edit' onClick={openEdit}>
									<Icon as={MdModeEdit} />
								</div>
								}
							</Box>

							<Grid
								templateColumns='repeat(12, 1fr)'
								gap={4}
								mt={4}
							>
								<GridItem colSpan={4}>
									<Box className='lift__profile_left_block bottom'>
										<div className='lift__profile_left_simple_header'>
											Оборудования
										</div>
										<div className='lift__profile_left_simple_info'>
											<ul>
												<li>Траволаторы </li>
												<li>Эскалаторы </li>
												<li>Подъемники для инвалидов </li>
												<li>Грузовые лифты </li>
												<li>Пассажирские лифты </li>
												<li>Панорамные лифты </li>
												<li>Котеджные лифты </li>
												<li> Больничные лифты </li>
											</ul>
										</div>

										{isEdit && <div className='lift__profile_edit' onClick={openEdit}>
											<Icon as={MdModeEdit} />
										</div>
										}
									</Box>

									<Box className='lift__profile_left_block bottom' mt={4}>
										<div className='lift__profile_left_simple_header'>
											Услуги
										</div>
										<div className='lift__profile_left_simple_info'>
											<ul>
												<li>Модернизация кабин</li>
												<li>Сервисное обслуживание </li>
												<li>Монтаж </li>
											</ul>
										</div>
										{isEdit && <div className='lift__profile_edit' onClick={openEdit}>
											<Icon as={MdModeEdit} />
										</div>
										}
									</Box>
								</GridItem>
								<GridItem colSpan={8}>
									<Box className='lift__profile_left_block bottom'>
										<div className='lift__profile_left_simple_header'>
											Партнеры
										</div>
										<div className='lift__profile_left_simple_info'>
											<Box className='lift__profile_left_item'>
												<div className='lift__profile_left_item_index'>
													1
												</div>
												<div className='lift__profile_left_item_img'>
													<div>
														<img src={window.location.origin + '/images/img5.png'} alt='' />
													</div>
												</div>
												<div className='lift__profile_left_item_name'>
													BI Group
												</div>
												<div className='lift__profile_left_item_count'>
													10 лифтов
												</div>
											</Box>

											<Box className='lift__profile_left_item'>
												<div className='lift__profile_left_item_index'>
													2
												</div>
												<div className='lift__profile_left_item_img'>
													<div>
														<img src={window.location.origin + '/images/img6.png'} alt='' />
													</div>
												</div>
												<div className='lift__profile_left_item_name'>
													Mega Silk Way
												</div>
												<div className='lift__profile_left_item_count'>
													12 лифтов
												</div>
											</Box>

											<Box className='lift__profile_left_item'>
												<div className='lift__profile_left_item_index'>
													3
												</div>
												<div className='lift__profile_left_item_img'>
													<div>
														<img src={window.location.origin + '/images/img7.png'} alt='' />
													</div>
												</div>
												<div className='lift__profile_left_item_name'>
													Городская больница
												</div>
												<div className='lift__profile_left_item_count'>
													10 лифтов
												</div>
											</Box>

											<Box className='lift__profile_left_item'>
												<div className='lift__profile_left_item_index'>
													4
												</div>
												<div className='lift__profile_left_item_img'>
													<div>
														<img src={window.location.origin + '/images/img8.png'} alt='' />
													</div>
												</div>
												<div className='lift__profile_left_item_name'>
													ТОО Apple Safety
												</div>
												<div className='lift__profile_left_item_count'>
													Обучающая компания
												</div>
											</Box>
										</div>

										{isEdit && <div className='lift__profile_edit plus' onClick={openEdit}>
											<Plus />
										</div>
										}

									</Box>

									<Box className='lift__profile_left_block bottom' mt={4}>
										<div className='lift__profile_left_simple_header'>
											Объекты
										</div>
										<div className='lift__profile_left_simple_info'>
											<Box className='lift__profile_left_item'>
												<div className='lift__profile_left_item_index'>
													1
												</div>
												<div className='lift__profile_left_item_img'>
													<div>
														<img src={window.location.origin + '/images/img5.png'} alt='' />
													</div>
												</div>
												<div className='lift__profile_left_item_name'>
													BI Group
												</div>
												<div className='lift__profile_left_item_count'>
													10 лифтов
												</div>
											</Box>
										</div>
									</Box>
								</GridItem>
							</Grid>

						</Box>) : activeTabIdx === 1 ? <StaffTable isPage={false}></StaffTable>
							: activeTabIdx === 2 ? (

								<Grid
									templateColumns='repeat(12, 1fr)'
									gap={4}
									mt={4}
									mb={4}
								>
									<GridItem colSpan={[12, 6, 6, 6]}>
										<Box className='lift__review'>
											<div className='lift__review_header'>
												<div className='lift__review_header_text'>
													Отзыв
												</div>
												<div className='lift__review_header_star'>
													<Icon as={MdStar} className='lift__star active' />
													<span>4.5/5</span>
												</div>
											</div>
											<div className='lift__review_text'>
												<div className='lift__review_text_stars'>
													<Icon as={MdStar} className='lift__star active' />
													<Icon as={MdStar} className='lift__star active' />
													<Icon as={MdStar} className='lift__star active' />
													<Icon as={MdStar} className='lift__star active' />
													<Icon as={MdStarBorder} className='lift__star active' />
												</div>
												<div className='lift__review_text_info'>
													<div className='lift__review_text_avatar'>
														<img src={window.location.origin + '/images/avatar.png'} alt='' />
													</div>
													Спасибо за вашу работу, отлично все прошло.
													Буду рекомендовать вашу компанию, как надежного партнера.
												</div>

												<div className='lift__review_text_author'>
													Имя фамилия автора
												</div>
											</div>
										</Box>
									</GridItem>
								</Grid>

							) : ''
					}

				</GridItem>
				<GridItem colSpan={3}>
					<Box className='lift_profile__right_side_info lift__shadow'>
						<div className='lift_profile__right_side_info_header'>
							Документы
						</div>

						<div className='lift_profile__right_side_info_items' display='flex' justify='center'>
							<SimpleGrid columns={2} spacing={4} mt={7}>
								<Box className='lift_profile__right_side_info_item'>
									<img src={window.location.origin + '/images/cert.png'} alt='' />
									<p>Справка о
										соответствии
										с профстандартами</p>
								</Box>
								<Box className='lift_profile__right_side_info_item'>
									<img src={window.location.origin + '/images/cert.png'} alt='' />
									<p>Аттестат на
										право монтажа</p>
								</Box>
							</SimpleGrid>
						</div>
					</Box>
				</GridItem>
			</Grid>

			<Edit ref={editRef} />
		</div>
	)

}

export default Profile
