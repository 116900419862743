import React from 'react'
import {
	Flex,
	Box,
	Text,
	FormControl,
	Input,
	Button,
	InputGroup,
	InputLeftElement,
	FormLabel,
	Select,
	FormErrorMessage,
	useToast,
	Icon,
} from '@chakra-ui/react'
import {MdPerson, MdVpnKey, MdContacts, MdEmail} from 'react-icons/md'
import {authActions, userActions, ncaLayerActions} from '../redux/actions'
import {useSelector, useDispatch} from 'react-redux'
import {Formik, Form, Field} from 'formik'
import {useHistory} from 'react-router-dom'
import {validationHelper} from '../helpers'

const Auth = () => {
	const signResult = useSelector(state => state.ncalayer.signResult)
	const listenerType = useSelector(state => state.ncalayer.listenerType)
	const registrationData = useSelector(state => state.auth.registrationData)
	const authData = useSelector(state => state.auth.authData)
	const isRegistrationSignOk = useSelector(state => state.auth.isRegistrationSignOk)
	const registrationSignResponse = useSelector(state => state.auth.registrationSignResponse)
	const authView = useSelector(state => state.auth.authView)
	const authLoaded = useSelector(state => state.auth.authLoaded)
	const authStatus = useSelector(state => state.auth.authStatus)
	const authResponse = useSelector(state => state.auth.authResponse)
	const authBySignLoading = useSelector(state => state.auth.authBySignLoading)
	const registerLoading = useSelector(state => state.auth.registerLoading)
	const responseXML = useSelector(state => state.ncalayer.responseXML)
	const toast = useToast()
	const dispatch = useDispatch()
	const history = useHistory()

	if (listenerType === 'REGISTRATION' && signResult.code === '200') {
		dispatch(ncaLayerActions.setListenerType(''))
		dispatch(ncaLayerActions.setSignResult({}))

		dispatch(authActions.setRegistrationData({
			...registrationData,
			xml: responseXML,
		}))

		dispatch(authActions.getDataBySign(toast))

	}

	if (listenerType === 'AUTHORIZATION' && signResult.code === '200') {

		dispatch(ncaLayerActions.setListenerType(''))
		dispatch(ncaLayerActions.setSignResult({}))

		let requestData = {
			xml: responseXML,
		}

		dispatch(authActions.authBySign(requestData, toast))
	}

	if (authLoaded) {

		if (authStatus === '0') {
			localStorage.setItem('auth_token', authResponse.token)
			localStorage.setItem('user', JSON.stringify(authResponse.user))
			localStorage.setItem('company', JSON.stringify(authResponse.company))
			localStorage.setItem('isAuthorized', true)

			dispatch(authActions.setAuthToken(authResponse.token))
			dispatch(authActions.setAuthLoaded(false))
			dispatch(userActions.setUser(authResponse.user))

			history.push('/')
		}

	}

	const setAuthViewAction = payload => {
		dispatch(authActions.setAuthView(payload))
		dispatch(authActions.setRegistrationData({
			email: '',
			password: '',
			iin: '',
			bin: '',
			roles: '0',
			firstName: '',
			lastName: '',
			middleName: '',
			chiefName: '',
		}))
		dispatch(authActions.setIsRegistrationSignOk(false))
	}

	const getActiveTokensAction = () => {
		dispatch(ncaLayerActions.getActiveTokens({
			signType: 'SIGNATURE',
			listenerType: 'REGISTRATION',
			requestXML: '<data><lang></lang></data>',
		}))
		dispatch(authActions.setIsRegistrationSignOk(false))
	}

	const getActiveTokensSignAction = () => {
		dispatch(ncaLayerActions.getActiveTokens({
			signType: 'AUTHENTICATION',
			listenerType: 'AUTHORIZATION',
			requestXML: '<data><lang></lang></data>',
		}))
		dispatch(authActions.setIsRegistrationSignOk(false))
	}

	const registerAction = values => {
		let payload = {
			...values,
			companyTypeEnum: [...registrationSignResponse.roles.filter(r => r.code == values.roles)],
			sid: registrationSignResponse.token,
			iin: registrationSignResponse.iin,
			bin: registrationSignResponse.bin,
			firstName: registrationSignResponse.firstName,
			lastName: registrationSignResponse.lastName,
			middleName: registrationSignResponse.middleName,
		}
		dispatch(authActions.register(payload, toast))

		dispatch(authActions.setRegistrationData({
			email: '',
			password: '',
			iin: '',
			bin: '',
			roles: '0',
			firstName: '',
			lastName: '',
			middleName: '',
			chiefName: '',
		}))
	}

	const authAction = values => {
		let payload = values
		dispatch(authActions.auth(payload, toast))
	}

	const handleGoToAuth = () => {
		setAuthViewAction(true)
		dispatch(authActions.setRegistrationData({
			email: '',
			password: '',
			iin: '',
			bin: '',
			roles: '0',
			firstName: '',
			lastName: '',
			middleName: '',
			chiefName: '',
		}))
		dispatch(authActions.setIsRegistrationSignOk(false))
	}

	function validateEmail(value) {
		let error
		if (!value) {
			error = 'Данное поле является обязательным'
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			error = 'Неправильный формат Email'
		}
		return error
	}

	function validateRole(value) {
		let error
		if (!value) {
			error = 'Данное поле является обязательным'
		}
		return error
	}

	function validatePassword(value) {
		let error
		if (!value) {
			error = 'Данное поле является обязательным'
		} else if (!/(?=.*[0-9])(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]{8,}/g.test(value)) {
			error = 'Пароль должен состоять не менее чем из 8 символов и содержать заглавные, строчные буквы латинского алфавита, цифры и специальные символы'
		}
		return error
	}

	function validateIIN(value) {
		let error
		let isValidIIN = validationHelper.checkIIN(value)

		if (!value) {
			error = 'Данное поле является обязательным'
		}
		// } else if (!isValidIIN) {
		//     error = 'Введите правильный формат ИИН';
		// }
		return error
	}

	function validateAuthPassword(value) {
		let error
		if (!value) {
			error = 'Данное поле является обязательным'
		} else if (value.length <= 3) {
			error = 'Пароль слишком короткий'
		}
		return error
	}

	return (
		<div className='lift__auth_container'>
			<Flex width='full' align='center' justifyContent='center' h={'100%'}>
				{authView
					? <Box p={2} className='lift__auth_box'>
						<Box textAlign='center'>
							<Text className='lift__auth_header'>АВТОРИЗАЦИЯ</Text>
						</Box>

						<Box my={4} textAlign='left'>
							<Formik
								initialValues={authData}
								enableReinitialize
								onSubmit={(values, actions) => {
									setTimeout(() => {
										authAction(values)
										actions.setSubmitting(false)
									}, 1000)
								}}
							>
								{formProps => (
									<Form>
										<Field name='iin' validate={validateIIN} >
											{({
												field,
												form: {touched, errors},
											}) => (
												<FormControl isInvalid={errors.iin && touched.iin}>
													<InputGroup>
														<InputLeftElement
															pointerEvents='none'
															children={<Icon as={MdPerson} />}
														/>
														<Input type='text' placeholder='ИИН' bg='white' autoComplete='off' {...field} />

													</InputGroup>
													<FormErrorMessage>{errors.iin}</FormErrorMessage>
												</FormControl>
											)}
										</Field>

										<Field name='password' validate={validateAuthPassword} >
											{({
												field,
												form: {touched, errors},
											}) => (
												<FormControl mt={3} isInvalid={errors.password && touched.password}>
													<InputGroup>
														<InputLeftElement
															pointerEvents='none'
															children={<Icon as={MdVpnKey} />}
														/>
														<Input type='password' placeholder='Пароль' bg='white' autoComplete='off' {...field} />

													</InputGroup>
													<FormErrorMessage>{errors.password}</FormErrorMessage>
												</FormControl>
											)}
										</Field>

										<div>
											<Button isLoading={formProps.isSubmitting}
												width='full'
												mt={5}
												type='submit'
												size='md'
												colorScheme='primary'>
													Войти
											</Button>

											<Button isLoading={authBySignLoading}
												width='full'
												mt={3}
												size='md'
												onClick={getActiveTokensSignAction}
												colorScheme='primary'>
													Войти через ЭЦП
											</Button>

											<Button width='full' mt={3} colorScheme='tertiary' size='md' onClick={() => setAuthViewAction(false)}>
												Регистрация
											</Button>
										</div>
									</Form>
								)}
							</Formik>
						</Box>
					</Box>
					: <Box p={2} className='lift__register_box'>
						<Box textAlign='center'>
							<Text className='lift__auth_header'>РЕГИСТРАЦИЯ</Text>
						</Box>

						<Box my={4} textAlign='left'>
							<Formik
								initialValues={registrationData}
								enableReinitialize
								onSubmit={(values, actions) => {
									setTimeout(() => {
										registerAction(values)
										actions.setSubmitting(false)
									}, 1000)
								}}
							>
								{formProps => (
									<Form>

										<Field name='companyName' >
											{({
												field,
											}) => (
												<FormControl>
													<InputGroup>
														<InputLeftElement
															pointerEvents='none'
															children={<Icon as={MdContacts} />}
														/>
														<Input type='text' placeholder='Наименование компании' bg='white' disabled {...field} />
													</InputGroup>
												</FormControl>
											)}
										</Field>

										<Field name='chiefName'>
											{({
												field,
												form: {touched, errors},
												meta,
											}) => (
												<FormControl mt={3} isInvalid={errors.chiefName && touched.chiefName}>
													<InputGroup>
														<InputLeftElement
															pointerEvents='none'
															children={<Icon as={MdPerson} />}
														/>
														<Input type='text' placeholder='ФИО первого руководителя' bg='white' disabled {...field} />
														<FormErrorMessage>{errors.chiefName}</FormErrorMessage>
													</InputGroup>
												</FormControl>
											)}
										</Field>

										{isRegistrationSignOk && <Field name='email' validate={validateEmail} >
											{({
												field,
												form: {touched, errors},
												meta,
											}) => (
												<FormControl mt={3} isInvalid={errors.email && touched.email}>
													<InputGroup>
														<InputLeftElement
															pointerEvents='none'
															children={<Icon as={MdEmail} />}
														/>
														<Input type='email' placeholder='Email' bg='white' {...field} />
													</InputGroup>
													<FormErrorMessage>{errors.email}</FormErrorMessage>
												</FormControl>

											)}
										</Field>
										}

										{isRegistrationSignOk && <Field name='password' validate={validatePassword} >
											{({
												field,
												form: {touched, errors},
												meta,
											}) => (
												<FormControl mt={3} isInvalid={errors.password && touched.password}>
													<InputGroup>
														<InputLeftElement
															pointerEvents='none'
															children={<Icon as={MdVpnKey} />}
														/>
														<Input type='password' placeholder='Пароль' bg='white' {...field} />
													</InputGroup>
													<FormErrorMessage>{errors.password}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
										}

										{isRegistrationSignOk && <Field name='roles' validate={validateRole} >
											{
												({
													field,
													form: {touched, errors},
													meta,
												}) => (
													<FormControl as='fieldset' mt={3} isInvalid={errors.roles && touched.roles}>
														<FormLabel as='legend'>Выберите роль в системе</FormLabel>
														<Select placeholder='Выберите роль в системе' bg='white' {...field}>
															{registrationSignResponse.roles.map((x, idx) =>
																<option key={idx} value={x.code}>{x.title}</option>,
															)}
														</Select>
														<FormErrorMessage>{errors.roles}</FormErrorMessage>
													</FormControl>
												)}
										</Field>
										}

										{isRegistrationSignOk
											? <Button width='full' mt={5} colorScheme='primary' isLoading={formProps.isSubmitting || registerLoading} size='md' type='submit'>
												Зарегистрироваться
											</Button>
											: <Button width='full' mt={5} colorScheme='primary' isLoading={formProps.isSubmitting || registerLoading} size='md' onClick={getActiveTokensAction}>
												Выберите ЭЦП
											</Button>
										}

										<Button width='full' mt={3} colorScheme='tertiary' size='md' onClick={() => handleGoToAuth()}>
											Вернуться ко входу
										</Button>


									</Form>
								)}
							</Formik>
						</Box>
					</Box>
				}
			</Flex>
		</div >
	)
}

export default Auth
