import React, {useState} from 'react'
import {
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	FormErrorMessage,
	SimpleGrid,
	Button,
} from '@chakra-ui/react'

const EditActsSpecifications = React.forwardRef((props, ref) => {
	const [isSubmit, setSubmit] = useState(false)
	const [formData, setFormData] = useState({...props.actData})

	// const regEx = /^[0-9\b]+$/
	const regEx = /^[0-9]*\.?[0-9]*$/

	React.useImperativeHandle(ref, () => ({
		onTabChange() {
			props.setTabData(formData, null)
		},
	}))

	const onNextClick = () => {
		setSubmit(true)
		props.setTabData(formData, 2)
	}

	const onPrevClick = () => {
		setSubmit(true)
		props.setTabData(formData, 0)
	}

	const onNumberInputChange = (fieldName, event) => {
		if (event.target.value === '' || regEx.test(event.target.value)) {
			setFormData({...formData, [fieldName]: event.target.value})
		}
	}

	const onInputChange = (fieldName, event) => {
		setFormData({...formData, [fieldName]: event.target.value})
	}

	return (<>
		<SimpleGrid columns={2} spacing={4} mt={4}>
			<FormControl isInvalid={isSubmit && !formData.liftingCapacity}>
				<FormLabel>Грузоподъемность, кг</FormLabel>
				<InputGroup>
					<Input
						isDisabled={props.isDisabled}
						type='text'
						placeholder='Грузоподъемность'
						bg='white'
						autoComplete='off'
						value={formData.liftingCapacity}
						onChange={e => onNumberInputChange('liftingCapacity', e)} />
				</InputGroup>
				<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={isSubmit && !formData.factoryNumber}>
				<FormLabel>Заводской номер</FormLabel>
				<InputGroup>
					<Input
						isDisabled={props.isDisabled}
						type='text'
						placeholder='Заводской номер'
						bg='white'
						autoComplete='off'
						value={formData.factoryNumber}
						onChange={e => onInputChange('factoryNumber', e)}/>
				</InputGroup>
				<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={isSubmit && !formData.ratedSpeed}>
				<FormLabel>Номинальная скорость, м/с</FormLabel>
				<InputGroup>
					<Input
						isDisabled={props.isDisabled}
						type='text'
						placeholder='Номинальная скорость'
						bg='white'
						autoComplete='off'
						value={formData.ratedSpeed}
						onChange={e => onNumberInputChange('ratedSpeed', e)}/>
				</InputGroup>
				<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={isSubmit && !formData.manufactureYear}>
				<FormLabel>Год изготовления</FormLabel>
				<InputGroup>
					<Input
						isDisabled={props.isDisabled}
						type='text'
						placeholder='Год изготовления'
						bg='white'
						autoComplete='off'
						value={formData.manufactureYear}
						onChange={e => onNumberInputChange('manufactureYear', e)}/>
				</InputGroup>
				<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={isSubmit && !formData.liftingHeight}>
				<FormLabel>Высота подъема, м</FormLabel>
				<InputGroup>
					<Input
						isDisabled={props.isDisabled}
						type='text'
						placeholder='Высота подъема'
						bg='white'
						autoComplete='off'
						value={formData.liftingHeight}
						onChange={e => onNumberInputChange('liftingHeight', e)}/>
				</InputGroup>
				<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={isSubmit && !formData.stopsNumber}>
				<FormLabel>Число остановок</FormLabel>
				<InputGroup>
					<Input
						isDisabled={props.isDisabled}
						type='text'
						placeholder='Число остановок'
						bg='white'
						autoComplete='off'
						value={formData.stopsNumber}
						onChange={e => onNumberInputChange('stopsNumber', e)}/>
				</InputGroup>
				<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
			</FormControl>
		</SimpleGrid>
		<Box mt='8' display='flex' justifyContent='flex-end'>
			<Button colorScheme='gray' mr={3} onClick={onPrevClick}>
				Назад
			</Button>
			<Button colorScheme='blue' mr={3} onClick={onNextClick}>
				Далее
			</Button>
		</Box>
	</>)
})

export default EditActsSpecifications
