export const ncalayerConstants = {
	SETUP_WS: 'SETUP_WS',
	SET_TOKENS: 'SET_TOKENS',
	SET_CALLBACK_TYPE: 'SET_CALLBACK_TYPE',
	SET_REQUEST_XML: 'SET_REQUEST_XML',
	SET_REQUEST_BASE64: 'SET_REQUEST_BASE64',
	SET_RESPONSE_XML: 'SET_RESPONSE_XML',
	SET_RESPONSE_BASE64: 'SET_RESPONSE_BASE64',
	SET_SIGN_LOADING: 'SET_SIGN_LOADING',
	SET_SIGN_SUCCESS: 'SET_SIGN_SUCCESS',
	SET_TOKENS_MODAL_OPEN: 'SET_TOKENS_MODAL_OPEN',
	SET_SIGN_TYPE: 'SET_SIGN_TYPE',
	SET_LISTENER_TYPE: 'SET_LISTENER_TYPE',
	SET_SIGN_RESULT: 'SET_SIGN_RESULT',
	INIT_NCALAYER_SUCCESS: 'INIT_NCALAYER_SUCCESS',
	INIT_NCALAYER_STARTED: 'INIT_NCALAYER_STARTED',
	INIT_NCALAYER_FAILURE: 'INIT_NCALAYER_FAILURE',

}
