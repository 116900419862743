import {ncalayerConstants} from '../../constants/ncalayer.constants'

export const ncaLayerActions = {
	setupWS,
	setTokens,
	setCallbackType,
	setRequestXML,
	setResponseXML,
	setSignLoading,
	setSignSuccess,
	setTokensModalOpen,
	setListenerType,
	setSignType,
	setSignResult,
	initNCALayer,
	initNCALayerSuccess,
	initNCALayerStarted,
	initNCALayerFailure,
	getActiveTokens,
	signXmlCallNew,
	setRequestBase64,
	setResponseBase64,
	createCMSSignatureFromBase64,
}

function setupWS(payload) {
	return {
		type: ncalayerConstants.SETUP_WS,
		payload: {
			ws: payload.ws,
		},
	}
}

function setTokens(payload) {
	return {
		type: ncalayerConstants.SET_TOKENS,
		payload,
	}
}

function setCallbackType(payload) {
	return {
		type: ncalayerConstants.SET_CALLBACK_TYPE,
		payload,
	}
}

function setRequestXML(payload) {
	return {
		type: ncalayerConstants.SET_REQUEST_XML,
		payload,
	}
}

function setRequestBase64(payload) {
	return {
		type: ncalayerConstants.SET_REQUEST_BASE64,
		payload,
	}
}

function setResponseXML(payload) {
	return {
		type: ncalayerConstants.SET_RESPONSE_XML,
		payload,
	}
}

function setResponseBase64(payload) {
	return {
		type: ncalayerConstants.SET_RESPONSE_BASE64,
		payload,
	}
}

function setSignLoading(payload) {
	return {
		type: ncalayerConstants.SET_SIGN_LOADING,
		payload,
	}
}

function setSignSuccess(payload) {
	return {
		type: ncalayerConstants.SET_SIGN_SUCCESS,
		payload,
	}
}

function setTokensModalOpen(payload) {
	return {
		type: ncalayerConstants.SET_TOKENS_MODAL_OPEN,
		payload,
	}
}

function setSignType(payload) {
	return {
		type: ncalayerConstants.SET_SIGN_TYPE,
		payload,
	}
}

function setListenerType(payload) {
	return {
		type: ncalayerConstants.SET_LISTENER_TYPE,
		payload,
	}
}

function setSignResult(payload) {
	return {
		type: ncalayerConstants.SET_SIGN_RESULT,
		payload,
	}
}

function initNCALayerSuccess() {
	return {
		type: ncalayerConstants.INIT_NCALAYER_SUCCESS,
	}
}

function initNCALayerStarted() {
	return {
		type: ncalayerConstants.INIT_NCALAYER_STARTED,
	}
}

function initNCALayerFailure(error) {
	return {
		type: ncalayerConstants.INIT_NCALAYER_FAILURE,
		payload: {
			error,
		},
	}
}

function initNCALayer(toast) {

	return (dispatch, getState) => {
		dispatch(initNCALayerStarted())

		let state = getState()

		const ws = new WebSocket(state.ncalayer.wsUrl)
		dispatch(setupWS({ws}))

		ws.onopen = () => {
			dispatch(initNCALayerSuccess())
		}

		ws.onclose = event => {
			dispatch(initNCALayerFailure())
			setTimeout(function () {
				dispatch(initNCALayer(toast))
			}, 1000)
		}

		ws.onmessage = event => {
			let result = JSON.parse(event.data)
			state = getState()

			if (result != null) {
				var rw = {
					code: result.code,
					message: result.message,
					responseObject: result.responseObject,
					getResult: () => this.result,
					getMessage: () => this.message,
					getResponseObject: () => this.responseObject,
					getCode: () => this.code,
				}

				try {
					if (state.ncalayer.callbackType === 0) {
						dispatch(getActiveTokensHandler(rw, toast))
					} else if (state.ncalayer.callbackType === 1) {
						dispatch(signXMLHandler(rw, toast))
					} else if (state.ncalayer.callbackType === 2) {
						dispatch(createCMSSignatureFromBase64Handler(rw, toast))
					}
				} catch (e) {
					console.error('ncalyer error')
				}
			}
		}

	}
}

function getActiveTokens(payload) {
	return (dispatch, getState) => {
		const activeTokens = {
			'module': 'kz.gov.pki.knca.commonUtils',
			'method': 'getActiveTokens',
		}

		dispatch(setSignType(payload.signType))
		dispatch(setListenerType(payload.listenerType))
		dispatch(setRequestXML(payload.requestXML))
		dispatch(setRequestBase64(payload.requestBase64))

		if (getState().ncalayer.ws.readyState !== 1) {
			return
		}

		getState().ncalayer.ws.send(JSON.stringify(activeTokens))
	}
}

const signXMLs = payload => (dispatch, getState) => {

	const state = getState()

	const signXmls = {
		'module': 'kz.gov.pki.knca.commonUtils',
		'method': 'signXml',
		'args': [payload.selectedStorage, payload.keyType, state.ncalayer.requestXML || state.ncalayer.requestBase64, '', ''],
	}

	console.warn(signXmls)

	dispatch(setCallbackType(1))

	state.ncalayer.ws.send(JSON.stringify(signXmls))
}

function signXmlCallNew(payload) {

	return (dispatch, getState) => {
		const xmlToSign = payload.xml
		const selectedStorage = payload.token
		let request = {
			selectedStorage,
			xmlsToSign: xmlToSign,
			keyType: getState().ncalayer.signType,
		}

		dispatch(setSignLoading(true))

		dispatch(signXMLs(request))
	}
}

const getActiveTokensHandler = (payload, toast) => dispatch => {

	const result = payload
	let tokensArr = []

	if (result.code === '500') {
		if (result.message === 'action.canceled') {
			result.message = 'Действие отменено'
			toast({
				title: 'NCALayer',
				description: 'Действие отменено',
				status: 'warning',
				duration: 3000,
				isClosable: true,
			})
		}
	} else if (result.code === '200') {
		dispatch(setTokensModalOpen(true))
		var listOfTokens = result.responseObject

		for (var i = 0; i < listOfTokens.length; i++) {
			let nameRu = ''
			let nameEn = ''
			switch (listOfTokens[i]) {
				case 'AKJaCartaStore':
					nameRu = 'JaCarta'
					nameEn = 'JaCarta'
					break
				case 'AKKaztokenStore':
					nameRu = 'Казтокен'
					nameEn = 'Kaztoken'
					break
				case 'AKKZIDCardStore':
					nameRu = 'Удостоверение личности'
					nameEn = 'Identification card'
					break
				case 'AKKorganSStore':
					nameRu = 'Корган'
					nameEn = 'Korgan'
					break
				case 'AKEToken72KStore':
					nameRu = 'Etoken'
					nameEn = 'Etoken'
					break
				default:
			}
			tokensArr.push({token: listOfTokens[i], nameRu, nameEn})
		}
		tokensArr.push({token: 'PKCS12', nameRu: 'Выбрать файл', nameEn: 'Choose file'})

		dispatch(setTokens(tokensArr))
	}
}

const signXMLHandler = (payload, toast) => dispatch => {

	const result = payload

	if (result.code === '500') {
		if (result.message === 'action.canceled') {
			result.message = 'Действие отменено'
			toast({
				title: 'NCALayer',
				description: 'Действие отменено',
				status: 'warning',
				duration: 3000,
				isClosable: true,
			})
		}
	} else if (result.code === '200') {
		dispatch(setResponseXML({
			xml: result.responseObject,
		}))
	}

	dispatch(setSignResult(result))
	dispatch(setTokensModalOpen(false))
	dispatch(setCallbackType(0))
	dispatch(setSignLoading(false))
}

function createCMSSignatureFromBase64(payload) {

	return (dispatch, getState) => {
		dispatch(setSignLoading(true))

		const state = getState()

		const signXmls = {
			'module': 'kz.gov.pki.knca.commonUtils',
			'method': 'createCAdESFromBase64 ',
			'args': [payload.token, getState().ncalayer.signType, state.ncalayer.requestBase64, '', ''],
		}

		console.warn(signXmls)

		dispatch(setCallbackType(2))

		state.ncalayer.ws.send(JSON.stringify(signXmls))
	}
}

const createCMSSignatureFromBase64Handler = (payload, toast) => dispatch => {

	const result = payload

	if (result.code === '500') {
		if (result.message === 'action.canceled') {
			result.message = 'Действие отменено'
			toast({
				title: 'NCALayer',
				description: 'Действие отменено',
				status: 'warning',
				duration: 3000,
				isClosable: true,
			})
		}
	} else if (result.code === '200') {
		dispatch(setResponseBase64({
			base64: result.responseObject,
		}))
	}

	dispatch(setSignResult(result))
	dispatch(setTokensModalOpen(false))
	dispatch(setCallbackType(0))
	dispatch(setSignLoading(false))
}

