export const passportConstants = {
	FIELD_TYPE_SECTION: 1,
	FIELD_TYPE_ITEM: 2,
	ITEM_TYPE_GROUP_TITLE: 0,
	ITEM_TYPE_INPUT: 1,
	ITEM_TYPE_TEXTBOX: 2,
	ITEM_TYPE_SELECT: 3,
	ITEM_TYPE_NUMERIC: 4,
	ITEM_TYPE_DATETIME: 5,
	ITEM_TYPE_DATE: 6,
	ITEM_TYPE_TIME: 7,
	ITEM_TYPE_CHECKBOX: 8,
	SET_SECTION: 'PASSPORT_SET_SECTION',
	ADD_FIELD: 'PASSPORT_ADD_FIELD',
	SET_CURRENT_PASSPORT_ID: 'SET_CURRENT_PASSPORT_ID',
	GET_PASSPORT_LIST_STARTED: 'GET_PASSPORT_LIST_STARTED',
	GET_PASSPORT_LIST_SUCCESS: 'GET_PASSPORT_LIST_SUCCESS',
	GET_PASSPORT_LIST_FAILURE: 'GET_PASSPORT_LIST_FAILURE',
	GET_PASSPORT_BY_ID_STARTED: 'GET_PASSPORT_BY_ID_STARTED',
	GET_PASSPORT_BY_ID_SUCCESS: 'GET_PASSPORT_BY_ID_SUCCESS',
	GET_PASSPORT_BY_ID_FAILURE: 'GET_PASSPORT_BY_ID_FAILURE',
	SET_PASSPORT_BY_ID_STARTED: 'SET_PASSPORT_BY_ID_STARTED',
	SET_PASSPORT_BY_ID_SUCCESS: 'SET_PASSPORT_BY_ID_SUCCESS',
	SET_PASSPORT_BY_ID_FAILURE: 'SET_PASSPORT_BY_ID_FAILURE',
	SET_SECTION_DATA_INITITAL: 'SET_SECTION_DATA_INITITAL',
	SET_ACTIVE_PASSPORT_EDIT_TAB_IDX: 'SET_ACTIVE_PASSPORT_EDIT_TAB_IDX',
	SET_ACTIVE_PASSPORT_ACT_EDIT_TAB_IDX: 'SET_ACTIVE_PASSPORT_ACT_EDIT_TAB_IDX',
	SET_PASSPORT_ACT_INFO_DATA: 'SET_PASSPORT_ACT_INFO_DATA',
	SET_PASSPORT_ACT_SPECIFICATIONS_DATA: 'SET_PASSPORT_ACT_SPECIFICATIONS_DATA',
	SET_PASSPORT_ACT_STATUS_DATA: 'SET_PASSPORT_ACT_STATUS_DATA',
	GET_PASSPORT_ACT_PREVIEW_STARTED: 'GET_PASSPORT_ACT_PREVIEW_STARTED',
	GET_PASSPORT_ACT_PREVIEW_SUCCESS: 'GET_PASSPORT_ACT_PREVIEW_SUCCESS',
	GET_PASSPORT_ACT_PREVIEW_FAILURE: 'GET_PASSPORT_ACT_PREVIEW_FAILURE',
	GET_PASSPORT_ACTS_LIST_STARTED: 'GET_PASSPORT_ACTS_LIST_STARTED',
	GET_PASSPORT_ACTS_LIST_SUCCESS: 'GET_PASSPORT_ACTS_LIST_SUCCESS',
	GET_PASSPORT_ACTS_LIST_FAILURE: 'GET_PASSPORT_ACTS_LIST_FAILURE',
}
