import React, {useState, useEffect} from 'react'
import {
	Td,
	FormControl,
	Tooltip,
	FormErrorMessage,
	Textarea,
	useToast,
} from '@chakra-ui/react'
import {useSelector} from 'react-redux'
import {ThemeProvider, createTheme} from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import DatePicker from '@mui/lab/DatePicker'
import TimePicker from '@mui/lab/TimePicker'
import TextField from '@mui/material/TextField'
import ruLocale from 'date-fns/locale/ru'
import Checkbox from '@mui/material/Checkbox'
import moment from 'moment'
import 'moment/locale/ru'
import {passportConstants} from '../../constants/passport.constants'
import AsyncDictSelect from './AsyncDictSelect'
import TextInput from './TextInput'

const theme = createTheme({
	palette: {
		mode: 'light',
	},
})

moment.locale('ru')

const RenderItemComponent = props => {
	const [itemValue, setItemValue] = useState(props.itemType == passportConstants.ITEM_TYPE_CHECKBOX ? false : '')
	const [showNumericTooltip, setShowNumericTooltip] = useState(false)
	const regEx = /^[0-9]*\.?[0-9]*$/

	useEffect(() => {
		if (props.data && props.data[props.fieldName] && !props.parentFieldName) {
			setItemValue(props.data[props.fieldName])
		} else if (props.data && props.parentFieldName && props.data[props.parentFieldName] && props.data[props.parentFieldName][props.fieldName]) {
			setItemValue(props.data[props.parentFieldName][props.fieldName])
		}
	}, [props.data])

	const onItemChange = val => {
		setItemValue(val)
		if (props.onChange) {
			if (props.parentFieldName) {
				props.onChange(props.parentFieldName, {[props.fieldName]: val})
			} else {
				props.onChange(props.fieldName, val)
			}
		}
	}

	const onNumberInputChange = val => {
		if (val === '' || regEx.test(val)) {
			if (showNumericTooltip) {
				setShowNumericTooltip(false)
			}
			setItemValue(val)
			if (props.onChange) {
				if (props.parentFieldName) {
					props.onChange(props.parentFieldName, {[props.fieldName]: val})
				} else {
					props.onChange(props.fieldName, val)
				}
			}
		} else if (!regEx.test(val)) {
			setShowNumericTooltip(true)
		}
	}

	const setDateTimeFormat = (date, format) => {
		if (date) {
			return moment(date.toString()).format(format || 'DD.MM.YYYY HH:mm')
		} else {
			return '-'
		}
	}

	switch (props.itemType) {
		case passportConstants.ITEM_TYPE_INPUT:
			return <TextInput
				isEditMode={props.isEditMode}
				placeholder={props.placeholder || ''}
				itemValue={itemValue}
				onChange={onItemChange} />
		case passportConstants.ITEM_TYPE_TEXTBOX:
			return <Textarea
				autoComplete='off'
				placeholder={props.placeholder || ''}
				value={itemValue}
				onChange={e => onItemChange(e.target.value)} />
		case passportConstants.ITEM_TYPE_SELECT:
			return <AsyncDictSelect
				isEditMode={props.isEditMode}
				placeholder={props.placeholder || ''}
				dictionaryName={props.dictionaryName}
				additionalCondition={props.additionalCondition}
				isMultiple={props.isMultiple}
				fieldName={props.fieldName}
				selectedValue={itemValue}
				onChange={onItemChange} />
		case passportConstants.ITEM_TYPE_NUMERIC:
			return <Tooltip hasArrow label='Можно ввести только числовые значения' isOpen={showNumericTooltip}>
				<span>
					<TextInput
						isEditMode={props.isEditMode}
						placeholder={props.placeholder || ''}
						itemValue={itemValue}
						onChange={onNumberInputChange} />
				</span>
			</Tooltip>
		case passportConstants.ITEM_TYPE_DATETIME:
			return props.isEditMode
				? <ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
						<DateTimePicker
							clearable={true}
							minDate={new Date('1950-01-01')}
							maxDate={new Date('2050-01-01')}
							value={itemValue}
							onChange={newValue => onItemChange(newValue ? newValue.toString() : null)}
							renderInput={params => <TextField fullWidth {...params} />}
						/>
					</LocalizationProvider>
				</ThemeProvider>
				: <div>{setDateTimeFormat(itemValue)}</div>
		case passportConstants.ITEM_TYPE_DATE:
			return props.isEditMode
				? <ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
						<DatePicker
							clearable={true}
							views={props.views}
							minDate={new Date('1950-01-01')}
							maxDate={new Date('2050-01-01')}
							value={itemValue}
							onChange={newValue => onItemChange(newValue ? newValue.toString() : null)}
							renderInput={params => <TextField fullWidth {...params} />}
						/>
					</LocalizationProvider>
				</ThemeProvider>
				: <div>{setDateTimeFormat(itemValue, props.dateTimeFormat || 'DD.MM.YYYY')}</div>
		case passportConstants.ITEM_TYPE_TIME:
			return props.isEditMode
				? <ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<TimePicker
							clearable={true}
							value={itemValue}
							onChange={newValue => onItemChange(newValue ? newValue.toString() : null)}
							renderInput={params => <TextField fullWidth {...params} />}
						/>
					</LocalizationProvider>
				</ThemeProvider>
				: <div>{setDateTimeFormat(itemValue, props.dateTimeFormat || 'HH:mm')}</div>
		case passportConstants.ITEM_TYPE_CHECKBOX:
			return <ThemeProvider theme={theme}>
				<Checkbox
					checked={itemValue}
					disabled={!props.isEditMode}
					onChange={e => onItemChange(e.target.checked)}
					inputProps={{'aria-label': 'controlled'}}
				/>
			</ThemeProvider>
		default:
			return null
	}
}

const SectionItem = props => props.itemType == passportConstants.ITEM_TYPE_GROUP_TITLE
	? (<>
		<Td style={{padding: '5px 5px 5px 50px'}}>
			{props.fieldTitle}
		</Td>
		{
			Array.isArray(props.children) && props.children.length > 0
				? props.children.map((child, ix) => <Td key={`section-group-item-${child.fieldName}-${ix}`} style={{padding: '5px', textAlign: 'center'}}>
					<RenderItemComponent data={props.data} {...child} onChange={props.onChange} parentFieldName={props.fieldName} isEditMode={props.isEditMode}/>
				</Td>)
				: '-'
		}
	</>)
	: (<>
		<Td colSpan={3} style={{padding: '5px 5px 5px 50px'}}>
			{props.fieldTitle}
		</Td>
		<Td colSpan={4} style={props.isValid == false ? {border: '2px solid red', padding: '5px', textAlign: 'center'} : {padding: '5px', textAlign: 'center'}}>
			<FormControl isRequired={props.isRequired} >
				<RenderItemComponent {...props}/>
				{/* <FormErrorMessage>{errors[prop]}</FormErrorMessage> */}
			</FormControl>
		</Td>
	</>)

export default SectionItem
