import React, {useEffect, useState} from 'react'
import {
	Text,
	Select,
	Box,
	Input,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Spinner,
	Alert,
	AlertIcon,
	Icon,
	useToast,
} from '@chakra-ui/react'
import {MdFilterList, MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/ru'
import {passportActions, dictionaryActions} from '../redux/actions'
import {requestsService} from '../services'

moment.locale('ru')

const Requests = () => {
	const [requestsList, setRequestsList] = useState([])
	const [requestsCount, setRequestsCount] = useState(0)
	const [loading, setLoading] = useState(true)
	const authToken = useSelector(state => state.auth.authToken)
	const company = useSelector(state => state.auth.company)
	const history = useHistory()
	const dispatch = useDispatch()
	const toast = useToast()

	const goToPassport = value => {
		history.push('/passport' + value)
	}

	useEffect(() => {
		const requestData = {
			currentPage: 0,
			pageSize: 20,
		}

		requestsService.getRequestsList(requestData, authToken).then(res => {
			if (res.data.code === '0') {
				if (Array.isArray(res.data.data) && res.data.data.length > 0) {
					setRequestsList(res.data.data)
					setRequestsCount(res.data.totalRecords || 0)
				}
			} else {
				toast({
					title: 'Получение заявок',
					description: res.data.message,
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			}
			setLoading(false)
		},
		error => {
			console.log(error)
			setLoading(false)
		})
	}, [])

	const setDateTimeFormat = (date, format) => {
		if (date) {
			return moment(date.toString()).format(format || 'DD.MM.YYYY HH:mm')
		} else {
			return '-'
		}
	}

	return (
		<div>
			<div className='lift__eq_table_container'>
				<Box bg='white' borderRadius='4px' mt={5} mb={5}>
					<Box p={4} textAlign='center'>
						<Text>Задачи и заявки</Text>
					</Box>
					{
						loading && <Box p={4} textAlign='center'>
							<Spinner />
						</Box>
					}

					<div>
						<Table variant='simple' className='lift__eq_table'>
							<Thead>
								<Tr>
									<Th isNumeric width='20px'>№</Th>
									<Th>Номер</Th>
									<Th>Адрес</Th>
									<Th>Тип</Th>
									<Th>Ответственный специалист</Th>
									<Th>Задача</Th>
									<Th>Статус</Th>
									<Th>Дата</Th>
								</Tr>
							</Thead>
							<Tbody>
								{
									requestsList.length > 0
										? requestsList.map((x, idx) => (
											<Tr key={idx} onClick={() => goToPassport('/edit/' + x.id)}>
												<Td isNumeric>{idx + 1}</Td>
												<Td>{x.factoryNumber ? x.factoryNumber : '-'}</Td>
												<Td>{x.dictEquipmentAppointment ? x.dictEquipmentAppointment.name : '-'}</Td>
												<Td>{setDateTimeFormat(x.manufactureDate, 'MMMM YYYY')}</Td>
												<Td>{x.createdUser && x.createdUser.fullname ? x.createdUser.fullname : '-'}</Td>
												<Td>{x.dictDriveUnit ? x.dictDriveUnit.name : '-'}</Td>
												<Td>{x.dictDriveUnit ? x.dictDriveUnit.name : '-'}</Td>
												<Td>{x.dictDriveUnit ? x.dictDriveUnit.name : '-'}</Td>
											</Tr>
										))
										: <Tr>
											<Td colSpan='8'>
												<Alert status='info'>
													<AlertIcon />
													На данный момент список пуст
												</Alert>
											</Td>
										</Tr>
								}

							</Tbody>
						</Table>
					</div>
				</Box>


				{requestsList.length > 0 && <Box mt={5} mb={5} className='lift__pagination'>
					<Box borderRadius='4px' className='lift__pagination_item'>
						<Icon as={MdKeyboardArrowLeft} className='lift__eq_filter_icon' />
					</Box>
					{/* <Box borderRadius="4px" className="lift__pagination_item">1</Box>
						<Box borderRadius="4px" className="lift__pagination_item">2</Box> */}
					<Box borderRadius='4px' className='lift__pagination_item active' border='1px' borderStyle='solid' borderColor='primary.500' color='primary.500'>1</Box>
					{/* <Box borderRadius="4px" className="lift__pagination_item">4</Box>
						<Box borderRadius="4px" className="lift__pagination_item">5</Box> */}
					<Box borderRadius='4px' className='lift__pagination_item'>
						<Icon as={MdKeyboardArrowRight} className='lift__eq_filter_icon' />
					</Box>
				</Box>}

			</div>

		</div>
	)

}

export default Requests
