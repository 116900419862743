import {passportConstants} from '../../constants/passport.constants'

const passportReducer = (state = initialState, action) => {
	switch (action.type) {
		case passportConstants.SET_SECTION:
			return {
				...state,
				sectionData: {
					...state.sectionData,
					[action.payload.property]: action.payload.values,
				},
			}
		case passportConstants.ADD_FIELD:

			let newValueKey = Object.keys(state.sectionData[action.payload.section]).length + 1

			let first = state.sectionData[action.payload.section][Object.keys(state.sectionData[action.payload.section])[0]]

			const objectKeys = Object.keys(first)

			let newObject = {}

			for (let i = 0; i < objectKeys.length; i++) {
				newObject = {
					...newObject,
					[objectKeys[i]]: '',
				}
			}

			let newSectionInfo = [
				...state.sectionInfo[action.payload.section],
			]

			newSectionInfo.push({
				...state.sectionInfo[action.payload.section][0],
				field_name: ['value_' + newValueKey],
			})

			return {
				...state,
				sectionInfo: {
					...state.sectionInfo,
					[action.payload.section]: newSectionInfo,
				},
				sectionData: {
					...state.sectionData,
					[action.payload.section]: {
						...state.sectionData[action.payload.section],
						['value_' + newValueKey]: newObject,
					},
				},
			}
		case passportConstants.GET_PASSPORT_LIST_STARTED:
			return {
				...state,
				getPassportListLoading: true,
				getPassportListLoaded: true,
			}
		case passportConstants.GET_PASSPORT_LIST_SUCCESS:
			return {
				...state,
				getPassportListLoading: false,
				items: action.payload.items,
			}
		case passportConstants.GET_PASSPORT_LIST_FAILURE:
			return {
				...state,
				getPassportListLoading: false,
				getPassportListLoaded: false,
			}
		case passportConstants.GET_PASSPORT_BY_ID_STARTED:
			return {
				...state,
				getPassportByIdLoading: true,
				getPassportByIdLoaded: true,
			}
		case passportConstants.GET_PASSPORT_BY_ID_SUCCESS:
			console.log(action.payload.sectionData)
			return {
				...state,
				getPassportByIdLoading: false,
				currentPassportData: {
					...action.payload.currentPassportData,
				},
				sectionData: {
					...action.payload.sectionData,
				},
			}
		case passportConstants.GET_PASSPORT_BY_ID_FAILURE:
			return {
				...state,
				getPassportByIdLoading: false,
				getPassportByIdLoaded: false,
			}
		case passportConstants.SET_PASSPORT_BY_ID_STARTED:
			return {
				...state,
				setPassportByIdLoading: true,
				setPassportByIdLoaded: true,
			}
		case passportConstants.SET_PASSPORT_BY_ID_SUCCESS:
			return {
				...state,
				setPassportByIdLoading: false,
			}
		case passportConstants.SET_PASSPORT_BY_ID_FAILURE:
			return {
				...state,
				setPassportByIdLoading: false,
				setPassportByIdLoaded: false,
			}
		case passportConstants.SET_CURRENT_PASSPORT_ID:
			return {
				...state,
				currentPassportId: action.payload,
				currentPassportData: null,
			}
		case passportConstants.SET_SECTION_DATA_INITITAL:
			return {
				...state,
				sectionData: initialState.sectionData,
			}
		case passportConstants.SET_ACTIVE_PASSPORT_EDIT_TAB_IDX:
			return {
				...state,
				activePassportTabIdx: action.payload,
			}
		case passportConstants.SET_ACTIVE_PASSPORT_ACT_EDIT_TAB_IDX:
			return {
				...state,
				activePassportActsTabIdx: action.payload,
			}
		case passportConstants.SET_PASSPORT_ACT_INFO_DATA:
			return {
				...state,
				passportActInfoData: {
					...action.payload.data,
				},
			}
		case passportConstants.SET_PASSPORT_ACT_SPECIFICATIONS_DATA:
			return {
				...state,
				passportActSpecificationData: {
					...action.payload.data,
				},
			}
		case passportConstants.SET_PASSPORT_ACT_STATUS_DATA:
			return {
				...state,
				passportActsStatusData: {
					...action.payload.data,
				},
			}
		case passportConstants.GET_PASSPORT_ACT_PREVIEW_STARTED:
			return {
				...state,
				getPassportActsPreviewLoading: true,
				getPassportActsPreviewLoaded: true,
			}
		case passportConstants.GET_PASSPORT_ACT_PREVIEW_SUCCESS:
			return {
				...state,
				getPassportActsPreviewLoading: false,
				passportActsPreviewData: action.payload.passportActsPreviewData,
			}
		case passportConstants.GET_PASSPORT_ACT_PREVIEW_FAILURE:
			return {
				...state,
				getPassportActsPreviewLoading: false,
				getPassportActsPreviewLoaded: false,
			}
		case passportConstants.GET_PASSPORT_ACTS_LIST_STARTED:
			return {
				...state,
				getPassportActsListLoading: true,
				getPassportActsListLoaded: true,
			}
		case passportConstants.GET_PASSPORT_ACTS_LIST_SUCCESS:
			return {
				...state,
				getPassportActsListLoading: false,
				passportActsItems: action.payload.items,
			}
		case passportConstants.GET_PASSPORT_ACTS_LIST_FAILURE:
			return {
				...state,
				getPassportActsListLoading: false,
				getPassportActsListLoaded: false,
			}
		default:
			return state
	}
}

const initialState = {
	getPassportListLoading: false,
	getPassportListLoaded: true,
	getPassportByIdLoading: false,
	getPassportByIdLoaded: true,
	setPassportByIdLoading: false,
	setPassportByIdLoaded: true,
	currentPassportData: null,
	currentPassportId: '',
	items: [],
	listSpecifications: [
		{
			fieldType: passportConstants.FIELD_TYPE_SECTION,
			fieldName: 'section_1',
			fieldTitle: 'ОБЩИЕ СВЕДЕНИЯ',
			numberTitle: '1',
			itemType: null,
			dictionaryName: '',
			isMultiple: false,
			isRequired: false,
			editable: true,
			children: [
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'lift_type',
					fieldTitle: 'Тип и модель лифта / Лифтінен түрі мен үлгісі',
					itemType: passportConstants.ITEM_TYPE_SELECT,
					dictionaryName: 'DictEquipmentAppointments',
					additionalCondition: '',
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'factory_number',
					fieldTitle: 'Заводской номер / Зауыттық номірі',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'manufacture_date',
					fieldTitle: 'Месяц и год изготовления / Ай, шығарылған жылы',
					itemType: passportConstants.ITEM_TYPE_DATE,
					isMultiple: false,
					isRequired: true,
					editable: true,
					views: ['year', 'month'],
					dateTimeFormat: 'MMMM YYYY',
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'drive_unit',
					fieldTitle: 'Привод (электрический, гидравлический, пневматический и т.д.) / Есік жетегі (электрлік, гидравликалық, пневматикалық, серіппелі т.б.)',
					itemType: passportConstants.ITEM_TYPE_SELECT,
					dictionaryName: 'DictDriveUnits',
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'allowable_temperature',
					fieldTitle: 'Допустимая температура (минимальная и максимальная), (0 С) в машинном помещении /в шахте / Шахтада, Машыналық бөлмеде мүмкін температура',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'environment',
					fieldTitle: 'Окружающая среда, в которой может эксплуатироваться лифт / Лифтіні пайдалануға болатын қоршаған орта',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'regulations',
					fieldTitle: 'Нормативные документы, в соответствии с которыми изготовлен лифт / Лифт сәйкестендіріліп жасалған негізгі нормативтітехниқалық құжаттар',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'lifetime',
					fieldTitle: 'Назначенный срок эксплуатации, лет Пайдалануды тоғайындалған мерзім ? жыл',
					itemType: passportConstants.ITEM_TYPE_NUMERIC,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'lifting_capacity',
					fieldTitle: 'Номинальная грузоподъемность, кг / Номиналды жұк көтеретін, кг',
					itemType: passportConstants.ITEM_TYPE_NUMERIC,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'max_passengers',
					fieldTitle: 'Число пассажиров (макс.) / Жолаушылардың саны',
					itemType: passportConstants.ITEM_TYPE_NUMERIC,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'speed',
					fieldTitle: 'Номинальная скорость движения кабины, м/с /Кабинаның номиналды қозғалыс жылдамдығы',
					itemType: passportConstants.ITEM_TYPE_NUMERIC,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'speed_revision',
					fieldTitle: 'Скорость движения кабины в режиме «Ревизия» / Тексерудi тәртiптегi кабинаның қозғалыс жылдамдығы»',
					itemType: passportConstants.ITEM_TYPE_NUMERIC,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'control_system',
					fieldTitle: 'Система управления / Басқару жүйесi',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'floors_number',
					fieldTitle: 'Число этажей / Қабаттардың саны',
					itemType: passportConstants.ITEM_TYPE_NUMERIC,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'stops_number',
					fieldTitle: 'Число остановок / Аялдамалар саны',
					itemType: passportConstants.ITEM_TYPE_NUMERIC,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'mine_doors_number',
					fieldTitle: 'Число дверей шахты / Шахта есіктерінің саны',
					itemType: passportConstants.ITEM_TYPE_NUMERIC,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'lifting_height',
					fieldTitle: 'Высота подъема, м / Көтерілу биіктігі, м',
					itemType: passportConstants.ITEM_TYPE_NUMERIC,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'mine_height',
					fieldTitle: 'Высота шахты, м / Шахтаны биiктiк, м',
					itemType: passportConstants.ITEM_TYPE_NUMERIC,
					isMultiple: false,
					isRequired: true,
					editable: true,
					children: null,
				},
			],
		},
		{
			fieldType: passportConstants.FIELD_TYPE_SECTION,
			fieldName: '',
			fieldTitle: 'Основные технические данные и характеристики оборудования лифта / Негізге техникалық деректер және сипаттама',
			numberTitle: '2',
			itemType: null,
			dictionaryName: '',
			isMultiple: false,
			isRequired: false,
			editable: true,
			children: [
				{
					fieldType: passportConstants.FIELD_TYPE_SECTION,
					fieldName: 'section_2_1',
					fieldTitle: 'Электрические цепи',
					numberTitle: '2.1',
					itemType: null,
					dictionaryName: '',
					isMultiple: false,
					isRequired: false,
					editable: true,
					isGrouped: true,
					groups: [
						{
							groupTitle: 'Электрические цепи / электр тiзбектерi',
							isFromChildren: false,
							fieldName: '',
						},
						{
							groupTitle: 'Род тока / Тоқтың түрі',
							isFromChildren: true,
							fieldName: 'current_type',
						},
						{
							groupTitle: 'Напряжение / Кернеу В; (±%)',
							isFromChildren: true,
							fieldName: 'voltage',
						},
						{
							groupTitle: 'Частота / Жиілік Гц; (±%)',
							isFromChildren: true,
							fieldName: 'frequency',
						},
					],
					children: [
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'on_input_device',
							fieldTitle: 'На вводном устройстве лифта / Жұмыс істемейтін лифтіде пайдалануға қосылған құрылғыда',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'current_type',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'voltage',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'frequency',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'elevator_drive',
							fieldTitle: 'Силовая цепь / Әлді шынжыр. Привод лифта лифттың / ерiксiз келтiруi',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'current_type',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'voltage',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'frequency',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'doors_drive',
							fieldTitle: 'Силовая цепь / Әлді шынжыр. Привод дверей / есiктердiң ерiксiз келтiруi',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'current_type',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'voltage',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'frequency',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'control_circuit',
							fieldTitle: 'Цепь управления / Басқару шынжыры',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'current_type',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'voltage',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'frequency',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'light_chain_cabs',
							fieldTitle: 'Цепь освещения для: Кабины / Кабина',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'current_type',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'voltage',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'frequency',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'light_chain_mine',
							fieldTitle: 'Цепь освещения для: Шахты / Шахта',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'current_type',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'voltage',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'frequency',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'signaling_circuit',
							fieldTitle: 'Цепь сигнализации / Дабылдама шанжыры',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'current_type',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'voltage',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'frequency',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
					],
				},
				{
					fieldType: passportConstants.FIELD_TYPE_SECTION,
					fieldName: 'section_2_2',
					fieldTitle: 'Лебедка / Жүкшығыр',
					numberTitle: '2.2',
					itemType: null,
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: [
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'winch_type',
							fieldTitle: 'Тип / Түрі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'serial_number',
							fieldTitle: 'Заводской номер / Зауыт нөмірі',
							itemType: passportConstants.ITEM_TYPE_NUMERIC,
							dictionaryName: '',
							isMultiple: false,
							isRequired: true,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'manufacture_date',
							fieldTitle: 'Год изготовления / Шығарылған жылы',
							itemType: passportConstants.ITEM_TYPE_NUMERIC,
							dictionaryName: '',
							isMultiple: false,
							isRequired: true,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'rated_torque',
							fieldTitle: 'Номинальный крутящий момент на выходном валу, rpm / Шығатын біліктегі номиналды айналмалы сәт, rpm',
							itemType: passportConstants.ITEM_TYPE_NUMERIC,
							dictionaryName: '',
							isMultiple: false,
							isRequired: true,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'pulley_diameter',
							fieldTitle: 'Диаметр канатоведущего шкива, барабана, мм / Арқанмен жүргізетін тегершік, барабан, жұлдызшаның диаметрі, ММ',
							itemType: passportConstants.ITEM_TYPE_NUMERIC,
							dictionaryName: '',
							isMultiple: false,
							isRequired: true,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'branch_block_diameter',
							fieldTitle: 'Диаметр отводного блока , мм / Бұрып жiберетiн блоктiң диаметрi, мм',
							itemType: passportConstants.ITEM_TYPE_NUMERIC,
							dictionaryName: '',
							isMultiple: false,
							isRequired: true,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'weight',
							fieldTitle: 'Масса, кг / Салмағы, кг',
							itemType: passportConstants.ITEM_TYPE_NUMERIC,
							dictionaryName: '',
							isMultiple: false,
							isRequired: true,
							editable: true,
							children: null,
						},
					],
				},
				{
					fieldType: passportConstants.FIELD_TYPE_SECTION,
					fieldName: 'section_2_3',
					fieldTitle: 'Частотный преобразователь',
					numberTitle: '2.3',
					itemType: null,
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: [
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'type',
							fieldTitle: 'Тип / Түрі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'current_type',
							fieldTitle: 'Род тока / Токтың турі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'voltage',
							fieldTitle: 'Напряжение / Кернеу',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
							suffix: 'B',
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'rated_current',
							fieldTitle: 'Номинальный ток, А / Номиналды ток, А',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'frequency',
							fieldTitle: 'Частота, Гц / Жиілік, гц',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
					],
				},
				{
					fieldType: passportConstants.FIELD_TYPE_SECTION,
					fieldName: 'section_2_4',
					fieldTitle: 'Блок автоматической эвакуации (при наличии)',
					numberTitle: '2.4',
					itemType: null,
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: [
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'type',
							fieldTitle: 'Тип / Түрі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'serial_number',
							fieldTitle: 'Заводской номер № Зауыт нөмірі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'current_type',
							fieldTitle: 'Род тока / Токтың турі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'voltage',
							fieldTitle: 'Напряжение / Кернеу',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
							suffix: 'B',
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'frequency',
							fieldTitle: 'Частота, Гц / Жиілік, гц',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
					],
				},
				{
					fieldType: passportConstants.FIELD_TYPE_SECTION,
					fieldName: 'section_2_5',
					fieldTitle: 'Блок аккумуляторных батарей (при наличии)',
					numberTitle: '2.5',
					itemType: null,
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: [
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'type',
							fieldTitle: 'Тип / Түрі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'serial_number',
							fieldTitle: 'Заводской номер № Зауыт нөмірі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
					],
				},
				{
					fieldType: passportConstants.FIELD_TYPE_SECTION,
					fieldName: 'section_2_6',
					fieldTitle: 'Тормоз / Тежеуіш',
					numberTitle: '2.6',
					itemType: null,
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: [
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'type',
							fieldTitle: 'Тип / Түрі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'brake_pulley_diameter',
							fieldTitle: 'Диаметр тормозного шкива, мм / Тежеуіп тегершіктің диаметрі, мм',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'brake_drive_type',
							fieldTitle: 'Привод тормоза / тежеуiштiң ерiксiз келтiруi | Тип Түрі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'brake_drive_effort',
							fieldTitle: 'Привод тормоза / тежеуiштiң ерiксiз келтiруi | Усилие, Nm. күш Nm',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
							suffix: 'Nm',
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'brake_drive_step',
							fieldTitle: 'Привод тормоза / тежеуiштiң ерiксiз келтiруi | Ход исполнительного органа, мм атқарушы органның жүрiсi, мм',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
					],
				},
				{
					fieldType: passportConstants.FIELD_TYPE_SECTION,
					fieldName: 'section_2_7',
					fieldTitle: 'Ловители',
					numberTitle: '2.7',
					itemType: null,
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: [
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'type',
							fieldTitle: 'Тип / Түрі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'serial_no_1',
							fieldTitle: 'Serial No.1',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'serial_no_2',
							fieldTitle: 'Serial No.2',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
					],
				},
				{
					fieldType: passportConstants.FIELD_TYPE_SECTION,
					fieldName: 'section_2_8',
					fieldTitle: 'Электрические цепи',
					numberTitle: '2.8',
					itemType: null,
					dictionaryName: '',
					isMultiple: false,
					isRequired: false,
					editable: true,
					isGrouped: true,
					groups: [
						{
							groupTitle: 'Назначение / Міндеті',
							isFromChildren: false,
							fieldName: '',
						},
						{
							groupTitle: 'Лебедки / Көтеретін қозғалтқыш',
							isFromChildren: true,
							fieldName: 'winches',
						},
						{
							groupTitle: 'Привода дверей / Кабина есіктерінің жетегі',
							isFromChildren: true,
							fieldName: 'door_drive',
						},
					],
					children: [
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'type',
							fieldTitle: 'Тип / Түрі',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'current_type',
							fieldTitle: 'Род тока / Токтың турі',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'voltage',
							fieldTitle: 'Напряжение, В / Кернеу',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'rated_current',
							fieldTitle: 'Номинальный ток, А / Номиналды ток, А',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'frequency',
							fieldTitle: 'Частота, Гц / Жиілік, гц',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'power',
							fieldTitle: 'Мощность, кВт / Куаты, кВт',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'overheating',
							fieldTitle: 'Допустимый перегрев обмоток двигателя, 0 С (класс изоляции) / Қозғалтқыш орамасына рұқсат етілетін қатты қыздыру Оқшауландыру тобы',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							suffix: 'F',
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'rotation_frequency',
							fieldTitle: 'Частота вращения, rpm / Айналу жиілігі',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'pv',
							fieldTitle: 'ПВ, %',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'inclusions_per_hour',
							fieldTitle: 'Число включений в час / Сағатқа қосындыларының саны',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'result_with_protection',
							fieldTitle: 'Исполнение с указанием степени защиты / Қорғау дәрежесін нұсқаумен орындау',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'weight',
							fieldTitle: 'Масса, кг Салмағы, кг',
							itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'winches',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: '',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
					],
				},
				{
					fieldType: passportConstants.FIELD_TYPE_SECTION,
					fieldName: 'section_2_9',
					fieldTitle: 'Двери шахты / Шахта есіктері',
					numberTitle: '2.9',
					itemType: null,
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: [
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'opening_design',
							fieldTitle: 'Конструкция открывания / Құрылымы',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'doorway_size',
							fieldTitle: 'Размер дверного проема в свету (ширина х высота), мм / Есік орнының көлемі, мм',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'opening_closing_method',
							fieldTitle: 'Способ открывания/закрывания / Ашылу немесе жабылу әдісі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'drive',
							fieldTitle: 'Привод / Еріксіз',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'opening_mine_door_method',
							fieldTitle: 'Способ отпирания двери шахты при остановке кабины на уровне посадочной / Кабинаның отырғызу деңгейіне тоқтаған кезде шахта есігін итеру әдісі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_ITEM,
							fieldName: 'opening_mine_door_method_2',
							fieldTitle: 'Способ открывания двери шахты при отсутствии кабины на уровне посадочной (погрузочной) площадки / Кабина болмаған жағдайда отырғызу деңгейіндегі (жүк тиеу) алаңқайда шахта есігінің ашылу эдесі',
							itemType: passportConstants.ITEM_TYPE_INPUT,
							dictionaryName: '',
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: null,
						},
						{
							fieldType: passportConstants.FIELD_TYPE_SECTION,
							fieldName: 'section_2_9_1',
							fieldTitle: 'Кабина / Кабина',
							numberTitle: '2.9.1',
							itemType: null,
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'width',
									fieldTitle: 'Внутренние размеры: Ішкі өлшемдер, мм. Ширина/Кендігі мм',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'depth',
									fieldTitle: 'Глубина/Терендігі мм',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'height',
									fieldTitle: 'Высота/Биіктігі мм',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'floor_construction',
									fieldTitle: 'Конструкция пола (подвижный, неподвижный) / Етек конструкция (қозғалатын, қимылсыз)',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_construction',
									fieldTitle: 'Конструкция дверей (распашные, центрально раздвижные, одно-, двухили многостворчатые) / (жырту, жайылмалы, бiр-шi - екi және немесе көп жармалы) есiктердiң конструкциясы',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'opening_closing_design',
									fieldTitle: 'Способ открывания или закрывания дверей (ручной, полуавтоматический, автоматический) Есікті ашу және жабу тәсілдері (қолмен, жартылай автоматты, автоматты)',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'door_drive',
									fieldTitle: 'Привод дверей (электрический, гидравлический, пневматический, пружинный и т.п.) / Есік жетегі (электрлік, гидравликалық, пневматикалық, серіппелі т.б.)',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'cab_type',
									fieldTitle: 'Вид кабины (проходная, непроходная) / Кабина түрі (өтпелі, өтпелі емес)',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'weight',
									fieldTitle: 'Масса, кг / Салмағы, кг',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_SECTION,
							fieldName: 'section_2_9_2',
							fieldTitle: 'Противовес / Ауырлығы',
							numberTitle: '2.9.2',
							itemType: null,
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'weight',
									fieldTitle: 'Масса, кг (в собранном виде) (жиналған түрде)',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_SECTION,
							fieldName: 'section_2_9_3',
							fieldTitle: 'Тяговые и уравновешивающие элементы / Ауырлық және теңгеруші элементтер',
							numberTitle: '2.9.3',
							itemType: null,
							isMultiple: false,
							isRequired: false,
							editable: true,
							isGrouped: true,
							groups: [
								{
									groupTitle: '',
									isFromChildren: false,
									fieldName: '',
								},
								{
									groupTitle: 'Кабины / Кабиналар',
									isFromChildren: true,
									fieldName: 'cab',
								},
								{
									groupTitle: 'Противовеса / Қарсысалмақ',
									isFromChildren: true,
									fieldName: 'counterweight',
								},
								{
									groupTitle: 'Ограничителяскорости / Жылдымдықшектеушілері',
									isFromChildren: true,
									fieldName: 'speed_limitter',
								},
								{
									groupTitle: 'Уравновешивающие элементы / Теңгеруші элементтер',
									isFromChildren: true,
									fieldName: 'balancing_elements',
								},
							],
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'main_type',
									fieldTitle: 'Вид (канат, цепь и т. п.) / Турі (арқан, шынжыр және т,б)',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'speed_limitter',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'balancing_elements',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'type',
									fieldTitle: 'Тип / Турі',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'speed_limitter',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'balancing_elements',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'construction',
									fieldTitle: 'Конструкция / Құрлымы',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'speed_limitter',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'balancing_elements',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'symbol',
									fieldTitle: 'Условное обозначение / Шартты белгілері',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'speed_limitter',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'balancing_elements',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'diameter',
									fieldTitle: 'Диаметр, размеры, мм / Диаметр, мм',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'speed_limitter',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'balancing_elements',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'elements_count',
									fieldTitle: 'Количество элементов, шт. / Элементтер саны, дана',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'speed_limitter',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'balancing_elements',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'one_element_length',
									fieldTitle: 'Длина одного элемента, включая длину, необходимую для крепления, м / Бекітуге қажетті ұзындықты қосқандағы бір элементтің ұзындығы, м',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'speed_limitter',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'balancing_elements',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'breaking_force',
									fieldTitle: 'Разрывное усилие (разрушающая нагрузка), кН / Ажырату күші (бұзушы жүктеме), кН',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'speed_limitter',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'balancing_elements',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'safety_factor',
									fieldTitle: 'Коэффициент запаса прочности / Қор төзімділігінің коэффициенті',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'speed_limitter',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'balancing_elements',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_SECTION,
							fieldName: 'section_2_9_4',
							fieldTitle: 'Устройства безопасности Қауiпсiздiктiң құрылымдары',
							numberTitle: '2.9.4',
							itemType: null,
							isMultiple: false,
							isRequired: false,
							editable: true,
							isGrouped: true,
							groups: [
								{
									groupTitle: '',
									isFromChildren: false,
									fieldName: '',
								},
								{
									groupTitle: 'Кабины / Кабиналар',
									isFromChildren: true,
									fieldName: 'cab',
								},
								{
									groupTitle: 'Противовеса / Қарсысалмақ',
									isFromChildren: true,
									fieldName: 'counterweight',
								},
							],
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'type',
									fieldTitle: 'Ловители / Аулағыштар | Тип (резкого, плавного торможения, комбинированные) (қатты, байсалды тоқтату, құрамалы) түр',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'put_into_action',
									fieldTitle: 'Ограничитель скорости / Жылдамдықтың шектеушiсi | Приводятся в действие (от ограничителя скорости, от устройства, срабатывающего от слабины всех тяговых канатов) Жылдамдықтың шектеушiсi, әлсiздiктен барлық тарту iске қосылатын құрылымнан әсерлерге тура келедi',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'conditions_for_catchers',
									fieldTitle: 'Условия испытания ловителей плавного торможения (скорость движения кабины, противовеса, загрузка кабины) (кабина, теңестiрушi салмақтың қозғалыс жылдамдығы, кабинаның жүктеуi) байсалды тоқтатудың аулағыштарының сынауының шарттары',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									suffix: 'м/c',
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'permissible_braking_distance_max',
									fieldTitle: 'Допустимый путь торможения ловителей плавного торможения, мм - максимальный / Байсалды тоқтату, ммның аулағыштарының тоқтатуын мүмкiн жол - максималы',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'permissible_braking_distance_min',
									fieldTitle: 'Допустимый путь торможения ловителей плавного торможения, мм - минимальный / Байсалды тоқтату, ммның аулағыштарының тоқтатуын мүмкiн жол - ең төменгiсi',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'limitter_type',
									fieldTitle: 'Тип (центробежный, маятниковый и т.п.) / (центрден тепкiш, маятниктi тағы сол сияқтылар) түр',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'limitter_serial_number',
									fieldTitle: 'Серийный номер',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'limitter_action_speed',
									fieldTitle: 'Скорость движения кабины (противовеса), при которой срабатывает ограничитель скорости, м/с - минимальная / Жанында жылдамдықтың шектеушiсi iстейтiн (теңестiрушi салмақ ) кабинаның қозғалыс жылдамдығы м/с - максималы - ең төменгiсi',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'limitter_effort',
									fieldTitle: 'Усилие на канате ограничителя скорости от натяжного устройства, N / Керiп кигiзiлген құрылымы, N ден жылдамдықтың шектеушiсiнiң арқанындағы күш',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'buffer_type',
									fieldTitle: 'Буфера / Буфер | Тип (пружинный, гидравлический и т.д.) / (серiппелi, гидравликалық және тағы басқалар) түр',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'buffer_stroke',
									fieldTitle: 'Величина хода, мм / Жүрiс, ммнiң шамасы',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'buffer_count',
									fieldTitle: 'Число',
									itemType: passportConstants.ITEM_TYPE_GROUP_TITLE,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: [
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'cab',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
										{
											fieldType: passportConstants.FIELD_TYPE_ITEM,
											fieldName: 'counterweight',
											fieldTitle: '',
											itemType: passportConstants.ITEM_TYPE_INPUT,
											dictionaryName: '',
											isMultiple: false,
											isRequired: false,
											editable: true,
											children: null,
										},
									],
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_SECTION,
							fieldName: 'section_2_9_5',
							fieldTitle: 'Механические устройства Механикалық құрылымдар',
							numberTitle: '2.9.5',
							itemType: null,
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'closing_cab_door',
									fieldTitle: 'Закрытия двери кабины / Кабинаның есiгiнiң жабулары',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'closing_mine_door',
									fieldTitle: 'Закрытия двери шахты / Шахтаны есiктiң жабулары',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'auto_mine_door_lock',
									fieldTitle: 'Автоматического замка двери шахты Шахтаның есiктерi автоматты құлып',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'cab_loads',
									fieldTitle: 'Загрузки кабины / Кабинаның жүктеулерi',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'cab_overload',
									fieldTitle: 'Перегрузки кабины / Кабинаның шамадан тыс жүктеулер',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'speedlimitter',
									fieldTitle: 'Ограничителя скорости / Жылдамдықтың шектеушiсi',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'catchers',
									fieldTitle: 'Ловителей / Аулағыштар',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'weakness_of_traction',
									fieldTitle: 'Слабины тяговых канатов / Тарту арқандарының әлсiздiктерi',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'speed_limitter_rope',
									fieldTitle: 'Натяжного устройства каната ограничителя скорости / Жылдамдықтар шектеушiнiң арқанының керiп кигiзiлген құрылымы',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'balancing_rope',
									fieldTitle: 'Натяжного устройства уравновешивающих канатов / Теңестiрушi арқандарды керiп кигiзiлген құрылымы',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'pit',
									fieldTitle: 'Приямка / Шұңқыр',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'stop_button',
									fieldTitle: 'Кнопка «Стоп» в постах управления (при комплектации НКУ управления УЛ) / (НКУ басқаруды iрiктеп жинақтаудың жанында УЛ) басқару орындарындағы стоп батырма',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'stop_button_second',
									fieldTitle: 'Кнопка «Стоп» в постах управления (при комплектации НКУ управления ШК, ШДК, УПЛ, УЛЖ) / (НКУ, ШК, ШДК, УПЛ, УЛЖ басқаруды iрiктеп жинақтаудың жанында ) басқару орындарындағы «Стоп» батырма',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'stop_button_third',
									fieldTitle: 'Кнопка «Стоп» в машинном помещении (НКУ управления лифтом) / (лифтты басқару) машина бөлмесiндегi «Стоп» батырм',
									itemType: passportConstants.ITEM_TYPE_CHECKBOX,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
						{
							fieldType: passportConstants.FIELD_TYPE_SECTION,
							fieldName: 'section_2_9_7',
							fieldTitle: 'Механические устройства Механикалық құрылымдар',
							numberTitle: '2.9.7',
							itemType: null,
							isMultiple: false,
							isRequired: false,
							editable: true,
							children: [
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'breaking_chain',
									fieldTitle: 'Разрываемая цепь / Бөлiнетiн шынжыр',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
								{
									fieldType: passportConstants.FIELD_TYPE_ITEM,
									fieldName: 'method_of_actuation',
									fieldTitle: 'Способ приведения в действие / Әсерге келтiруiн әдiс',
									itemType: passportConstants.ITEM_TYPE_INPUT,
									dictionaryName: '',
									isMultiple: false,
									isRequired: false,
									editable: true,
									children: null,
								},
							],
						},
					],
				},
			],
		},
		{
			fieldType: passportConstants.FIELD_TYPE_SECTION,
			fieldName: 'section_3',
			fieldTitle: 'Нагрузка при проведении полного технического освидетельствования Жүктеме толық техникалық куәләндiрудi өткiзуде',
			numberTitle: '3',
			itemType: null,
			isMultiple: false,
			isRequired: false,
			editable: true,
			children: [
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'static_load',
					fieldTitle: 'Статическая нагрузка / Статикалық жүктеме',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					dictionaryName: '',
					isMultiple: false,
					isRequired: false,
					editable: true,
					suffix: 'кг.',
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'dynamic_overload',
					fieldTitle: 'Динамическая нагрузка / Динамикалық жүктеме',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					dictionaryName: '',
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: null,
				},
			],
		},
		{
			fieldType: passportConstants.FIELD_TYPE_SECTION,
			fieldName: 'section_4',
			fieldTitle: 'Свидетельство о приемке. Қабылдау жөніндегі куәлік',
			numberTitle: '4',
			itemType: null,
			isMultiple: false,
			isRequired: false,
			editable: true,
			children: [
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'no1',
					fieldTitle: '№ IST',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					dictionaryName: '',
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'date_of_issue',
					fieldTitle: 'Дата выпуска / Шығарылған күні',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					dictionaryName: '',
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: null,
				},
			],
		},
		{
			fieldType: passportConstants.FIELD_TYPE_SECTION,
			fieldName: 'section_5',
			fieldTitle: 'Гарантийные обязательства организации, смонтировавшей лифт. / Лифтті қүрастырған мекеменің кепілдік міндеттері.',
			numberTitle: '5',
			itemType: null,
			isMultiple: false,
			isRequired: false,
			editable: true,
			children: [
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'name_of_company',
					fieldTitle: 'Наименование организации, смонтировавшей лифт / Лифтті құрастырған мекеменің атауы',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					dictionaryName: '',
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'date',
					fieldTitle: 'Дата / Күні',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					dictionaryName: '',
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: null,
				},
				{
					fieldType: passportConstants.FIELD_TYPE_ITEM,
					fieldName: 'presentative_full_name',
					fieldTitle: 'Представитель монтажной организации / Монтаждық ұйымның өкілі',
					itemType: passportConstants.ITEM_TYPE_INPUT,
					dictionaryName: '',
					isMultiple: false,
					isRequired: false,
					editable: true,
					children: null,
				},
			],
		},
	],
	sectionInfo: {
		section_1: [
			{
				field_title: 'Тип и модель лифта / Лифтінен түрі мен үлгісі',
				field_name: 'company_name',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Заводской номер / Зауыттық номірі',
				field_name: 'factory_number',
				isRequired: false,
				editable: true,
				prefix: 'IST',
			},
			{
				field_title: 'Месяц и год изготовления / Ай, шығарылған жылы',
				field_name: 'manufacture_date',
				isRequired: false,
				editable: true,
				prefix: 'IST',
			},
			{
				field_title: 'Привод (электрический, гидравлический, пневматический и т.д.) / Есік жетегі (электрлік, гидравликалық, пневматикалық, серіппелі т.б.)',
				field_name: 'drive_unit',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Допустимая температура (минимальная и максимальная), (0 С) в машинном помещении /в шахте / Шахтада, Машыналық бөлмеде мүмкін температура',
				field_name: 'allowable_temperature',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Окружающая среда, в которой может эксплуатироваться лифт / Лифтіні пайдалануға болатын қоршаған орта',
				field_name: 'environment',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Нормативные документы, в соответствии с которыми изготовлен лифт / Лифт сәйкестендіріліп жасалған негізгі нормативтітехниқалық құжаттар',
				field_name: 'regulations',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Назначенный срок эксплуатации, лет Пайдалануды тоғайындалған мерзім ? жыл',
				field_name: 'lifetime',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Номинальная грузоподъемность, кг / Номиналды жұк көтеретін, кг',
				field_name: 'lifting_capacity',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Число пассажиров (макс.) / Жолаушылардың саны',
				field_name: 'max_passengers',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Номинальная скорость движения кабины, м/с /Кабинаның номиналды қозғалыс жылдамдығы',
				field_name: 'speed',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Скорость движения кабины в режиме «Ревизия» / Тексерудi тәртiптегi кабинаның қозғалыс жылдамдығы»',
				field_name: 'speed_revision',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Система управления / Басқару жүйесi',
				field_name: 'control_system',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Число этажей / Қабаттардың саны',
				field_name: 'floors_number',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Число остановок / Аялдамалар саны',
				field_name: 'stops_number',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Число дверей шахты / Шахта есіктерінің саны',
				field_name: 'mine_doors_number',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Высота подъема, м / Көтерілу биіктігі, м',
				field_name: 'lifting_height',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Высота шахты, м / Шахтаны биiктiк, м',
				field_name: 'mine_height',
				isRequired: false,
				editable: true,
			},
		],
		section_2_1: [
			{
				field_title: 'На вводном устройстве лифта / Жұмыс істемейтін лифтіде пайдалануға қосылған құрылғыда',
				field_name: 'on_input_device',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'current_type',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'voltage',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'frequency',
					},
				],
			},
			{
				field_title: 'Силовая цепь / Әлді шынжыр. Привод лифта лифттың / ерiксiз келтiруi',
				field_name: 'elevator_drive',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'current_type',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'voltage',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'frequency',
					},
				],
			},
			{
				field_title: 'Силовая цепь / Әлді шынжыр. Привод дверей / есiктердiң ерiксiз келтiруi',
				field_name: 'doors_drive',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'current_type',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'voltage',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'frequency',
					},
				],
			},
			{
				field_title: 'Цепь управления / Басқару шынжыры',
				field_name: 'control_circuit',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'current_type',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'voltage',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'frequency',
					},
				],
			},
			{
				field_title: 'Цепь освещения для: Кабины / Кабина',
				field_name: 'light_chain_cabs',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'current_type',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'voltage',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'frequency',
					},
				],
			},
			{
				field_title: 'Цепь освещения для: Шахты / Шахта',
				field_name: 'light_chain_mine',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'current_type',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'voltage',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'frequency',
					},
				],
			},
			{
				field_title: 'Цепь сигнализации / Дабылдама шанжыры',
				field_name: 'signaling_circuit',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'current_type',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'voltage',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'frequency',
					},
				],
			},

		],
		section_2_2: [
			{
				field_title: 'Заводской номер / Зауыт нөмірі',
				field_name: 'serial_number',
				isRequired: false,
				editable: true,
				prefix: 'Ser.No',
			},
			{
				field_title: 'Год изготовления / Шығарылған жылы',
				field_name: 'manufacture_date',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Номинальный крутящий момент на выходном валу, rpm / Шығатын біліктегі номиналды айналмалы сәт, rpm',
				field_name: 'rated_torque',
				isRequired: false,
				editable: true,
				suffix: 'rpm',
			},
			{
				field_title: 'Диаметр канатоведущего шкива, барабана, мм / Арқанмен жүргізетін тегершік, барабан, жұлдызшаның диаметрі, ММ',
				field_name: 'pulley_diameter',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Диаметр отводного блока , мм / Бұрып жiберетiн блоктiң диаметрi, мм',
				field_name: 'branch_block_diameter',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Масса, кг / Салмағы, кг',
				field_name: 'weight',
				isRequired: false,
				editable: true,
			},
		],
		section_2_3: [
			{
				field_title: 'Тип / Түрі',
				field_name: 'type',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Род тока / Токтың турі',
				field_name: 'current_type',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Напряжение / Кернеу',
				field_name: 'voltage',
				isRequired: false,
				editable: true,
				suffix: 'B',
			},
			{
				field_title: 'Номинальный ток, А / Номиналды ток, А',
				field_name: 'rated_current',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Частота, Гц / Жиілік, гц',
				field_name: 'frequency',
				isRequired: false,
				editable: true,
			},
		],
		section_2_4: [
			{
				field_title: 'Тип / Түрі',
				field_name: 'type',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Заводской номер № Зауыт нөмірі',
				field_name: 'serial_number',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Род тока / Токтың турі',
				field_name: 'current_type',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Напряжение / Кернеу',
				field_name: 'voltage',
				isRequired: false,
				editable: true,
				suffix: 'B',
			},
			{
				field_title: 'Частота, Гц / Жиілік, гц',
				field_name: 'frequency',
				isRequired: false,
				editable: true,
			},
		],
		section_2_5: [
			{
				field_title: 'Тип / Түрі',
				field_name: 'type',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Заводской номер № Зауыт нөмірі',
				field_name: 'serial_number',
				isRequired: false,
				editable: true,
			},
		],
		section_2_6: [
			{
				field_title: 'Тип / Түрі',
				field_name: 'type',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Диаметр тормозного шкива, мм / Тежеуіп тегершіктің диаметрі, мм',
				field_name: 'brake_pulley_diameter',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Привод тормоза / тежеуiштiң ерiксiз келтiруi | Тип Түрі',
				field_name: 'brake_drive_type',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Привод тормоза / тежеуiштiң ерiксiз келтiруi | Усилие, Nm. күш Nm',
				field_name: 'brake_drive_effort',
				isRequired: false,
				editable: true,
				suffix: 'Nm',
			},
			{
				field_title: 'Привод тормоза / тежеуiштiң ерiксiз келтiруi | Ход исполнительного органа, мм атқарушы органның жүрiсi, мм',
				field_name: 'brake_drive_step',
				isRequired: false,
				editable: true,
			},
		],
		section_2_7: [
			{
				field_title: 'Тип / Түрі',
				field_name: 'type',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Serial No.1',
				field_name: 'serial_no_1',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Serial No.2',
				field_name: 'serial_no_2',
				isRequired: false,
				editable: true,
			},
		],
		section_2_8: [
			{
				field_title: 'Тип / Түрі',
				field_name: 'type',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'Род тока / Токтың турі',
				field_name: 'current_type',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'Напряжение, В / Кернеу',
				field_name: 'voltage',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'Номинальный ток, А / Номиналды ток, А',
				field_name: 'rated_current',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'Частота, Гц / Жиілік, гц',
				field_name: 'frequency',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'Мощность, кВт / Куаты, кВт',
				field_name: 'power',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'Допустимый перегрев обмоток двигателя, 0 С (класс изоляции) / Қозғалтқыш орамасына рұқсат етілетін қатты қыздыру Оқшауландыру тобы',
				field_name: 'overheating',
				isRequired: false,
				editable: true,
				suffix: 'F',
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'Частота вращения, rpm / Айналу жиілігі',
				field_name: 'rotation_frequency',
				isRequired: false,
				editable: true,
				suffix: 'rpm',
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'ПВ, %',
				field_name: 'pv',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'Число включений в час / Сағатқа қосындыларының саны',
				field_name: 'inclusions_per_hour',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'Исполнение с указанием степени защиты / Қорғау дәрежесін нұсқаумен орындау',
				field_name: 'result_with_protection',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
			{
				field_title: 'Масса, кг Салмағы, кг',
				field_name: 'weight',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'winches',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'door_drive',
					},
				],
			},
		],
		section_2_9: [
			{
				field_title: 'Конструкция открывания / Құрылымы',
				field_name: 'opening_design',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Размер дверного проема в свету (ширина х высота), мм / Есік орнының көлемі, мм',
				field_name: 'doorway_size',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Способ открывания/закрывания / Ашылу немесе жабылу әдісі',
				field_name: 'opening_closing_method',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Привод / Еріксіз',
				field_name: 'drive',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Способ отпирания двери шахты при остановке кабины на уровне посадочной / Кабинаның отырғызу деңгейіне тоқтаған кезде шахта есігін итеру әдісі',
				field_name: 'opening_mine_door_method',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Способ открывания двери шахты при отсутствии кабины на уровне посадочной (погрузочной) площадки / Кабина болмаған жағдайда отырғызу деңгейіндегі (жүк тиеу) алаңқайда шахта есігінің ашылу эдесі',
				field_name: 'opening_mine_door_method_2',
				isRequired: false,
				editable: true,
			},
		],
		section_2_9_1: [
			{
				field_title: 'Внутренние размеры: Ішкі өлшемдер, мм. Ширина/Кендігі мм ',
				field_name: 'width',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Глубина/Терендігі мм',
				field_name: 'depth',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Высота/Биіктігі мм',
				field_name: 'height',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Конструкция пола (подвижный, неподвижный) / Етек конструкция (қозғалатын, қимылсыз)',
				field_name: 'floor_construction',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Конструкция дверей (распашные, центрально раздвижные, одно-, двухили многостворчатые) / (жырту, жайылмалы, бiр-шi - екi және немесе көп жармалы) есiктердiң конструкциясы',
				field_name: 'door_construction',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Способ открывания или закрывания дверей (ручной, полуавтоматический, автоматический) Есікті ашу және жабу тәсілдері (қолмен, жартылай автоматты, автоматты)',
				field_name: 'opening_closing_design',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Привод дверей (электрический, гидравлический, пневматический, пружинный и т.п.) / Есік жетегі (электрлік, гидравликалық, пневматикалық, серіппелі т.б.)',
				field_name: 'door_drive',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Вид кабины (проходная, непроходная) / Кабина түрі (өтпелі, өтпелі емес)',
				field_name: 'cab_type',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Масса, кг / Салмағы, кг',
				field_name: 'weight',
				isRequired: false,
				editable: true,
			},
		],
		section_2_9_2: [
			{
				field_title: 'Масса, кг (в собранном виде) (жиналған түрде)',
				field_name: 'weight',
				isRequired: false,
				editable: true,
			},
		],
		section_2_9_3: [
			{
				field_title: 'Вид (канат, цепь и т. п.) / Турі (арқан, шынжыр және т,б)',
				field_name: 'main_type',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'speed_limitter',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'balancing_elements',
					},
				],
			},
			{
				field_title: 'Тип / Турі',
				field_name: 'type',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'speed_limitter',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'balancing_elements',
					},
				],
			},
			{
				field_title: 'Конструкция / Құрлымы',
				field_name: 'construction',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'speed_limitter',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'balancing_elements',
					},
				],
			},
			{
				field_title: 'Условное обозначение / Шартты белгілері',
				field_name: 'symbol',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'speed_limitter',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'balancing_elements',
					},
				],
			},
			{
				field_title: 'Диаметр, размеры, мм / Диаметр, мм',
				field_name: 'diameter',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'speed_limitter',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'balancing_elements',
					},
				],
			},
			{
				field_title: 'Количество элементов, шт. / Элементтер саны, дана',
				field_name: 'elements_count',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'speed_limitter',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'balancing_elements',
					},
				],
			},
			{
				field_title: 'Длина одного элемента, включая длину, необходимую для крепления, м / Бекітуге қажетті ұзындықты қосқандағы бір элементтің ұзындығы, м',
				field_name: 'one_element_length',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'speed_limitter',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'balancing_elements',
					},
				],
			},
			{
				field_title: 'Разрывное усилие (разрушающая нагрузка), кН / Ажырату күші (бұзушы жүктеме), кН',
				field_name: 'breaking_force',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'speed_limitter',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'balancing_elements',
					},
				],
			},
			{
				field_title: 'Коэффициент запаса прочности / Қор төзімділігінің коэффициенті',
				field_name: 'safety_factor',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'speed_limitter',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'balancing_elements',
					},
				],
			},
		],
		section_2_9_4: [
			{
				field_title: 'Ловители / Аулағыштар | Тип (резкого, плавного торможения, комбинированные) (қатты, байсалды тоқтату, құрамалы) түр',
				field_name: 'type',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Ограничитель скорости / Жылдамдықтың шектеушiсi | Приводятся в действие (от ограничителя скорости, от устройства, срабатывающего от слабины всех тяговых канатов) Жылдамдықтың шектеушiсi, әлсiздiктен барлық тарту iске қосылатын құрылымнан әсерлерге тура келедi',
				field_name: 'put_into_action',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Условия испытания ловителей плавного торможения (скорость движения кабины, противовеса, загрузка кабины) (кабина, теңестiрушi салмақтың қозғалыс жылдамдығы, кабинаның жүктеуi) байсалды тоқтатудың аулағыштарының сынауының шарттары',
				field_name: 'conditions_for_catchers',
				isRequired: false,
				editable: true,
				suffix: 'м/c',
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Допустимый путь торможения ловителей плавного торможения, мм - максимальный / Байсалды тоқтату, ммның аулағыштарының тоқтатуын мүмкiн жол - максималы',
				field_name: 'permissible_braking_distance_max',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Допустимый путь торможения ловителей плавного торможения, мм - минимальный / Байсалды тоқтату, ммның аулағыштарының тоқтатуын мүмкiн жол - ең төменгiсi',
				field_name: 'permissible_braking_distance_min',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Тип (центробежный, маятниковый и т.п.) / (центрден тепкiш, маятниктi тағы сол сияқтылар) түр',
				field_name: 'limitter_type',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Серийный номер',
				field_name: 'limitter_serial_number',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Скорость движения кабины (противовеса), при которой срабатывает ограничитель скорости, м/с - минимальная / Жанында жылдамдықтың шектеушiсi iстейтiн (теңестiрушi салмақ ) кабинаның қозғалыс жылдамдығы м/с - максималы - ең төменгiсi',
				field_name: 'limitter_action_speed',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Усилие на канате ограничителя скорости от натяжного устройства, N / Керiп кигiзiлген құрылымы, N ден жылдамдықтың шектеушiсiнiң арқанындағы күш',
				field_name: 'limitter_effort',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Буфера / Буфер | Тип (пружинный, гидравлический и т.д.) / (серiппелi, гидравликалық және тағы басқалар) түр',
				field_name: 'buffer_type',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Величина хода, мм / Жүрiс, ммнiң шамасы',
				field_name: 'buffer_stroke',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
			{
				field_title: 'Число',
				field_name: 'buffer_count',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'cab',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'counterweight',
					},
				],
			},
		],
		section_2_9_5: [
			{
				field_title: 'Закрытия двери кабины / Кабинаның есiгiнiң жабулары',
				field_name: 'closing_cab_door',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Закрытия двери шахты / Шахтаны есiктiң жабулары',
				field_name: 'closing_mine_door',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Автоматического замка двери шахты Шахтаның есiктерi автоматты құлып',
				field_name: 'auto_mine_door_lock',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Загрузки кабины / Кабинаның жүктеулерi',
				field_name: 'cab_loads',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Перегрузки кабины / Кабинаның шамадан тыс жүктеулер',
				field_name: 'cab_overload',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Ограничителя скорости / Жылдамдықтың шектеушiсi',
				field_name: 'speedlimitter',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Ловителей / Аулағыштар',
				field_name: 'catchers',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Слабины тяговых канатов / Тарту арқандарының әлсiздiктерi',
				field_name: 'weakness_of_traction',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Натяжного устройства каната ограничителя скорости / Жылдамдықтар шектеушiнiң арқанының керiп кигiзiлген құрылымы',
				field_name: 'speed_limitter_rope',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Натяжного устройства уравновешивающих канатов / Теңестiрушi арқандарды керiп кигiзiлген құрылымы',
				field_name: 'balancing_rope',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Приямка / Шұңқыр',
				field_name: 'pit',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Кнопка «Стоп» в постах управления (при комплектации НКУ управления УЛ) / (НКУ басқаруды iрiктеп жинақтаудың жанында УЛ) басқару орындарындағы стоп батырма',
				field_name: 'stop_button',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Кнопка «Стоп» в постах управления (при комплектации НКУ управления ШК, ШДК, УПЛ, УЛЖ) / (НКУ, ШК, ШДК, УПЛ, УЛЖ басқаруды iрiктеп жинақтаудың жанында ) басқару орындарындағы «Стоп» батырма',
				field_name: 'stop_button_second',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Кнопка «Стоп» в машинном помещении (НКУ управления лифтом) / (лифтты басқару) машина бөлмесiндегi «Стоп» батырм',
				field_name: 'stop_button_third',
				isRequired: false,
				editable: true,
			},
		],
		section_2_9_7: [
			{
				field_title: 'Разрываемая цепь / Бөлiнетiн шынжыр',
				field_name: 'breaking_chain',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Способ приведения в действие / Әсерге келтiруiн әдiс',
				field_name: 'method_of_actuation',
				isRequired: false,
				editable: true,
			},
		],
		section_3: [
			{
				field_title: 'Статическая нагрузка / Статикалық жүктеме',
				field_name: 'static_load',
				isRequired: false,
				editable: true,
				suffix: 'кг.',
			},
			{
				field_title: 'Динамическая нагрузка / Динамикалық жүктеме',
				field_name: 'dynamic_overload',
				isRequired: false,
				editable: true,
			},
		],
		section_4: [
			{
				field_title: '№ IST',
				field_name: 'no1',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Дата выпуска / Шығарылған күні',
				field_name: 'date_of_issue',
				isRequired: false,
				editable: true,
			},
		],
		section_5: [
			{
				field_title: 'Наименование организации, смонтировавшей лифт / Лифтті құрастырған мекеменің атауы',
				field_name: 'name_of_company',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Дата / Күні',
				field_name: 'date',
				isRequired: false,
				editable: true,
			},
			{
				field_title: 'Представитель монтажной организации / Монтаждық ұйымның өкілі',
				field_name: 'presentative_full_name',
				isRequired: false,
				editable: true,
			},
		],
		section_6: [
			{
				field_title: '',
				field_name: 'value_1',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'name',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'place',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'date',
					},
				],
			},
		],
		section_7: [
			{
				field_title: '',
				field_name: 'value_1',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'date',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'full_name',
					},
				],
			},
		],
		section_8: [
			{
				field_title: '',
				field_name: 'value_1',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'date',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'full_name',
					},
				],
			},
		],
		section_9: [
			{
				field_title: '',
				field_name: 'value_1',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'date',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'repair_details',
					},
				],
			},
		],
		section_10: [
			{
				field_title: '',
				field_name: 'value_1',
				isRequired: false,
				editable: true,
				nestedProperties: [
					{
						isRequired: false,
						editable: true,
						field_name: 'date',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'survey_results',
					},
					{
						isRequired: false,
						editable: true,
						field_name: 'next_survey_deadline',
					},
				],
			},
		],
	},
	sectionData: {
		'section_1': {
			'lift_type': '',
			'factory_number': '',
			'manufacture_date': new Date().toString(),
			'drive_unit': '',
			'allowable_temperature': '',
			'environment': '',
			'regulations': '',
			'lifetime': '',
			'lifting_capacity': '',
			'max_passengers': '',
			'speed': '',
			'speed_revision': '',
			'control_system': '',
			'floors_number': '',
			'stops_number': '',
			'mine_doors_number': '',
			'lifting_height': '',
			'mine_height': '',
		},
		'section_2_1': {
			'on_input_device': {
				'current_type': '',
				'voltage': '',
				'frequency': '',
			},
			'elevator_drive': {
				'current_type': '',
				'voltage': '',
				'frequency': '',
			},
			'doors_drive': {
				'current_type': '',
				'voltage': '',
				'frequency': '',
			},
			'control_circuit': {
				'current_type': '',
				'voltage': '',
				'frequency': '',
			},
			'light_chain_cabs': {
				'current_type': '',
				'voltage': '',
				'frequency': '',
			},
			'light_chain_mine': {
				'current_type': '',
				'voltage': '',
				'frequency': '',
			},
			'signaling_circuit': {
				'current_type': '',
				'voltage': '',
				'frequency': '',
			},
		},
		'section_2_2': {
			'serial_number': '',
			'manufacture_date': '',
			'rated_torque': '',
			'pulley_diameter': '',
			'branch_block_diameter': '',
			'weight': '',
		},
		'section_2_3': {
			'type': '',
			'current_type': '',
			'voltage': '',
			'rated_current': '',
			'frequency': '',
		},
		'section_2_4': {
			'type': '',
			'serial_number': '',
			'current_type': '',
			'voltage': '',
			'frequency': '',
		},
		'section_2_5': {
			'type': '',
			'serial_number': '',
		},
		'section_2_6': {
			'type': '',
			'brake_pulley_diameter': '',
			'brake_drive_type': '',
			'brake_drive_effort': '',
			'brake_drive_step': '',
		},
		'section_2_7': {
			'type': '',
			'serial_no_1': '',
			'serial_no_2': '2',
		},
		'section_2_8': {
			'type': {
				'winches': '',
				'door_drive': '',
			},
			'current_type': {
				'winches': '',
				'door_drive': '',
			},
			'voltage': {
				'winches': '',
				'door_drive': '',
			},
			'rated_current': {
				'winches': '',
				'door_drive': '',
			},
			'frequency': {
				'winches': '',
				'door_drive': '',
			},
			'power': {
				'winches': '',
				'door_drive': '',
			},
			'overheating': {
				'winches': '',
				'door_drive': '',
			},
			'rotation_frequency': {
				'winches': '',
				'door_drive': '',
			},
			'pv': {
				'winches': '',
				'door_drive': '',
			},
			'inclusions_per_hour': {
				'winches': '',
				'door_drive': '',
			},
			'result_with_protection': {
				'winches': '',
				'door_drive': '',
			},
			'weight': {
				'winches': '',
				'door_drive': '',
			},
		},
		'section_2_9': {
			'opening_design': '',
			'doorway_size': '',
			'opening_closing_method': '',
			'drive': '',
			'opening_mine_door_method': '',
			'opening_mine_door_method_2': '',
		},
		'section_2_9_1': {
			'width': '',
			'depth': '',
			'height': '',
			'floor_construction': '',
			'door_construction': '',
			'opening_closing_design': '',
			'door_drive': '',
			'cab_type': '',
			'weight': '',
		},
		'section_2_9_2': {
			'weight': '',
		},
		'section_2_9_3': {
			'main_type': {
				'cab': '',
				'counterweight': '',
				'speed_limitter': '',
				'balancing_elements': '',
			},
			'type': {
				'cab': '',
				'counterweight': '',
				'speed_limitter': '',
				'balancing_elements': '',
			},
			'construction': {
				'cab': '',
				'counterweight': '',
				'speed_limitter': '',
				'balancing_elements': '',
			},
			'symbol': {
				'cab': '',
				'counterweight': '',
				'speed_limitter': '',
				'balancing_elements': '',
			},
			'diameter': {
				'cab': '',
				'counterweight': '',
				'speed_limitter': '',
				'balancing_elements': '',
			},
			'elements_count': {
				'cab': '',
				'counterweight': '',
				'speed_limitter': '',
				'balancing_elements': '',
			},
			'one_element_length': {
				'cab': '',
				'counterweight': '',
				'speed_limitter': '',
				'balancing_elements': '',
			},
			'breaking_force': {
				'cab': '',
				'counterweight': '',
				'speed_limitter': '',
				'balancing_elements': '',
			},
			'safety_factor': {
				'cab': '',
				'counterweight': '',
				'speed_limitter': '',
				'balancing_elements': '',
			},
		},
		'section_2_9_4': {
			'type': {
				'cab': '',
				'counterweight': '',
			},
			'put_into_action': {
				'cab': '',
				'counterweight': '',
			},
			'conditions_for_catchers': {
				'cab': '',
				'counterweight': '',
			},
			'permissible_braking_distance_max': {
				'cab': '',
				'counterweight': '',
			},
			'permissible_braking_distance_min': {
				'cab': '',
				'counterweight': '',
			},
			'limitter_type': {
				'cab': '',
				'counterweight': '',
			},
			'limitter_serial_number': {
				'cab': '',
				'counterweight': '',
			},
			'limitter_action_speed': {
				'cab': '',
				'counterweight': '',
			},
			'limitter_effort': {
				'cab': '',
				'counterweight': '',
			},
			'buffer_type': {
				'cab': '',
				'counterweight': '',
			},
			'buffer_stroke': {
				'cab': '',
				'counterweight': '',
			},
			'buffer_count': {
				'cab': '',
				'counterweight': '',
			},
		},
		'section_2_9_5': {
			'closing_cab_door': true,
			'closing_mine_door': true,
			'auto_mine_door_lock': true,
			'cab_loads': true,
			'cab_overload': true,
			'speedlimitter': true,
			'catchers': true,
			'weakness_of_traction': true,
			'speed_limitter_rope': true,
			'balancing_rope': false,
			'pit': true,
			'stop_button': true,
			'stop_button_second': true,
			'stop_button_third': false,
		},
		'section_2_9_7': {
			'breaking_chain': '',
			'method_of_actuation': '',
		},
		'section_3': {
			'static_load': '',
			'dynamic_overload': '',
		},
		'section_4': {
			'no1': '',
			'date_of_issue': '',
		},
		'section_5': {
			'name_of_company': '',
			'date': '',
			'presentative_full_name': '',
		},
		'section_6': {
			'value_1': {
				'name': '',
				'place': '',
				'date': '',
			},
		},
		'section_7': {
			'value_1': {
				'date': '',
				'full_name': '',
			},
		},
		'section_8': {
			'value_1': {
				'date': '',
				'full_name': '',
			},
		},
		'section_9': {
			'value_1': {
				'date': '',
				'repair_details': '',
			},
		},
		'section_10': {
			'value_1': {
				'date': '',
				'survey_results': '',
				'next_survey_deadline': '',
			},
		},
	},
	editPassportTabs: [
		{
			title: 'Тех. характеристики лифта',
			idx: 0,
		},
		{
			title: 'Акты',
			idx: 1,
		},
		{
			title: 'Ответственные специалисты',
			idx: 2,
		},
		{
			title: 'Данные о ремонте и модернизации',
			idx: 3,
		},
		{
			title: 'Техническое освидетельствование и Диагностика',
			idx: 4,
		},
	],
	activePassportTabIdx: 0,
	editPassportActsTabs: [
		{
			title: 'Общие данные объекта',
			idx: 0,
		},
		{
			title: 'Тех. характеристика',
			idx: 1,
		},
		{
			title: 'Статус',
			idx: 2,
		},
		{
			title: 'Предпросмотр документа',
			idx: 3,
		},
	],
	activePassportActsTabIdx: 0,
	passportActInfoData: {
		number: '',
		owner: '',
		address: '',
		appointment: '',
		installationOrganizationRepresentative: null,
		buildingOrganizationRepresentative: null,
		electromechanic: '',
	},
	passportActSpecificationData: {
		liftingCapacity: '',
		factoryNumber: '',
		ratedSpeed: '',
		manufactureYear: '',
		liftingHeight: '',
		stopsNumber: '',
	},
	passportActsStatusData: {
		signer: [],
	},
	passportActsPreviewData: '',
	getPassportActsPreviewLoading: false,
	getPassportActsPreviewLoaded: true,
	passportActsItems: [],
	getPassportActsListLoading: false,
	getPassportActsListLoaded: true,
}


export default passportReducer
