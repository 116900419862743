import axios from 'axios'
import {apiConstants} from '../../constants/api.constants'

export const userActions = {
	setUser,
	getUsersByFilter,
	getCompaniesByFilter,
}

function setUser(payload) {
	return {
		type: 'SET_USER',
		payload,
	}
}

async function getUsersByFilter(requestData, toast, authToken) {
	return await axios
		.get(`${apiConstants.apiUrl}/api/User/GetUsersByFilter?companyId=${requestData.companyId || ''}&inputFilter=${requestData.inputFilter}`, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}

			if (!res || res && res.data.code !== '0') {
				toast({
					title: 'Фильтр',
					description: 'Техническая ошибка. Попробуйте позже',
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
				return []
			} else {
				return res.data.data
			}
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}

			toast({
				title: 'Фильтр',
				description: `Техническая ошибка. ${err}`,
				status: 'error',
				duration: 4000,
				isClosable: true,
			})
			return []
		})
}

async function getCompaniesByFilter(searchValue, toast, authToken) {
	return await axios
		.get(`${apiConstants.apiUrl}/api/User/GetCompaniesByFilter?inputFilter=${searchValue}`, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}

			if (!res || res && res.data.code !== '0') {
				toast({
					title: 'Фильтр',
					description: 'Техническая ошибка. Попробуйте позже',
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
				return []
			} else {
				return res.data.data
			}
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}

			toast({
				title: 'Фильтр',
				description: `Техническая ошибка. ${err}`,
				status: 'error',
				duration: 4000,
				isClosable: true,
			})
			return []
		})
}

