import React from 'react'
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
} from '@chakra-ui/react'
import {useSelector, useDispatch} from 'react-redux'

import MainInfo from './MainInfo'
import DocsInfo from './DocsInfo'
import SecurityInfo from './SecurityInfo'
import GeneralInfo from './GeneralInfo'
import {profileActions} from '../../../redux/actions'

const Edit = React.forwardRef((props, ref) => {

	const editTabs = useSelector(state => state.profile.editTabs)
	const activeTabIdx = useSelector(state => state.profile.activeTabIdx)

	const dispatch = useDispatch()
	const {isOpen, onOpen, onClose} = useDisclosure()

	const initialRef = React.useRef()
	const finalRef = React.useRef()

	React.useImperativeHandle(ref, () => ({
		openModal() {
			onOpen()
		},
	}))

	const setActiveTabIdx = idx => {
		dispatch(profileActions.setActiveEditTabIdx(idx))
	}

	if (activeTabIdx > 3) {
		setActiveTabIdx(0)
	}

	console.log('activeTabIdx: ' + activeTabIdx)

	return (
		<>
			<div className='lift__profile_edit'>
				<Modal
					initialFocusRef={initialRef}
					finalFocusRef={finalRef}
					isOpen={isOpen}
					onClose={onClose}
					width={900}
				>
					<ModalOverlay />
					<ModalContent maxWidth={780}>

						<ModalHeader>Редактировать профиль</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<Box className='profile__edit_tabs'>
								{editTabs.map(tab =>
									<button key={tab.idx} className={`profile__edit_tab ${activeTabIdx === tab.idx && 'active'}`}
										onClick={() => setActiveTabIdx(tab.idx)}>
										{tab.title}
									</button>,
								)}
							</Box>

							{activeTabIdx === 0
								? <MainInfo initialRef={initialRef}></MainInfo>
								: activeTabIdx === 1
									? <DocsInfo initialRef={initialRef}></DocsInfo>
									: activeTabIdx === 2
										? <SecurityInfo initialRef={initialRef}></SecurityInfo>
										: activeTabIdx === 3
											? <GeneralInfo initialRef={initialRef}></GeneralInfo> : ''
							}
						</ModalBody>
					</ModalContent>

				</Modal>
			</div>
		</>
	)
})

export default Edit
