import React from 'react'
import {
	Box,
	Icon,
} from '@chakra-ui/react'
import {default as SlickSlider} from 'react-slick'
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md'

const Slider = () => {

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	}

	return (
		<Box>
			<SlickSlider {...settings} className='lift__profile_slider'>
				<div className='lift__profile_slider_item'>
					<img src={window.location.origin + '/images/img1.png'} alt='' />
				</div>
				<div className='lift__profile_slider_item'>
					<img src={window.location.origin + '/images/img2.png'} alt='' />
				</div>
				<div className='lift__profile_slider_item'>
					<img src={window.location.origin + '/images/img3.png'} alt='' />
				</div>
				<div className='lift__profile_slider_item'>
					<img src={window.location.origin + '/images/img4.png'} alt='' />
				</div>
				<div className='lift__profile_slider_item'>
					<img src={window.location.origin + '/images/img1.png'} alt='' />
				</div>
				<div className='lift__profile_slider_item'>
					<img src={window.location.origin + '/images/img2.png'} alt='' />
				</div>
				<div className='lift__profile_slider_item'>
					<img src={window.location.origin + '/images/img3.png'} alt='' />
				</div>
				<div className='lift__profile_slider_item'>
					<img src={window.location.origin + '/images/img4.png'} alt='' />
				</div>
			</SlickSlider>
		</Box>
	)
}

function SampleNextArrow(props) {
	const {className, style, onClick} = props
	return (
		<div onClick={onClick} className='lift__slider_icon right'>
			<Icon as={MdKeyboardArrowRight} />
		</div>
	)
}

function SamplePrevArrow(props) {
	const {className, style, onClick} = props
	return (
		<div onClick={onClick} className='lift__slider_icon left'>
			<Icon as={MdKeyboardArrowLeft} />
		</div>
	)
}

export default Slider
