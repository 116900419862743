import {staffConstants} from '../../constants/staff.constants'

export const staffActions = {
	setData,
	setIsEdit,
	setActiveEditTabIdx,
	setActiveMainTabIdx,
	setEditItemId,
}

function setData(payload) {
	return {
		type: staffConstants.SET_DATA,
		payload,
	}
}

function setIsEdit(payload) {
	return {
		type: staffConstants.SET_IS_EDIT,
		payload,
	}
}

function setActiveEditTabIdx(payload) {
	return {
		type: staffConstants.SET_ACTIVE_EDIT_TAB_IDX,
		payload,
	}
}

function setActiveMainTabIdx(payload) {
	return {
		type: staffConstants.SET_ACTIVE_MAIN_TAB_IDX,
		payload,
	}
}

function setEditItemId(payload) {
	return {
		type: staffConstants.SET_EDIT_ITEM_ID,
		payload,
	}
}


