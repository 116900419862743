import React from 'react'
import {
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	FormErrorMessage,
	SimpleGrid,
	Textarea,
	Box,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Select,
} from '@chakra-ui/react'
import {useSelector, useDispatch} from 'react-redux'
import {Formik, Form, Field} from 'formik'

import Plus from '../../../components/Icons/Plus'

const AccessInfo = props => {
	const dispatch = useDispatch()
	const data = useSelector(state => state.staff.data)

	const submit = submitData => {
		console.log(submitData)
	}

	return (
		<>

			<Formik
				initialValues={data}
				enableReinitialize

				onSubmit={(values, actions) => {
					setTimeout(() => {
						submit(values)
						actions.setSubmitting(false)
					}, 1000)
				}}
			>
				<Form className='lift__profile_edit_formik'>

					<SimpleGrid columns={1} spacing={4} mt={4}>

						<Field name='doc' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.doc && touched.doc}>
									<FormLabel> Доступ к документам</FormLabel>
									<Select placeholder='Выберите документ' size='md' color='gray.400' {...field}>
										<option>Должность 1</option>
										<option>Должность 2</option>
									</Select>
									<FormErrorMessage>{errors.doc}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

					</SimpleGrid>

					<Box mt='4' display='flex' justifyContent='flex-end' >
						<Button type='submit' colorScheme='blue' mr={3} isLoading={props.isSubmitting}>
						Сохранить
						</Button>
					</Box>
				</Form>
			</Formik>
		</>
	)
}

export default AccessInfo
