import ncalayerReducer from './ncalayer.reducer'
import passportReducer from './passport.reducer'
import userReducer from './user.reducer'
import authReducer from './auth.reducer'
import profileReducer from './profile.reducer'
import staffReducer from './staff.reducer'
import {alert} from './alert.reducer'
import {combineReducers} from 'redux'

const allReducers = combineReducers({
	ncalayer: ncalayerReducer,
	passport: passportReducer,
	auth: authReducer,
	user: userReducer,
	alert,
	profile: profileReducer,
	staff: staffReducer,
})

export default allReducers
