
import {ncalayerConstants} from '../../constants/ncalayer.constants'

const initialState = {
	wsUrl: 'wss://127.0.0.1:13579/',
	ws: undefined,
	isNcalayerEnabled: false,
	tokensArr: [],
	callbackType: 0,
	loading: false,
	error: null,
	requestXML: '',
	responseXML: '',
	signSuccess: false,
	signLoading: false,
	signResult: {},
	tokensModalOpen: false,
	signType: 'SIGNATURE',
	listenerType: 'REGISTRATION',
	requestBase64: '',
	responseBase64: '',
}

const ncalayerReducer = (state = initialState, action) => {
	switch (action.type) {
		case ncalayerConstants.SETUP_WS:
			return {
				...state,
				ws: action.payload.ws,
			}
		case ncalayerConstants.SET_CALLBACK_TYPE:
			return {
				...state,
				callbackType: action.payload,
			}
		case ncalayerConstants.SET_TOKENS:
			return {
				...state,
				tokensArr: action.payload,
				tokensModalOpen: true,
			}
		case ncalayerConstants.INIT_NCALAYER_STARTED:
			return {
				...state,
				loading: true,
			}
		case ncalayerConstants.INIT_NCALAYER_SUCCESS:
			console.log('NCALayer open')
			return {
				...state,
				loading: false,
				error: null,
				isNcalayerEnabled: true,
			}
		case ncalayerConstants.INIT_NCALAYER_FAILURE:
			console.log('NCALayer close')
			return {
				...state,
				loading: false,
				error: action.payload.error,
				isNcalayerEnabled: false,
			}
		case ncalayerConstants.SET_REQUEST_XML:
			return {
				...state,
				requestXML: action.payload,
			}
		case ncalayerConstants.SET_REQUEST_BASE64:
			return {
				...state,
				requestBase64: action.payload,
			}
		case ncalayerConstants.SET_RESPONSE_XML:
			return {
				...state,
				responseXML: action.payload.xml,
			}
		case ncalayerConstants.SET_RESPONSE_BASE64:
			return {
				...state,
				responseBase64: action.payload.base64,
			}
		case ncalayerConstants.SET_SIGN_LOADING:
			return {
				...state,
				signLoading: action.payload,
			}
		case ncalayerConstants.SET_SIGN_SUCCESS:
			return {
				...state,
				signSuccess: action.payload,
			}
		case ncalayerConstants.SET_SIGN_RESULT:
			return {
				...state,
				signResult: action.payload,
			}
		case ncalayerConstants.SET_SIGN_TYPE:
			return {
				...state,
				signType: action.payload,
			}
		case ncalayerConstants.SET_LISTENER_TYPE:
			return {
				...state,
				listenerType: action.payload,
			}
		case ncalayerConstants.SET_TOKENS_MODAL_OPEN:
			return {
				...state,
				tokensModalOpen: action.payload,
			}
		default:
			return state
	}
}

export default ncalayerReducer
