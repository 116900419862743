import axios from 'axios'
import {Base64} from 'js-base64'
import {authConstants} from '../../constants/auth.constants'
import {apiConstants} from '../../constants/api.constants'
import {history} from '../../helpers'

export const authActions = {
	setAuthResponse,
	setAuthData,
	setRegistrationData,
	setIsRegistrationSignOk,
	setRegistrationSignResponse,
	setAuthView,
	setAuthLoaded,
	setAuthToken,
	auth,
	register,
	getDataBySign,
	authBySign,
	logout,
}

function setAuthResponse(payload) {
	return {
		type: authConstants.SET_AUTH_RESPONSE,
		payload,
	}
}

function setAuthData(payload) {
	return {
		type: authConstants.SET_AUTH_DATA,
		payload,
	}
}

function setRegistrationData(payload) {
	return {
		type: authConstants.SET_REGISTRATION_DATA,
		payload,
	}
}

function setIsRegistrationSignOk(payload) {
	return {
		type: authConstants.SET_REGISTRATION_SIGN_OK,
		payload,
	}
}

function setRegistrationSignResponse(payload) {
	return {
		type: authConstants.SET_REGISTRATION_SIGN_RESPONSE,
		payload,
	}
}

function setAuthView(payload) {
	return {
		type: authConstants.SET_AUTH_VIEW,
		payload,
	}
}

function setAuthLoaded(payload) {
	return {
		type: authConstants.SET_AUTH_LOADED,
		payload,
	}
}

function setLogout(payload) {
	return {
		type: authConstants.SET_LOGOUT,
	}
}

function setAuthToken(payload) {
	return {
		type: authConstants.SET_AUTH_TOKEN,
		payload,
	}
}

const getDataBySignSuccess = payload => ({
	type: authConstants.GET_DATA_BY_SIGN_SUCCESS,
	payload: {
		...payload,
	},
})

const getDataBySignStarted = () => ({
	type: authConstants.GET_DATA_BY_SIGN_STARTED,
})

const getDataBySignFailure = error => ({
	type: authConstants.GET_DATA_BY_SIGN_FAILURE,
	payload: {
		error,
	},
})

const registerSuccess = payload => ({
	type: authConstants.REGISTER_SUCCESS,
	payload: {
		...payload,
	},
})

const registerStarted = () => ({
	type: authConstants.REGISTER_STARTED,
})

const registerFailure = error => ({
	type: authConstants.REGISTER_FAILURE,
	payload: {
		error,
	},
})

const authSuccess = payload => ({
	type: authConstants.AUTH_SUCCESS,
	payload: {
		...payload,
	},
})

const authStarted = () => ({
	type: authConstants.AUTH_STARTED,
})

const authFailure = error => ({
	type: authConstants.SET_AUTH_RESPONSE,
	payload: {
		error,
	},
})

const setUser = payload => ({
	type: authConstants.SET_USER,
	payload,
})


function getDataBySign(toast) {
	return (dispatch, getState) => {

		dispatch(getDataBySignStarted())
		let requestData = getState().auth.registrationData

		requestData = {
			...requestData,
			xml: Base64.encode(requestData.xml),
		}

		axios
			.post(`${apiConstants.apiUrl}/api/Auth/Register`, requestData)
			.then(res => {
				if (res.data.code === '0') {
					dispatch(getDataBySignSuccess(res.data))

					dispatch(setRegistrationSignResponse(res.data.data))

					let registrationData = getState().auth.registrationData
					registrationData = {
						...registrationData,
						chiefName: res.data.data.chiefName,
						companyName: res.data.data.companyName,
					}

					dispatch(setRegistrationData(registrationData))
				} else {
					dispatch(getDataBySignFailure(res.data.message))
					toast({
						title: 'Регистрация',
						description: res.data.message,
						status: 'error',
						duration: 4000,
						isClosable: true,
					})
				}
			})
			.catch(err => {
				dispatch(getDataBySignFailure(err.message))
				toast({
					title: 'Регистрация',
					description: 'Техническая ошибка. Попробуйте позже',
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			})
	}
}

function register(payload, toast) {
	return (dispatch, getState) => {

		dispatch(registerStarted())

		let requestData = payload

		requestData = {
			...requestData,
			xml: Base64.encode(requestData.xml),
			// CompanyTypeEnum: roles,
			// sid: state.auth.registrationSignResponse.token,
			// IIN: state.auth.registrationSignResponse.IIN,
			// BIN: state.auth.registrationSignResponse.BIN,
			// firstName: state.auth.registrationSignResponse.firstName,
			// lastName: state.auth.registrationSignResponse.lastName,
			// middleName: state.auth.registrationSignResponse.middleName,
		}

		axios
			.post(`${apiConstants.apiUrl}/api/Auth/RegisterPass`, requestData)
			.then(res => {

				if (res.data.code === '0') {
					dispatch(registerSuccess(res.data))

					toast({
						title: 'Регистрация',
						description: 'Регистрация прошла успешно',
						status: 'success',
						duration: 4000,
						isClosable: true,
					})
				} else {
					dispatch(registerFailure(res.data.message))
					toast({
						title: 'Регистрация',
						description: res.data.message,
						status: 'error',
						duration: 4000,
						isClosable: true,
					})
				}
			})
			.catch(err => {
				dispatch(registerFailure(err.message))
				toast({
					title: 'Регистрация',
					description: err.message,
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			})
	}
}

function authBySign(payload, toast) {
	return (dispatch, getState) => {

		dispatch(authBySignStarted())
		let requestData = {
			xml: Base64.encode(payload.xml),
		}

		axios
			.post(`${apiConstants.apiUrl}/api/Auth/LogInEds`, requestData)
			.then(res => {
				if (res.data.code === '0') {
					localStorage.setItem('auth_token', res.data.data.token)
					localStorage.setItem('user', JSON.stringify(res.data.data.user))
					localStorage.setItem('company', JSON.stringify(res.data.data.company))
					localStorage.setItem('isAuthorized', true)

					dispatch(authBySignSuccess(res.data))
					dispatch(setAuthToken(res.data.data.token))
					dispatch(setAuthResponse(res.data.data))
					// dispatch(setUser(res.data.data.user))
					dispatch(authSuccess({
						code: res.data.code,
						user: res.data.data.user,
						company: res.data.data.company,
					}))

					toast({
						title: 'Авторизация',
						description: 'Авторизация прошла успешно',
						status: 'success',
						duration: 4000,
						isClosable: true,
					})

					history.push('/')
				} else {
					dispatch(authBySignFailure(res.data.message))
					toast({
						title: 'Авторизация',
						description: res.data.message,
						status: 'error',
						duration: 4000,
						isClosable: true,
					})
				}
			})
			.catch(err => {
				dispatch(authBySignFailure(err.message))
				toast({
					title: 'Авторизация',
					description: 'Техническая ошибка. Попробуйте позже',
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			})

		function authBySignStarted(requestPayload) { return {type: authConstants.AUTH_BY_SIGN_STARTED, payload: requestPayload} }
		function authBySignSuccess(successPayload) { return {type: authConstants.AUTH_BY_SIGN_SUCCESS, payload: successPayload} }
		function authBySignFailure(failurePayload) { return {type: authConstants.AUTH_BY_SIGN_FAILURE, payload: failurePayload} }
	}
}

function auth(payload, toast) {
	return dispatch => {

		dispatch(authStarted())

		let requestData = payload

		requestData = {
			...requestData,
		}

		axios
			.post(`${apiConstants.apiUrl}/api/Auth/LogIn`, requestData)
			.then(res => {

				if (res.data.code === '0') {
					localStorage.setItem('auth_token', res.data.data.token)
					localStorage.setItem('user', JSON.stringify(res.data.data.user))
					localStorage.setItem('company', JSON.stringify(res.data.data.company))
					localStorage.setItem('isAuthorized', true)

					dispatch(setAuthToken(res.data.data.token))
					dispatch(setAuthResponse(res.data.data))
					dispatch(setUser(res.data.data.user))
					dispatch(authSuccess({
						code: res.data.code,
						user: res.data.data.user,
						company: res.data.data.company,
					}))

					toast({
						title: 'Авторизация',
						description: 'Авторизация прошла успешно',
						status: 'success',
						duration: 4000,
						isClosable: true,
					})
					history.push('/')
				} else {
					dispatch(authFailure({
						code: res.data.code,
						message: res.data.data.message,
					}))
					toast({
						title: 'Авторизация',
						description: res.data.data.message,
						status: 'error',
						duration: 4000,
						isClosable: true,
					})
				}
			})
			.catch(err => {
				dispatch(authFailure({
					code: '-1',
					message: err.message,
				}))
				toast({
					title: 'Авторизация',
					description: err.message,
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			})
	}
}

function logout() {
	return dispatch => {
		localStorage.clear()
		dispatch(setLogout())
	}
}
