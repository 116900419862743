import React, {useEffect} from 'react'
import {
	Box,
	Text,
} from '@chakra-ui/react'
import {useSelector, useDispatch} from 'react-redux'
import {passportActions} from '../redux/actions'
import EditSpecifications from '../components/Passport/Specifications/EditSpecifications'
import EditLiftSpecifications from '../components/Passport/Specifications/EditLiftSpecifications'
import EditActs from '../components/Passport/Acts/EditActs'

const Passport = props => {
	const dispatch = useDispatch()

	const passportTabs = useSelector(state => state.passport.editPassportTabs)
	const activeTabIdx = useSelector(state => state.passport.activePassportTabIdx)

	const setActiveTabIdx = idx => {
		dispatch(passportActions.setActivePassportEditTabIdx(idx))
	}

	if (activeTabIdx > 1) {
		setActiveTabIdx(0)
	}

	useEffect(() => () => {
		dispatch(passportActions.setCurrentPassportId(''))
	}, [])

	return (
		<div>
			<div className='lift_passport_container'>
				<Box className='passport__edit_tabs'>
					{passportTabs.map(tab =>
						<button key={'passport-' + tab.idx} className={`passport__edit_tab ${activeTabIdx === tab.idx && 'active'}`}
							onClick={() => setActiveTabIdx(tab.idx)}>
							{tab.title}
						</button>,
					)}
				</Box>

				{activeTabIdx === 0
					? <EditLiftSpecifications {...props}></EditLiftSpecifications>
					: activeTabIdx === 1
						? <EditActs {...props}></EditActs>
						: ''
				}
			</div>
		</div >
	)
}

export default Passport
