import {extendTheme} from '@chakra-ui/react'
import {mode} from '@chakra-ui/theme-tools'

const colors = {
	primary: {
		100: '#0D4B73',
		200: '#0F5380',
		300: '#115C8E',
		400: '#13669E',
		500: '#1571AF',
		600: '#177CC1',
		700: '#1988D4',
		800: '#2095E5',
		900: '#37A0E8',
	},
	secondary: {
		100: '#1D2830',
		200: '#202D35',
		300: '#24323B',
		400: '#283842',
		500: '#2C3E49',
		600: '#304450',
		700: '#354B58',
		800: '#3A5361',
		900: '#405B6B',
	},
	tertiary: {
		100: '#28730D',
		200: '#2D800F',
		300: '#328E11',
		400: '#379E13',
		500: '#3DAF15',
		600: '#43C117',
		700: '#4AD419',
		800: '#54E520',
		900: '#66E837',
	},
}

const components = {
	Button: {
		baseStyle: props => ({}),
		variants: {
			solid: ({colorScheme}) => ({
				bg: mode(`${colorScheme}.500`, `${colorScheme}.500`)(colorScheme),
				borderRadius: '2px',
				fontWeight: 500,
				fontSize: '14px',
				_hover: {
					bg: `${colorScheme}.600`,
				},
				_active: {bg: `${colorScheme}.400`},
			}),
		},
	},
	Input: {
		baseStyle: props => ({
			borderRadius: '2px',
		}),
	},
}

const styles = {
	global: props => ({
		body: {
			color: mode('gray.900', 'whiteAlpha.900')(props),
			bg: mode('#F4F6F8', '#F4F6F8')(props),
		},
	}),
}


const customTheme = extendTheme({
	colors,
	fonts: {
		heading: 'Roboto,"Segoe UI",Tahoma,Geneva,Verdana,sans-serif',
		body: 'Roboto,"Segoe UI",Tahoma,Geneva,Verdana,sans-serif',
	},
	initialColorMode: 'light',
	useSystemColorMode: false,
	components,
	styles,
})

export default customTheme
