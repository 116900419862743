import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {ncaLayerActions} from '../../redux/actions'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalCloseButton,
	Button,
	useToast,
} from '@chakra-ui/react'

const Ncalayer = () => {
	const dispatch = useDispatch()
	const tokensArr = useSelector(state => state.ncalayer.tokensArr)
	const tokensModalOpen = useSelector(state => state.ncalayer.tokensModalOpen)
	const signLoading = useSelector(state => state.ncalayer.signLoading)
	const requestXML = useSelector(state => state.ncalayer.requestXML)
	const requestBase64 = useSelector(state => state.ncalayer.requestBase64)
	const listenerType = useSelector(state => state.ncalayer.listenerType)

	const finalRef = React.useRef()
	const toast = useToast()

	React.useEffect(() => {
		dispatch(ncaLayerActions.initNCALayer(toast))

		return () => {
		}
	}, [])

	const signXMLsAction = token => {
		dispatch(ncaLayerActions.signXmlCallNew({
			xml: requestXML,
			token,
		}))
	}

	const onModalClose = () => {
		dispatch(ncaLayerActions.setTokensModalOpen(false))
	}

	return (
		<main>
			<Modal finalFocusRef={finalRef} isOpen={tokensModalOpen} onClose={onModalClose} isCentered>
				<ModalOverlay />
				<ModalContent display='flex' alignItems='center' justifyContent='center'>
					{/* <ModalCloseButton /> */}
					<ModalFooter>
						{tokensArr.map((x, idx) =>
							<Button colorScheme='blue' key={idx} isLoading={signLoading} onClick={() => signXMLsAction(x.token)}>Выбрать сертификат</Button>,
						)
						}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</main>
	)
}

export default Ncalayer
