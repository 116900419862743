import {profileConstants} from '../../constants/profile.constants'

export const profileActions = {
	setData,
	setIsEdit,
	setActiveEditTabIdx,
	setActiveMainTabIdx,
}

function setData(payload) {
	return {
		type: profileConstants.SET_DATA,
		payload,
	}
}

function setIsEdit(payload) {
	return {
		type: profileConstants.SET_IS_EDIT,
		payload,
	}
}

function setActiveEditTabIdx(payload) {
	return {
		type: profileConstants.SET_ACTIVE_EDIT_TAB_IDX,
		payload,
	}
}

function setActiveMainTabIdx(payload) {
	return {
		type: profileConstants.SET_ACTIVE_MAIN_TAB_IDX,
		payload,
	}
}


