import React, {useState, useEffect} from 'react'
import {
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	FormErrorMessage,
	SimpleGrid,
	Textarea,
	Button,
	useToast,
} from '@chakra-ui/react'
import {AsyncSelect} from 'chakra-react-select'
import {useSelector} from 'react-redux'
import {userActions, dictionaryActions} from '../../../redux/actions'
import AsyncDictSelect from '../AsyncDictSelect'

const EditActsInfo = React.forwardRef((props, ref) => {
	const toast = useToast()

	const authToken = useSelector(state => state.auth.authToken)
	const stateSectionData = useSelector(state => state.passport.sectionData)
	const user = useSelector(state => state.auth.user)

	const [isSubmit, setSubmit] = useState(false)
	const [formData, setFormData] = useState({
		...props.actData,
		dictEquipmentAppointmentsId: stateSectionData && stateSectionData.section_1 && stateSectionData.section_1.lift_type ? stateSectionData.section_1.lift_type : null,
	})

	useEffect(() => {
		setFormData({
			...props.actData,
			dictEquipmentAppointmentsId: stateSectionData && stateSectionData.section_1 && stateSectionData.section_1.lift_type ? stateSectionData.section_1.lift_type : null,
		})
	}, [props.actData])

	React.useImperativeHandle(ref, () => ({
		onTabChange() {
			props.setTabData(formData, null)
		},
	}))

	const loadOptions = (searchValue, callback) => {
		setTimeout(async () => {
			callback(await filterUsers(searchValue))
		}, 1000)
	}

	const loadBuildingOrganizationRepresentativeOptions = (searchValue, callback) => {
		setTimeout(async () => {
			callback(await filterUsers(searchValue))
		}, 1000)
	}

	const filterUsers = async (searchValue, companyId) => await userActions.getUsersByFilter({companyId, inputFilter: searchValue}, toast, authToken)

	const loadCompaniesOptions = (searchValue, callback) => {
		setTimeout(async () => {
			callback(await filterCompanies(searchValue))
		}, 1000)
	}

	const filterCompanies = async searchValue => await userActions.getCompaniesByFilter(searchValue, toast, authToken)

	const onAsyncSelectChange = (fieldName, value) => {
		setFormData({...formData, [fieldName]: value})
	}

	const onNextClick = () => {
		setSubmit(true)
		if (isValid()) {
			props.setTabData(formData, 1)
		}
	}

	const isValid = () => {
		if (formData.buildingOrganization
			&& formData.installationOrganization
			&& formData.buildingOrganizationRepresentative
			&& formData.installationOrganizationRepresentative) {
			return true
		}
		return true
	}

	const onChange = (fieldName, val) => {
		if (fieldName == 'buildingOrganizationRepresentative' && formData.installationOrganizationRepresentative && val
			&& formData.installationOrganizationRepresentative.value == val.value
			|| fieldName == 'installationOrganizationRepresentative' && formData.buildingOrganizationRepresentative && val
			&& formData.buildingOrganizationRepresentative.value == val.value) {
			toast({
				title: 'Сотрудник',
				description: 'Нельзя выбрать одинаковых представителей монтажной организации и генподрядной строительной организации',
				status: 'warning',
				duration: 4000,
				isClosable: true,
			})
		} else if (val && (fieldName == 'buildingOrganizationRepresentative'|| fieldName == 'installationOrganizationRepresentative') && user.id == val.value) {
			toast({
				title: 'Сотрудник',
				description: 'Нельзя выбрать себя как представитель монтажной организации и генподрядной строительной организации',
				status: 'warning',
				duration: 4000,
				isClosable: true,
			})
		} else {
			setFormData({...formData, [fieldName]: val})
		}
	}

	return (<>
		<SimpleGrid columns={2} spacing={4} mt={4}>
			<SimpleGrid columns={1} spacing={4} mt={4}>
				<FormControl isInvalid={isSubmit && !formData.number}>
					<FormLabel>Номер акта</FormLabel>
					<InputGroup>
						<Input
							type='text'
							placeholder='-'
							bg='white'
							isDisabled={true}
							autoComplete='off'
							value={formData.number}
							onChange={e => onChange('number', e.target.value)} />
					</InputGroup>
					<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={isSubmit && !formData.owner}>
					<FormLabel>Владелец</FormLabel>
					<InputGroup>
						<Input
							type='text'
							placeholder='Владелец'
							bg='white'
							autoComplete='off'
							value={formData.owner}
							isDisabled={true}
							onChange={e => onChange('owner', e.target.value)} />
					</InputGroup>
					<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={isSubmit && !formData.address}>
					<FormLabel>Адрес оборудования</FormLabel>
					<InputGroup>
						<Textarea
							bg='white'
							placeholder='Адрес'
							isDisabled={props.isDisabled}
							autoComplete='off'
							value={formData.address}
							onChange={e => onChange('address', e.target.value)} />
					</InputGroup>
					<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={isSubmit && !formData.dictEquipmentAppointmentsId}>
					<FormLabel>Тип оборудования</FormLabel>
					<InputGroup>
						<AsyncDictSelect
							isDisabled={props.isDisabled}
							isEditMode={true}
							dictionaryName='DictEquipmentAppointments'
							additionalCondition=''
							fieldName='dictEquipmentAppointmentsId'
							selectedValue={formData.dictEquipmentAppointmentsId}
							onChange={onChange} />
					</InputGroup>
					<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={false}>
					<FormLabel>Экспертная организация</FormLabel>
					<InputGroup>
						<Input
							type='text'
							placeholder='-'
							bg='white'
							autoComplete='off'
							isDisabled={true}
							value={''} />
					</InputGroup>
					<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
				</FormControl>

			</SimpleGrid>

			<SimpleGrid columns={1} spacing={4} mt={4}>

				<FormControl isInvalid={isSubmit && !formData.installationOrganization}>
					<FormLabel>Монтажная организация</FormLabel>
					<InputGroup>
						<AsyncSelect
							defaultOptions
							isClearable
							isDisabled={props.isDisabled}
							loadOptions={loadCompaniesOptions}
							placeholder='Выберите из списка'
							onChange={value => onAsyncSelectChange('installationOrganization', value)}
							value={formData.installationOrganization}
							defaultValue={formData.installationOrganization} />
					</InputGroup>
					<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={isSubmit && !formData.installationOrganizationRepresentative}>
					<FormLabel>Представитель монтажной организации</FormLabel>
					<InputGroup>
						<AsyncSelect
							defaultOptions
							isClearable
							isDisabled={formData.installationOrganization == null || props.isDisabled}
							loadOptions={loadBuildingOrganizationRepresentativeOptions}
							placeholder='Выберите из списка'
							onChange={value => onAsyncSelectChange('installationOrganizationRepresentative', value)}
							value={formData.installationOrganizationRepresentative}
							defaultValue={formData.installationOrganizationRepresentative} />
					</InputGroup>
					<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={isSubmit && !formData.buildingOrganization}>
					<FormLabel>Генподрядная организация</FormLabel>
					<InputGroup>
						<AsyncSelect
							defaultOptions
							isClearable
							isDisabled={props.isDisabled}
							loadOptions={loadCompaniesOptions}
							placeholder='Выберите из списка'
							onChange={value => onAsyncSelectChange('buildingOrganization', value)}
							value={formData.buildingOrganization}
							defaultValue={formData.buildingOrganization} />
					</InputGroup>
					<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={isSubmit && !formData.buildingOrganizationRepresentative}>
					<FormLabel>Представитель генподрядной строительной организации</FormLabel>
					<InputGroup>
						<AsyncSelect
							defaultOptions
							isClearable
							isDisabled={formData.buildingOrganization == null || props.isDisabled}
							loadOptions={loadOptions}
							placeholder='Выберите из списка'
							onChange={value => onAsyncSelectChange('buildingOrganizationRepresentative', value)}
							value={formData.buildingOrganizationRepresentative}
							defaultValue={formData.buildingOrganizationRepresentative} />
					</InputGroup>
					<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={isSubmit && !formData.electromechanic}>
					<FormLabel>Эксперт</FormLabel>
					<InputGroup>
						<Input
							type='text'
							placeholder='-'
							bg='white'
							autoComplete='off'
							isDisabled={true}
							value={formData.electromechanic} />
					</InputGroup>
					<FormErrorMessage>{isSubmit && 'Поле не заполнено'}</FormErrorMessage>
				</FormControl>

			</SimpleGrid>

		</SimpleGrid>

		<Box mt='4' display='flex' justifyContent='flex-end'>
			<Button colorScheme='blue' mr={3} onClick={onNextClick}>
				Далее
			</Button>
		</Box>
	</>)
})

export default EditActsInfo
