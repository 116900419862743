import React from 'react'
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	Select,
	FormLabel,
	FormControl,
} from '@chakra-ui/react'
import {useSelector, useDispatch} from 'react-redux'

import MainInfo from './MainInfo'
import DocsInfo from './DocsInfo'
import SecurityInfo from './SecurityInfo'
import GeneralInfo from './GeneralInfo'
import AppointmentInfo from './AppointmentInfo'
import AccessInfo from './AccessInfo'
import {staffActions} from '../../../redux/actions'

const Edit = React.forwardRef((props, ref) => {

	const isEdit = props.isEdit
	const editTabs = useSelector(state => state.staff.editTabs)
	const activeTabIdx = useSelector(state => state.staff.activeTabIdx)


	const dispatch = useDispatch()
	const {isOpen, onOpen, onClose} = useDisclosure()

	const initialRef = React.useRef()
	const finalRef = React.useRef()

	React.useImperativeHandle(ref, () => ({

		openModal(val) {
			if (val) {
				dispatch(staffActions.setEditItemId(val))
			}
			onOpen()
		},

	}))

	const editItemId = useSelector(state => state.staff.editItemId)

	console.log(editItemId)

	const setActiveTabIdx = idx => {
		dispatch(staffActions.setActiveEditTabIdx(idx))
	}

	return (
		<>
			<div className='lift__profile_edit'>
				<Modal
					initialFocusRef={initialRef}
					finalFocusRef={finalRef}
					isOpen={isOpen}
					onClose={onClose}
					width={900}
				>
					<ModalOverlay />
					<ModalContent maxWidth={780}>

						<ModalHeader>
							{isEdit ? 'Редактировать сотрудника' : 'Добавить сотрудника'}
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>

							<Box mb={6} className='lift__profile_edit_formik'>
								<FormControl>
									<FormLabel>Сотрудник</FormLabel>
									<Select placeholder='Выберите сотрудника' size='md' color='gray.400'>
										<option>Первый сотрудник</option>
										<option>Второй сотрудник</option>
										<option>Третий сотрудник</option>
									</Select>
								</FormControl>
							</Box>

							<Box className='profile__edit_tabs'>
								{editTabs.map(tab =>
									<button key={tab.idx} className={`profile__edit_tab ${activeTabIdx === tab.idx && 'active'}`}
										onClick={() => setActiveTabIdx(tab.idx)}>
										{tab.title}
									</button>,
								)}
							</Box>

							{activeTabIdx === 0
								? <MainInfo initialRef={initialRef} editItemId={editItemId}></MainInfo>
								: activeTabIdx === 1
									? <DocsInfo initialRef={initialRef} editItemId={editItemId}></DocsInfo>
									: activeTabIdx === 2
										? <SecurityInfo initialRef={initialRef} editItemId={editItemId}></SecurityInfo>
										: activeTabIdx === 3
											? <AppointmentInfo initialRef={initialRef} editItemId={editItemId}></AppointmentInfo>
											: activeTabIdx === 4
												? <AccessInfo initialRef={initialRef} editItemId={editItemId}></AccessInfo> : ''
							}
						</ModalBody>
					</ModalContent>

				</Modal>
			</div>
		</>
	)
})

export default Edit
