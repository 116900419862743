import React from 'react'
import {Flex, Text, Menu, MenuButton, MenuItem, MenuList, Icon, Link} from '@chakra-ui/react'
import {MdKeyboardArrowDown} from 'react-icons/md'
import {
	Link as RouterLink,
	useHistory,
} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {authActions} from '../redux/actions'

const Header = () => {
	const user = useSelector(state => state.auth.user)
	const company = useSelector(state => state.auth.company)
	const history = useHistory()
	const dispatch = useDispatch()

	const logout = () => {
		dispatch(authActions.logout())
		history.push('/auth')
	}

	return (
		<header className='App-header'>
			<Flex justify='space-between' p={2} bg='secondary.500' color='white'>
				<div>
					<RouterLink to='/' className='lift__header_logo_container'>
						<img src={window.location.origin + '/images/logo.png'} alt='' className='lift__header_logo' />
						<Text className='lift__header_logo_text'>
                            Группа Компаний «ЛС Групп»
						</Text>
					</RouterLink>
				</div>
				<div className='lift__header_navbar_container'>
					<Menu isLazy>
						<MenuButton className='lift__navbar_link'>Рус <Icon as={MdKeyboardArrowDown} /></MenuButton>
						<MenuList color='black' minWidth='140px'>
							<MenuItem>Русский</MenuItem>
							<MenuItem>Қазақша</MenuItem>
							<MenuItem>English</MenuItem>
						</MenuList>
					</Menu>
					{
						!user || !company
							? (
								<div>
									<RouterLink to='/' className='lift__navbar_link line'></RouterLink>
								</div>
							)
							: (
								<div>
									{
										company
											? <RouterLink to={`/profile/edit/${company.id}`} className='lift__navbar_link line'>{company.name.replaceAll(company.name.match(/"+/), '')}</RouterLink>
											: <RouterLink to='/equipment' className='lift__navbar_link line'></RouterLink>

									}

									<span>{'|'}</span>
									<Link to='/auth' className='lift__navbar_link' onClick={logout}>Выйти</Link>
								</div>
							)
					}

				</div>
			</Flex>
		</header>
	)
}

export default Header
