import {authConstants} from '../../constants/auth.constants'

const user = JSON.parse(localStorage.getItem('user'))
const company = JSON.parse(localStorage.getItem('company'))
const token = localStorage.getItem('auth_token')

const initialState = {
	authData: {
		iin: '',
		password: '',
	},
	authResponse: {},
	registrationData: {
		email: '',
		password: '',
		iin: '',
		bin: '',
		roles: '0',
		firstName: '',
		lastName: '',
		middleName: '',
		chiefName: '',
	},
	registrationSignResponse: {
		email: 'tm_00@inbox.ru',
		iin: '',
		bin: '',
		token: '.eyJ1c2VyIjoiIiwicGFzcyI6IiIsImlhdCI6IjE2MjM3NjExMjIiLCJleHBpcmUiOiIxNjIzNzYyMzIyIn0.itK4KRDGz0a26pCNwogEgvd6mb3Hd0scKx5MSW4r6y0',
		roles: [{'Name': 'Installation', 'Title': 'Installation', 'Code': 1}, {'Name': 'Service', 'Title': 'Service', 'Code': 2}],
		firstName: '',
		lastName: '',
		middleName: '',
		chiefName: '',
	},
	isRegistrationSignOk: false,
	getDataBySignLoading: false,
	getDataFailureText: false,

	registerLoading: false,
	registerFailureText: false,

	authBySignLoading: false,

	authLoading: false,
	authFailureText: false,
	authLoaded: false,
	authStatus: '-1',

	authView: true,
	user: user || null,
	company: company || null,
	authToken: token || null,
}

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.SET_AUTH_DATA:
			return {
				...state,
				authData: action.payload,
			}
		case authConstants.SET_AUTH_RESPONSE:
			return {
				...state,
				authResponse: action.payload,
			}
		case authConstants.SET_AUTH_VIEW:
			return {
				...state,
				authView: action.payload,
			}
		case authConstants.SET_REGISTRATION_DATA:
			return {
				...state,
				registrationData: action.payload,
			}
		case authConstants.SET_REGISTRATION_SIGN_OK:
			return {
				...state,
				isRegistrationSignOk: action.payload,
			}
		case authConstants.SET_REGISTRATION_SIGN_RESPONSE:
			return {
				...state,
				registrationSignResponse: action.payload,
			}
		case authConstants.GET_DATA_BY_SIGN_SUCCESS:
			return {
				...state,
				getDataBySignLoading: false,
				getDataSuccessModal: true,
				getDataFailureModal: true,
				isRegistrationSignOk: true,
			}
		case authConstants.GET_DATA_BY_SIGN_STARTED:
			return {
				...state,
				getDataBySignLoading: true,
				getDataSuccessModal: false,
				getDataFailureModal: false,
				isRegistrationSignOk: false,
			}
		case authConstants.GET_DATA_BY_SIGN_FAILURE:
			return {
				...state,
				getDataBySignLoading: false,
				getDataSuccessModal: false,
				getDataFailureModal: true,
				getDataFailureText: action.payload.error,
				isRegistrationSignOk: false,
			}
		case authConstants.REGISTER_STARTED:
			return {
				...state,
				registerLoading: true,
			}
		case authConstants.REGISTER_SUCCESS:
			return {
				...state,
				registerLoading: false,
				authView: true,
			}
		case authConstants.REGISTER_FAILURE:
			return {
				...state,
				registerLoading: false,
				registerFailureText: action.payload.error,
			}
		case authConstants.AUTH_STARTED:
			return {
				...state,
				authLoading: true,
				authLoaded: false,
			}
		case authConstants.AUTH_SUCCESS:
			return {
				...state,
				authLoading: false,
				authLoaded: true,
				user: {
					...state.user,
					...action.payload.user,
					loggedIn: true,
				},
				company: {
					...state.company,
					...action.payload.company,
				},
				authStatus: action.payload.code,
			}
		case authConstants.AUTH_FAILURE:
			return {
				...state,
				authLoading: false,
				authLoaded: true,
				authFailureText: action.payload.message,
				authStatus: action.payload.code,
			}
		case authConstants.SET_AUTH_LOADED:
			return {
				...state,
				authLoaded: action.payload,
			}
		case authConstants.AUTH_BY_SIGN_STARTED:
			return {
				...state,
				authBySignLoading: true,
				authLoaded: false,
			}
		case authConstants.AUTH_BY_SIGN_SUCCESS:
			return {
				...state,
				authBySignLoading: false,
				authLoaded: true,
				uthStatus: action.payload.code,
			}
		case authConstants.AUTH_BY_SIGN_FAILURE:
			return {
				...state,
				authBySignLoading: false,
				authLoaded: true,
				uthStatus: action.payload.code,
			}
		case authConstants.SET_USER:
			return {
				...state,
				user: {
					...state.user,
					...action.payload,
				},
			}
		case authConstants.SET_LOGOUT:
			return {
				...state,
				user: {
					loggedIn: false,
				},
				company: null,
			}
		case authConstants.SET_AUTH_TOKEN:
			return {
				...state,
				authToken: state.payload,
			}
		default:
			return state
	}
}

export default authReducer
