import React, {useState, useEffect} from 'react'
import {useToast} from '@chakra-ui/react'
import {useSelector} from 'react-redux'
import {AsyncSelect} from 'chakra-react-select'
import {dictionaryActions} from '../../redux/actions'

const AsyncDictSelect = props => {
	const toast = useToast()
	const authToken = useSelector(state => state.auth.authToken)
	const [selectValue, setSelectValue] = useState(null)

	useEffect(async () => {
		if (props.selectedValue) {
			const values = await dictionaryActions.getDictionaryDataForSelect(props.dictionaryName, props.selectedValue, toast, authToken, true, props.additionalCondition)
			if (Array.isArray(values) && values.length > 0) {
				if (props.isMultiple) {
					setSelectValue(values)
				} else {
					setSelectValue(values[0])
				}
			}
		}
	}, [props.selectedValue])

	function validate(value) {
		let error
		if (!value && props.isRequired) {
			error = 'Данное поле является обязательным'
		}
		return error
	}

	const loadDictOptions = (searchValue, callback) => {
		setTimeout(async () => {
			callback(await filterDict(searchValue))
		}, 1000)
	}

	const filterDict = async searchValue => await dictionaryActions.getDictionaryDataForSelect(props.dictionaryName, searchValue, toast, authToken)

	const onAsyncSelectChange = value => {
		setSelectValue(value)
		if (props.onChange) {
			props.onChange(value ? value.value : null)
		}
	}

	return props.isEditMode
		? <AsyncSelect
			defaultOptions
			isClearable
			isDisabled={props.isDisabled}
			loadOptions={loadDictOptions}
			isMulti={props.isMultiple || false}
			placeholder={props.placeholder || 'Выберите из списка'}
			onChange={value => onAsyncSelectChange(value)}
			defaultValue={selectValue}
			value={selectValue} />
		: selectValue
			? selectValue.label
			: <div>-</div>
}

export default AsyncDictSelect
