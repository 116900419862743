import React, {useEffect, useState} from 'react'
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import {useSelector} from 'react-redux'
import {Base64} from 'js-base64'
import {userActions, dictionaryActions} from '../../../redux/actions'
import EditActsInfo from './EditActsInfo'
import EditActsSpecifications from './EditActsSpecifications'
import EditActsStatus from './EditActsStatus'
import EditActsPreview from './EditActsPreview'
import {passportService} from '../../../services'

const EditActsModal = React.forwardRef((props, ref) => {

	const initialRef = React.useRef()
	const finalRef = React.useRef()
	const editActsInfoRef = React.useRef()
	const editActsStatusRef = React.useRef()
	const editActsSpecificationsRef = React.useRef()
	const toast = useToast()

	const passportActsTabs = useSelector(state => state.passport.editPassportActsTabs)
	const authToken = useSelector(state => state.auth.authToken)
	const company = useSelector(state => state.auth.company)
	const user = useSelector(state => state.auth.user)

	const [activeTabIdx, setActiveTabIdx] = useState(0)
	const [actData, setActData] = useState({
		id: 0,
		number: '',
		owner: '',
		ownerId: 0,
		address: '',
		dictActTypesId: props.dictActTypesId ? props.dictActTypesId : 1,
		dictEquipmentAppointmentsId: props.dictEquipmentAppointmentsId ? props.dictEquipmentAppointmentsId : 1,
		dictStatusId: 1,
		dictEquipmentAppointment: null,
		installationOrganization: null,
		installationOrganizationRepresentative: null,
		buildingOrganization: null,
		buildingOrganizationRepresentative: null,
		electromechanic: '',
		electromechanicId: null,
		liftingCapacity: '',
		factoryNumber: '',
		ratedSpeed: '',
		manufactureYear: '',
		liftingHeight: '',
		stopsNumber: '',
	})
	const [actAdditionalSigners, setActAdditionalSigners] = useState([])
	const [isDisabled, setDisabled] = useState(false)

	const {onClose} = useDisclosure()

	useEffect(() => {
		if (props.selectedPassportAct) {
			if (!props.selectedPassportAct.number
				&& (Array.isArray(props.selectedPassportAct.passportActSigns) && props.selectedPassportAct.passportActSigns.length == 0
				|| !Array.isArray(props.selectedPassportAct.passportActSigns))) {
				passportService.getNextActNumber(authToken).then(res => {
					if (res.data.code === '0' && res.data.data) {
						setInitialData({
							...props.selectedPassportAct,
							number: res.data.data,
							electromechanic: props.selectedPassportAct.electromechanic
								? props.selectedPassportAct.electromechanic.fullname
								: '',
							owner: props.selectedPassportAct.owner
								? props.selectedPassportAct.owner.fullname
								: '',
						})
					} else {
						setInitialData({
							...props.selectedPassportAct,
							electromechanic: props.selectedPassportAct.electromechanic
								? props.selectedPassportAct.electromechanic.fullname
								: '',
							owner: props.selectedPassportAct.owner
								? props.selectedPassportAct.owner.fullname
								: '',
						})
					}
				},
				error => {
					console.log(error)
					setInitialData({
						...props.selectedPassportAct,
						electromechanic: props.selectedPassportAct.electromechanic
							? props.selectedPassportAct.electromechanic.fullname
							: '',
						owner: props.selectedPassportAct.owner
							? props.selectedPassportAct.owner.fullname
							: '',
					})
				})
			} else {
				setInitialData({
					...props.selectedPassportAct,
					electromechanic: props.selectedPassportAct.electromechanic
						? props.selectedPassportAct.electromechanic.fullname
						: '',
					owner: props.selectedPassportAct.owner
						? props.selectedPassportAct.owner.fullname
						: '',
				})
			}
			setDisabled(Array.isArray(props.selectedPassportAct.passportActSigns) && props.selectedPassportAct.passportActSigns.some(p => p.signedUserId == user.id))
		} else if (props.passportId && !actData.electromechanicId) {
			const requestData = {
				BIN: company.bin,
				passportId: props.passportId,
			}

			passportService.getPassportById(requestData, authToken).then(res => {
				if (res.data.code === '0' && res.data.data && res.data.data.createdUser && res.data.data.createdUser.fullname) {
					let sectionData = null
					if (res.data.data.content) {
						sectionData = JSON.parse(Base64.decode(res.data.data.content))
					}

					let initActData = null
					if (sectionData && sectionData.section_1) {
						initActData = {
							...actData,
							electromechanic: res.data.data.createdUser.fullname,
							electromechanicId: res.data.data.createdUser.id,
							owner: res.data.data.createdUser.fullname,
							ownerId: res.data.data.createdUser.id,
							factoryNumber: sectionData.section_1.factory_number,
							ratedSpeed: sectionData.section_1.speed,
							liftingHeight: sectionData.section_1.lifting_height,
							manufactureYear: sectionData.section_1.manufacture_date ? new Date(sectionData.section_1.manufacture_date).getFullYear() : '',
							liftingCapacity: sectionData.section_1.lifting_capacity,
							stopsNumber: sectionData.section_1.stops_number,
						}
					} else {
						initActData = {
							...actData,
							electromechanic: res.data.data.createdUser.fullname,
							electromechanicId: res.data.data.createdUser.id,
							owner: res.data.data.createdUser.fullname,
							ownerId: res.data.data.createdUser.id,
						}
					}
					passportService.getNextActNumber(authToken).then(result => {
						console.log('initActData', initActData)
						if (result.data.code === '0' && result.data.data) {
							initActData = {...initActData, number: result.data.data}
						}
						setInitialData({...initActData})
					},
					error => {
						setInitialData({...initActData})
						console.log(error)
					})
				}
			},
			error => {
				console.log(error)
			})
		}
	}, [])

	const setInitialData = initialActData => {
		setActData({
			...initialActData,
			id: initialActData.id,
			number: initialActData.number || '',
			owner: initialActData.owner || '',
			ownerId: initialActData.ownerId || 0,
			address: initialActData.address || '',
			dictActTypesId: initialActData.dictActTypesId || 1,
			dictEquipmentAppointmentsId: initialActData.dictEquipmentAppointmentsId,
			dictStatusId: initialActData.dictStatusId || 1,
			dictStatus: initialActData.dictStatus
				? {
					value: initialActData.dictStatus.id,
					label: initialActData.dictStatus.name,
				}
				: null,
			dictEquipmentAppointment: initialActData.dictEquipmentAppointment
				? {
					value: initialActData.dictEquipmentAppointment.id,
					label: initialActData.dictEquipmentAppointment.name,
				}
				: null,
			installationOrganization: initialActData.installationOrganization
				? {
					value: initialActData.installationOrganization.id,
					label: initialActData.installationOrganization.name,
				}
				: null,
			installationOrganizationRepresentative: initialActData.installationOrganizationRepresentative
				? {
					value: initialActData.installationOrganizationRepresentative.id,
					label: initialActData.installationOrganizationRepresentative.fullname,
				}
				: null,
			buildingOrganization: initialActData.buildingOrganization
				? {
					value: initialActData.buildingOrganization.id,
					label: initialActData.buildingOrganization.name,
				}
				: null,
			buildingOrganizationRepresentative: initialActData.buildingOrganizationRepresentative
				? {
					value: initialActData.buildingOrganizationRepresentative.id,
					label: initialActData.buildingOrganizationRepresentative.fullname,
				}
				: null,
			electromechanic: initialActData.electromechanic || '',
			electromechanicId: initialActData.electromechanicId || null,
			liftingCapacity: initialActData.liftingCapacity || '',
			factoryNumber: initialActData.factoryNumber || '',
			ratedSpeed: initialActData.ratedSpeed || '',
			manufactureYear: initialActData.manufactureYear || '',
			liftingHeight: initialActData.liftingHeight || '',
			stopsNumber: initialActData.stopsNumber || '',
		})
	}

	if (activeTabIdx > 3) {
		setActiveTabIdx(0)
	}

	const setTabData = (data, tabIdx) => {
		if (data) {
			setActData({...data})
		}

		if (tabIdx != null) {
			setActiveTabIdx(tabIdx)
		}
	}

	const setSigners = (data, tabIdx) => {
		if (data) {
			setActAdditionalSigners([...data])
		}

		if (tabIdx != null) {
			setActiveTabIdx(tabIdx)
		}
	}

	const changeActiveTabIdx = tabIdx => {
		setActiveTabIdx(tabIdx)

		if (editActsInfoRef.current) {
			editActsInfoRef.current.onTabChange()
		}
		if (editActsStatusRef.current) {
			editActsStatusRef.current.onTabChange()
		}
		if (editActsSpecificationsRef.current) {
			editActsSpecificationsRef.current.onTabChange()
		}
	}

	const onSaveClick = (isCloseModal, callback) => {
		let passportActSigns = []

		if (Array.isArray(actData.passportActSigns) && actData.passportActSigns.length > 0) {
			passportActSigns = [...actData.passportActSigns]
		} else if (actData.installationOrganizationRepresentative && actData.buildingOrganizationRepresentative) {
			passportActSigns = [
				{
					id: 0,
					signerUserId: user.id,
					passportActId: 0,
					dictStatusId: 1,
					passportActSignType: 1,
					created: new Date(),
					changed: new Date(),
				},
				{
					id: 0,
					signerUserId: actData.installationOrganizationRepresentative.value,
					passportActId: 0,
					dictStatusId: 1,
					passportActSignType: 1,
					created: new Date(),
					changed: new Date(),
				},
				{
					id: 0,
					signerUserId: actData.buildingOrganizationRepresentative.value,
					passportActId: 0,
					dictStatusId: 1,
					passportActSignType: 1,
					created: new Date(),
					changed: new Date(),
				},
			]
		}

		if (actAdditionalSigners.some(a => a.value && a.key.toString().indexOf('-') == -1)) {
			passportActSigns = [
				...passportActSigns.filter(p => !p.isAdditionalSigner || p.isAdditionalSigner && actAdditionalSigners.some(a => a.value && a.key == p.id)),
			]
		}

		if (actAdditionalSigners.some(a => a.value && a.key.toString().indexOf('-') > -1)) {
			passportActSigns = [
				...passportActSigns,
				...actAdditionalSigners.filter(a => a.value && a.key.toString().indexOf('-') > -1).map(a => ({
					id: 0,
					signerUserId: a.value.value,
					passportActId: props.selectedPassportAct ? props.selectedPassportAct.id : 0,
					dictStatusId: 1,
					passportActSignType: 1,
					created: new Date(),
					changed: new Date(),
					isAdditionalSigner: true,
				})),
			]
		}

		const requestData = {
			...actData,
			dictEquipmentAppointmentsId: actData.dictEquipmentAppointmentsId
				? actData.dictEquipmentAppointmentsId
				: actData.dictEquipmentAppointment
					? actData.dictEquipmentAppointment.value
					: null,
			installationOrganizationId: actData.installationOrganization
				? actData.installationOrganization.value
				: null,
			installationOrganizationRepresentativeId: actData.installationOrganizationRepresentative
				? actData.installationOrganizationRepresentative.value
				: null,
			buildingOrganizationId: actData.buildingOrganization
				? actData.buildingOrganization.value
				: null,
			buildingOrganizationRepresentativeId: actData.buildingOrganizationRepresentative
				? actData.buildingOrganizationRepresentative.value
				: null,
			passportId: props.passportId,
			passportActSigns,
		}

		console.log('passportActSigns', passportActSigns)

		passportService.setPassportAct(requestData, authToken).then(res => {
			if (res.data.code === '0') {
				toast({
					title: 'Сохранение акта',
					description: res.data.message,
					status: 'success',
					duration: 4000,
					isClosable: true,
				})

				if (callback) {
					passportService.sendPassportActToSign({passportActId: actData.id}, authToken).then(result => {
						if (result.data.code == '0') {
							callback()
						}
					})
				}

				if (isCloseModal) {
					handleModalClose()
				}
			} else {
				toast({
					title: 'Сохранение акта',
					description: res.data.message,
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			}
		},
		error => {
			console.log(error)
		},
		)
	}

	const onSignSuccess = () => {
		handleModalClose()
	}

	const onSignClick = () => {
		const requestData = {
			...actData,
			installationOrganizationId: actData.installationOrganization
				? actData.installationOrganization.value
				: null,
			installationOrganizationRepresentativeId: actData.installationOrganizationRepresentative
				? actData.installationOrganizationRepresentative.value
				: null,
			buildingOrganizationId: actData.buildingOrganization
				? actData.buildingOrganization.value
				: null,
			buildingOrganizationRepresentativeId: actData.buildingOrganizationRepresentative
				? actData.buildingOrganizationRepresentative.value
				: null,
			passportId: props.passportId,
		}
		passportService.setPassportAct(requestData, authToken).then(res => {
			if (res.data.code === '0') {
				toast({
					title: 'Подписание акта',
					description: res.data.message,
					status: 'success',
					duration: 4000,
					isClosable: true,
				})
				// setActData({...actData, electromechanic: res.data.data.createdUser.fullname})
				handleModalClose()
			} else {
				toast({
					title: 'Подписание акта',
					description: res.data.message,
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
			}
		},
		error => {
			console.log(error)
		},
		)
	}

	const onRejectClick = () => {
		const requestData = {
			...actData,
			installationOrganizationId: actData.installationOrganization
				? actData.installationOrganization.value
				: null,
			installationOrganizationRepresentativeId: actData.installationOrganizationRepresentative
				? actData.installationOrganizationRepresentative.value
				: null,
			buildingOrganizationId: actData.buildingOrganization
				? actData.buildingOrganization.value
				: null,
			buildingOrganizationRepresentativeId: actData.buildingOrganizationRepresentative
				? actData.buildingOrganizationRepresentative.value
				: null,
			passportId: props.passportId,
		}

		delete requestData.installationOrganizationRepresentative
		delete requestData.buildingOrganizationRepresentative
		delete requestData.installationOrganization
		delete requestData.buildingOrganization

		passportService.setPassportAct(requestData, authToken).then(res => {
			if (res.data.code === '0') {
				toast({
					title: 'Подписание акта',
					description: res.data.message,
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
				// setActData({...actData, electromechanic: res.data.data.createdUser.fullname})
				handleModalClose()
			}
		},
		error => {
			console.log(error)
		},
		)
	}

	const handleModalClose = () => {
		onClose()
		if (props.onModalClose) {
			props.onModalClose()
		}
	}

	return (<>
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			isOpen={props.isOpenModal}
			onClose={handleModalClose}
			width={900}
		>
			<ModalOverlay />
			<ModalContent maxWidth={780}>

				<ModalHeader>Редактировать акты</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Box className='passport_acts__edit_tabs'>
						{passportActsTabs.map(tab =>
							<button key={'passport_acts-' + tab.idx} className={`passport_acts__edit_tab ${activeTabIdx === tab.idx && 'active'}`} onClick={() => changeActiveTabIdx(tab.idx)}
							>
								{tab.title}
							</button>,
						)}
					</Box>
					{
						activeTabIdx === 0
							? <EditActsInfo
								ref={editActsInfoRef}
								actData={actData}
								setTabData={setTabData}
								isDisabled={isDisabled}
								passportId={props.passportId} />
							: activeTabIdx === 1
								? <EditActsSpecifications
									ref={editActsSpecificationsRef}
									actData={actData}
									isDisabled={isDisabled}
									setTabData={setTabData} />
								: activeTabIdx === 2
									? <EditActsStatus
										ref={editActsStatusRef}
										actData={actData}
										selectedActType={props.selectedActType}
										isDisabled={isDisabled}
										setSigners={setSigners}
										actAdditionalSigners={actAdditionalSigners} />
									: activeTabIdx === 3
										? <EditActsPreview
											actData={actData}
											setTabData={setTabData}
											onSaveClick={onSaveClick}
											onSignClick={onSignClick}
											onSignSuccess={onSignSuccess}
											onRejectClick={onRejectClick} />
										: null
					}
				</ModalBody>
			</ModalContent>

		</Modal>
	</>)
})

export default EditActsModal
