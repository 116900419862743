import React from 'react'
import {
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	FormErrorMessage,
	SimpleGrid,
	Textarea,
	Box,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Select,
} from '@chakra-ui/react'
import {useSelector, useDispatch} from 'react-redux'
import {Formik, Form, Field} from 'formik'

import Plus from '../../../components/Icons/Plus'

const AppointmentInfo = props => {
	const dispatch = useDispatch()
	const data = useSelector(state => state.staff.data)

	const {isOpen, onOpen, onClose} = useDisclosure()

	const initialRef = React.useRef()
	const finalRef = React.useRef()

	const submit = submitData => {
		console.log(submitData)
	}

	return (
		<>

			<Formik
				initialValues={data}
				enableReinitialize

				onSubmit={(values, actions) => {
					setTimeout(() => {
						submit(values)
						actions.setSubmitting(false)
					}, 1000)
				}}
			>
				<Form className='lift__profile_edit_formik'>

					<SimpleGrid columns={2} spacing={4} mt={4}>

						<Field name='position' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.position && touched.position}>
									<FormLabel>Выберите должность</FormLabel>
									<Select placeholder='Выберите должность' size='md' color='gray.400' {...field}>
										<option>Должность 1</option>
										<option>Должность 2</option>
									</Select>
									<FormErrorMessage>{errors.position}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Field name='object' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.object && touched.object}>
									<FormLabel>Выберите объект</FormLabel>
									<Select placeholder='Выберите объект' size='md' color='gray.400' {...field}>
										<option>Объект 1</option>
										<option>Объект 2</option>
									</Select>
									<FormErrorMessage>{errors.object}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

					</SimpleGrid>

					<SimpleGrid columns={2} spacing={4} mt={4}>
						<Field name='vacation' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.vacation && touched.vacation}>
									<FormLabel>Отпуск</FormLabel>
									<InputGroup>
										<Input type='text' placeholder='Отпуск' bg='white' autoComplete='off' {...field} />
									</InputGroup>
									<FormErrorMessage>{errors.vacation}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Box className='lift__appointment_edit_items'>
							<div className='lift__appointment_edit_item'>
								<div className='lift__appointment_edit_item_idx'>
								1
								</div>
								<div className='lift__appointment_edit_item_name'>
								Объект №213
								</div>
								<div className='lift__appointment_edit_item_level'>
								Уровень доступа 1
								</div>
							</div>

							<div className='lift__appointment_edit_item'>
								<div className='lift__appointment_edit_item_idx'>
								2
								</div>
								<div className='lift__appointment_edit_item_name'>
								Объект №234
								</div>
								<div className='lift__appointment_edit_item_level'>
								Уровень доступа 2
								</div>
							</div>

							<div className='lift__appointment_edit_item'>
								<div className='lift__appointment_edit_item_idx'>
								3
								</div>
								<div className='lift__appointment_edit_item_name'>
								Объект №756
								</div>
								<div className='lift__appointment_edit_item_level'>
								Уровень доступа 3
								</div>
							</div>
						</Box>
					</SimpleGrid>

					<Box mt='4' display='flex' justifyContent='flex-end' >
						<Button type='submit' colorScheme='blue' mr={3} isLoading={props.isSubmitting}>
						Сохранить
						</Button>
					</Box>
				</Form>
			</Formik>

		</>
	)
}

export default AppointmentInfo
