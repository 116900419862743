import React from 'react'
import {
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	FormErrorMessage,
	SimpleGrid,
	Textarea,
	Box,
	Button,
} from '@chakra-ui/react'
import {useSelector, useDispatch} from 'react-redux'
import {Formik, Form, Field} from 'formik'

import Plus from '../../../components/Icons/Plus'

const MainInfo = props => {
	const dispatch = useDispatch()
	const data = useSelector(state => state.profile.data)

	const submit = submitData => {
		console.log(submitData)
	}

	return (
		<>
			<Box className='lift__profile_edit_avatar'>
				<div className='lift__profile_edit_avatar_img'>
					<img src={window.location.origin + '/images/avatar.png'} alt='' />
					<div className='lift__profile_edit_avatar_plus'>
						<Plus />
					</div>
				</div>
			</Box>

			<Formik
				initialValues={data}
				enableReinitialize

				onSubmit={(values, actions) => {
					setTimeout(() => {
						submit(values)
						actions.setSubmitting(false)
					}, 1000)
				}}
			>
				<Form className='lift__profile_edit_formik'>
					<SimpleGrid columns={2} spacing={4} mt={4}>
						<Field name='leader_full_name' mr=''>
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.leader_full_name && touched.leader_full_name}>
									<FormLabel>Руководитель</FormLabel>
									<InputGroup>
										<Input type='text' placeholder='ФИО' bg='white' autoComplete='off' {...field} />
									</InputGroup>
									<FormErrorMessage>{errors.leader_full_name}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Field name='phone_numbers' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.phone_numbers && touched.phone_numbers}>
									<FormLabel>Контакты</FormLabel>
									<InputGroup>
										<Input type='text' bg='white' autoComplete='off' placeholder='+7 7__ ___ __ __' {...field} />
									</InputGroup>
									<FormErrorMessage>{errors.phone_numbers}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Field name='bin' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.bin && touched.bin}>
									<FormLabel>БИН</FormLabel>
									<InputGroup>
										<Input type='text' bg='white' autoComplete='off' placeholder='БИН' {...field} />
									</InputGroup>
									<FormErrorMessage>{errors.bin}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Field name='email' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.email && touched.email}>
									<FormLabel>E-mail</FormLabel>
									<InputGroup>
										<Input type='text' bg='white' autoComplete='off' placeholder='E-mail' {...field} />
									</InputGroup>
									<FormErrorMessage>{errors.email}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Field name='registration_date' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.registration_date && touched.registration_date}>
									<FormLabel>Дата регистрации</FormLabel>
									<InputGroup>
										<Input type='text' bg='white' autoComplete='off' placeholder='Дата регистрации' {...field} />
									</InputGroup>
									<FormErrorMessage>{errors.registration_date}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Field name='site' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.site && touched.site}>
									<FormLabel>Сайт</FormLabel>
									<InputGroup>
										<Input type='text' bg='white' autoComplete='off' placeholder='https://' {...field} />
									</InputGroup>
									<FormErrorMessage>{errors.site}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Field name='u_address' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.u_address && touched.u_address}>
									<FormLabel>Юр. Адрес</FormLabel>
									<InputGroup>
										<Textarea bg='white' placeholder='' {...field} />
									</InputGroup>
									<FormErrorMessage>{errors.u_address}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Field name='f_address' >
							{({
								field,
								form: {touched, errors},
							}) => (
								<FormControl isInvalid={errors.f_address && touched.f_address}>
									<FormLabel>Фактический адрес</FormLabel>
									<InputGroup>
										<Textarea bg='white' placeholder='' {...field} />
									</InputGroup>
									<FormErrorMessage>{errors.f_address}</FormErrorMessage>
								</FormControl>
							)}
						</Field>
					</SimpleGrid>

					<Box mt='4' display='flex' justifyContent='flex-end'>
						<Button type='submit' colorScheme='blue' mr={3} isLoading={props.isSubmitting}>
						Сохранить
						</Button>
					</Box>
				</Form>
			</Formik>
		</>
	)
}

export default MainInfo
