import React, {useEffect} from 'react'
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Button,
	Icon,
	useToast,
	Tooltip,
} from '@chakra-ui/react'
import {useSelector, useDispatch} from 'react-redux'
import convert from 'xml-js'
import {MdOutlineLocalPrintshop, MdOutlineFileDownload} from 'react-icons/md'
import QRCode from 'react-qr-code'
import moment from 'moment'
import 'moment/locale/ru'
import {userActions, ncaLayerActions} from '../../../redux/actions'
import {passportService} from '../../../services'

const EditActsPreview = React.forwardRef((props, ref) => {
	const toast = useToast()
	const dispatch = useDispatch()

	const authToken = useSelector(state => state.auth.authToken)
	const signResult = useSelector(state => state.ncalayer.signResult)
	const listenerType = useSelector(state => state.ncalayer.listenerType)
	const user = useSelector(state => state.auth.user)

	if (listenerType === 'SIGN' && signResult.code === '200') {
		let signHash = ''
		let digestValue = ''
		let signedX509Certificate = ''

		const signedData = convert.xml2js(signResult.responseObject)

		if (signedData && Array.isArray(signedData.elements) && signedData.elements.length > 0
		&& Array.isArray(signedData.elements[0].elements) && signedData.elements[0].elements.length > 1) {
			const sign = signedData.elements[0].elements[signedData.elements[0].elements.length - 1]

			if (sign && Array.isArray(sign.elements) && sign.elements.length > 1) {
				if (Array.isArray(sign.elements[0].elements) && sign.elements[0].elements.length > 1
				&& Array.isArray(sign.elements[0].elements[2].elements) && sign.elements[0].elements[2].elements.length > 1
				&& Array.isArray(sign.elements[0].elements[2].elements[2].elements) && sign.elements[0].elements[2].elements[2].elements.length > 0) {
					digestValue = sign.elements[0].elements[2].elements[2].elements[0].text.replace(/\r\n/g, '')
				}
				if (Array.isArray(sign.elements[1].elements) && sign.elements[1].elements.length > 0) {
					signHash = sign.elements[1].elements[0].text.replace(/\r\n/g, '')
				}
				if (Array.isArray(sign.elements[2].elements) && sign.elements[2].elements.length > 0
				&& Array.isArray(sign.elements[2].elements[0].elements) && sign.elements[2].elements[0].elements.length > 0
				&& Array.isArray(sign.elements[2].elements[0].elements[0].elements) && sign.elements[2].elements[0].elements[0].elements.length > 0) {
					signedX509Certificate = sign.elements[2].elements[0].elements[0].elements[0].text
				}
			}
		}

		if (signHash) {
			let passportActSignId = 0
			if (props.actData && Array.isArray(props.actData.passportActSigns) && props.actData.passportActSigns.some(p => p.signerUserId == user.id && p.dictStatusId == 4)) {
				passportActSignId = props.actData.passportActSigns.filter(p => p.signerUserId == user.id && p.dictStatusId == 4)[0].id
			}
			const requestData = {
				id: passportActSignId,
				passportActId: props.actData.id,
				sign: signHash,
				digestValue,
				signedX509Certificate,
			}
			console.log('requestData', requestData)
			console.log('props.actData', props.actData)
			passportService.signPassportAct(requestData, authToken).then(res => {
				if (res && res.data.code === '0') {
					toast({
						title: 'ЭЦП',
						description: res.data.message,
						status: 'success',
						duration: 4000,
						isClosable: true,
					})

					props.onSignSuccess()
				} else {
					toast({
						title: 'ЭЦП',
						description: 'Во время сохранения подписи произошла ошибка',
						status: 'error',
						duration: 4000,
						isClosable: true,
					})
				}
			},
			error => {
				console.log(error)
			},
			)
		} else {
			toast({
				title: 'ЭЦП',
				description: 'Хэш ЭЦП пустой',
				status: 'error',
				duration: 4000,
				isClosable: true,
			})
		}

		dispatch(ncaLayerActions.setListenerType(''))
		dispatch(ncaLayerActions.setSignResult({}))
	}

	const onPrevClick = () => {
		props.setTabData(null, 2)
	}

	const getActiveTokensSignAction = () => {
		// const actDataXml = convert.js2xml(getToSignObj(), {compact: true})
		// const actDataXml = JSON.stringify(getToSignObj()) Buffer.from('Hello world!', 'binary').toString('base64')
		/* eslint-disable-next-line */
		const base64 = Buffer.from(JSON.stringify(getToSignObj()), 'binary').toString('base64')
		console.log('base64', base64)
		dispatch(ncaLayerActions.getActiveTokens({
			signType: 'SIGNATURE',
			listenerType: 'SIGN',
			requestXML: `<data>${base64}</data>`,
		}))
	}

	const getToSignObj = () => {
		let toSignObj = {}
		const exclude = [
			'id',
			'created',
			'changed',
			'owner',
			'electromechanic',
		]
		for (var k in props.actData) {
			/* eslint-disable-next-line */
			if (props.actData.hasOwnProperty(k) && typeof props.actData[k] != 'object' && !exclude.some(e => e == k)) {
				// toSignObj[k] = {
				// 	'_text': props.actData[k],
				// }
				toSignObj[k] = props.actData[k]
			}
		}
		return toSignObj
	}

	const setDateTimeFormat = (date, format) => {
		if (date) {
			return moment(date.toString()).format(format || 'DD.MM.YYYY HH:mm')
		} else {
			return ''
		}
	}

	const printAct = () => 	{
		var mywindow = window.open('', 'PRINT', 'height=3508,width=2480')
		// mywindow.document.write(`<html><head><title>Акт ${props.actData && props.actData.id ? `№  ${props.actData.id}` : ''}</title>`)
		// mywindow.document.write('</head><body >')
		mywindow.document.write('<html><body >')
		mywindow.document.write(document.getElementById('passport_act').innerHTML)
		mywindow.document.write('</body></html>')

		mywindow.document.close() // necessary for IE >= 10
		mywindow.focus() // necessary for IE >= 10*/

		mywindow.print()
		mywindow.close()

		return true
	}

	return (<>
		<div className='lift__passport_acts_edit'>
			<Box className='lift__passport_content' p='4' bg='white' mb={4} mt={3} style={{color: 'darkgray'}}>
				<Tooltip hasArrow label='Распечатать'>
					<span style={{float: 'right'}}>
						<Icon as={MdOutlineLocalPrintshop} style={{width: '25px', height: '25px', cursor: 'pointer'}} onClick={printAct}/>
					</span>
				</Tooltip>
				<Tooltip hasArrow label='Скачать'>
					<span style={{float: 'right'}}>
						<Icon as={MdOutlineFileDownload} style={{width: '25px', height: '25px', cursor: 'pointer'}}/>
					</span>
				</Tooltip>
			</Box>
			<Box className='lift__passport_content' p='4' bg='white' mb={4} mt={3} id='passport_act' style={{textAlign: 'justify'}}>
				<Box style={{textAlign: 'center'}}>
					<p style={{fontWeight: 'bold'}}>Акт технической готовности лифта</p>
				</Box>
				<Box>
					<div style={{float: 'left'}}>
						<span style={{display: 'table-cell', width: '10px'}}>г.</span>
						<span style={{borderBottom: '1px solid black', width: '100px', display: 'table-cell', textAlign: 'center'}}>
							{
								Array.isArray(props.actData.passportActSigns)
								&& props.actData.passportActSigns.length > 0
								&& props.actData.passportActSigns.some(p => p.signedUserId == props.actData.createdUserId)
									? setDateTimeFormat(props.actData.passportActSigns.filter(p => p.signedUserId == props.actData.createdUserId)[0].changed, 'YYYY')
									: ''
							}
						</span>
					</div>
					<div style={{float: 'right'}}>
						<span style={{display: 'table-cell', width: '5px'}}>"</span>
						<span style={{borderBottom: '1px solid black', width: '20px', display: 'table-cell', textAlign: 'center'}}>
							{
								Array.isArray(props.actData.passportActSigns)
								&& props.actData.passportActSigns.length > 0
								&& props.actData.passportActSigns.some(p => p.signedUserId == props.actData.createdUserId)
									? setDateTimeFormat(props.actData.passportActSigns.filter(p => p.signedUserId == props.actData.createdUserId)[0].changed, 'DD')
									: ''
							}
						</span>
						<span style={{display: 'table-cell', width: '5px'}}>"</span>
						<span style={{borderBottom: '1px solid black', width: '80px', display: 'table-cell', textAlign: 'center'}}>
							{
								Array.isArray(props.actData.passportActSigns)
								&& props.actData.passportActSigns.length > 0
								&& props.actData.passportActSigns.some(p => p.signedUserId == props.actData.createdUserId)
									? setDateTimeFormat(props.actData.passportActSigns.filter(p => p.signedUserId == props.actData.createdUserId)[0].changed, 'MMMM')
									: ''
							}
						</span>
					</div>
				</Box>
				<Box style={{marginTop: '35px'}}>
					<div><p>Мы, нижеподписавшиеся, представитель организации, смонтировавшей лифт (выполнившей реконструкцию),</p></div>
					<div style={{marginTop: '20px'}}>
						<div style={{borderBottom: '1px solid black', width: '100%', minWidth: '100%', textAlign: 'center', fontWeight: 'bold'}}>
							{
								props.actData.installationOrganizationRepresentative
									? props.actData.installationOrganizationRepresentative.label
									: null
							}
						</div>
					</div>
				</Box>
				<Box style={{marginTop: '3px'}}>
					<p>и представитель генподрядной строительной организации</p>
					<div style={{marginTop: '20px'}}>
						<div style={{borderBottom: '1px solid black', width: '100%', minWidth: '100%', textAlign: 'center', fontWeight: 'bold'}}>
							{
								props.actData.buildingOrganizationRepresentative
									? props.actData.buildingOrganizationRepresentative.label
									: null
							}
						</div>
					</div>
				</Box>
				<Box style={{marginTop: '5px'}}>
					<p>составили настоящий акт о том, что завершены монтажные и наладочные работы, проведены осмотр, проверка и испытание лифта в объеме Правил обеспечения промышленной безопасности при эксплуатации грузоподъемных механизмов</p>
				</Box>
				<Box style={{marginTop: '25px'}}>
					<p>Лифт установлен по адресу: г.  </p>
					<div style={{marginTop: '20px'}}>
						<div style={{borderBottom: '1px solid black', width: '100%', minWidth: '100%', textAlign: 'center', fontWeight: 'bold'}}>
							{
								props.actData
									? props.actData.address
									: null
							}
						</div>
					</div>
				</Box>
				<Box style={{marginTop: '15px'}}>
					<p>назначение здания –</p>
				</Box>
				<Box style={{marginTop: '15px'}}>
					<p>Характеристика лифта</p>
				</Box>

				<Box style={{marginTop: '15px'}}>
					<Table variant='simple' style={{width: '100%', border: 'none'}}>
						<Tbody>
							<Tr>
								<Td style={{width: '50px', padding: '5px 0', border: 'none'}}>Тип</Td>
								<Td style={{padding: '5px 5px', border: 'none'}}>
									<div style={{borderBottom: '1px solid black', width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
										{
											props.actData && props.actData.dictEquipmentAppointment
												? props.actData.dictEquipmentAppointment.name || props.actData.dictEquipmentAppointment.label
												: null
										}
									</div>
									<div style={{marginLeft: '85px'}}>(пассажирский, грузовой и т.п.)</div>
								</Td>
								<Td style={{width: '30px', padding: '5px 0', border: 'none'}}></Td>
							</Tr>
							<Tr>
								<Td style={{width: '50px', padding: '5px 0', border: 'none'}}>Грузоподъемность</Td>
								<Td style={{padding: '5px 5px', border: 'none'}}>
									<div style={{borderBottom: '1px solid black', width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
										{
											props.actData
												? props.actData.liftingCapacity
												: null
										}
									</div>
								</Td>
								<Td style={{width: '30px', padding: '5px 0', border: 'none'}}>кг</Td>
							</Tr>
							<Tr>
								<Td style={{width: '50px', padding: '5px 0', border: 'none'}}>Номинальная скорость</Td>
								<Td style={{padding: '5px 5px', border: 'none'}}>
									<div style={{borderBottom: '1px solid black', width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
										{
											props.actData
												? props.actData.ratedSpeed
												: null
										}
									</div>
								</Td>
								<Td style={{width: '30px', padding: '5px 0', border: 'none'}}>м/с</Td>
							</Tr>
							<Tr>
								<Td style={{width: '50px', padding: '5px 0', border: 'none'}}>Высота подъема</Td>
								<Td style={{padding: '5px 5px', border: 'none'}}>
									<div style={{borderBottom: '1px solid black', width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
										{
											props.actData
												? props.actData.liftingHeight
												: null
										}
									</div>
								</Td>
								<Td style={{width: '30px', padding: '5px 0', border: 'none'}}>м</Td>
							</Tr>
							<Tr>
								<Td style={{width: '50px', padding: '5px 0', border: 'none'}}>Число остановок</Td>
								<Td style={{padding: '5px 5px', border: 'none'}}>
									<div style={{borderBottom: '1px solid black', width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
										{
											props.actData
												? props.actData.stopsNumber
												: null
										}
									</div>
								</Td>
								<Td style={{padding: '5px 0', border: 'none'}}></Td>
							</Tr>
							<Tr>
								<Td style={{width: '50px', padding: '5px 0', border: 'none'}}>Заводской номер</Td>
								<Td style={{padding: '5px 5px', border: 'none'}}>
									<div style={{borderBottom: '1px solid black', width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
										{
											props.actData
												? props.actData.factoryNumber
												: null
										}
									</div>
								</Td>
								<Td style={{padding: '5px 0', border: 'none'}}></Td>
							</Tr>
							<Tr>
								<Td style={{width: '50px', padding: '5px 0', border: 'none'}}>Год изготовления</Td>
								<Td style={{padding: '5px 5px', border: 'none'}}>
									<div style={{borderBottom: '1px solid black', width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
										{
											props.actData
												? props.actData.manufactureYear
												: null
										}
									</div>
								</Td>
								<Td style={{padding: '5px 0', border: 'none'}}></Td>
							</Tr>
						</Tbody>
					</Table>

				</Box>

				<Box style={{marginTop: '15px'}}>
					<p>Лифт прошел осмотр и проверку, выдержал испытания, находится в исправном состоянии и готов к приемке.</p>
				</Box>

				<Box style={{marginTop: '15px'}}>
					<Table variant='simple' style={{width: '100%', border: 'none'}}>
						<Tbody>
							<Tr>
								<Td style={{width: '350px', padding: '5px 0', border: 'none'}}>Представитель монтажной организации</Td>
								<Td style={{padding: '5px 0', border: 'none'}}>
									<div style={{borderBottom: '1px solid black', width: '100%', textAlign: 'center', fontWeight: 'bold', display: 'flex'}}>
										{
											props && props.actData && props.actData.dictStatusId == 5
												&& Array.isArray(props.actData.passportActSigns) && props.actData.passportActSigns.length > 0
												&& props.actData.installationOrganizationRepresentative
												&& props.actData.passportActSigns.some(p => p.signedUserId == props.actData.installationOrganizationRepresentative.value)
												? <>
													<div style={{marginRight: '10px'}}>
														<QRCode value='signed' size={50} />
													</div>
													<div style={{paddingLeft: '5px'}}>
														{
															props.actData.passportActSigns.filter(p => p.signedUserId == props.actData.installationOrganizationRepresentative.value)[0].signedUser.fullname
														}
													</div>
												</>
												: ''
										}
									</div>
									<span style={{marginLeft: '150px'}}>(подпись)</span>
								</Td>
							</Tr>
							<Tr>
								<Td style={{padding: '5px 0', width: '350px', border: 'none'}}>Представитель генподрядной строительной организации</Td>
								<Td style={{padding: '5px 0', border: 'none'}}>
									<div style={{borderBottom: '1px solid black', width: '100%', textAlign: 'center', fontWeight: 'bold', display: 'flex'}}>
										{
											props && props.actData && props.actData.dictStatusId == 5
												&& Array.isArray(props.actData.passportActSigns) && props.actData.passportActSigns.length > 0
												&& props.actData.buildingOrganizationRepresentative
												&& props.actData.passportActSigns.some(p => p.signedUserId == props.actData.buildingOrganizationRepresentative.value)
												? <>
													<div style={{marginRight: '10px'}}>
														<QRCode value='signed' size={50} />
													</div>
													<div style={{paddingLeft: '5px'}}>
														{
															props.actData.passportActSigns.filter(p => p.signedUserId == props.actData.buildingOrganizationRepresentative.value)[0].signedUser.fullname
														}
													</div>
												</>
												: ''
										}
									</div>
									<span style={{marginLeft: '150px'}}>(подпись)</span>
								</Td>
							</Tr>
						</Tbody>
					</Table>
				</Box>

			</Box>
		</div>
		<Box mt='8' display='flex' justifyContent='flex-end'>
			<Button colorScheme='gray' mr={3} onClick={onPrevClick}>
				Назад
			</Button>
			{
				(props.actData.id == 0 || props.actData.id == null
					|| props.actData.id > 0 && props.actData.createdUserId == user.id && props.actData.dictStatusId == 1) && <Button colorScheme='blue' mr={3} onClick={() => props.onSaveClick(true)}>
					Сохранить как черновик
				</Button>
			}
			{
				(Array.isArray(props.actData.passportActSigns)
				&& props.actData.passportActSigns.length > 0
				&& !props.actData.passportActSigns.some(p => p.signedUserId == user.id)
				|| Array.isArray(props.actData.passportActSigns)
				&& props.actData.passportActSigns.length == 0
				|| !props.actData.passportActSigns)
				&& <Button colorScheme='blue' mr={3} onClick={() => {
					Array.isArray(props.actData.passportActSigns) && props.actData.passportActSigns.length > 0
					&& props.actData.createdUserId > 0
					&& props.actData.passportActSigns.some(p => p.signerUserId == props.actData.createdUserId && p.dictStatusId == 4)
						? getActiveTokensSignAction()
						: props.onSaveClick(false, getActiveTokensSignAction)
				}
				}>
					Подписать{props.actData.id == 0 || props.actData.id == null || props.actData.id > 0 && props.actData.createdUserId == user.id ? ' и отправить' : ''}
				</Button>
			}

		</Box>
	</>
	)
})

export default EditActsPreview
