import React from 'react'
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	Icon,
} from '@chakra-ui/react'
import {MdExpandMore, MdPerson, MdBuild, MdStar, MdStarBorder, MdFilterList, MdModeEdit} from 'react-icons/md'

import Plus from '../../components/Icons/Plus'
import {useHistory} from 'react-router-dom'
import Edit from '../../components/Staff/Edit/Edit'

const StaffTable = props => {

	const isPage = props.isPage
	const history = useHistory()
	const [isEdit, setIsEdit] = React.useState(0)

	const goToStaffProfile = value => {
		history.push('/staff/profile/' + value)
	}

	const editRef = React.useRef()

	const openEdit = val => {
		if (editRef.current) {
			editRef.current.openModal(val)
		}
	}

	return (
		<Box className={`lift__profile_left_block lift__shadow ${!isPage && 'lift__profile_staff'}`}>
			<div className='lift__profile_staff_info'>
				<div className='lift__profile_staff_info_header'>
                    Сотрудники
				</div>

				<div className={`lift__profile_staff_info_filter ${isPage && 'change_position'}`}>
					<Icon as={MdFilterList} />
				</div>
				{isPage && (
					<div className='lift__profile_staff_info_add plus' onClick={() => {
						setIsEdit(false)
						openEdit(0)
					}}>
						<Plus />
					</div>
				)}
			</div>

			<div className='lift__profil_staff_table'>
				<Table variant='simple'>
					<TableCaption>Список сотрудников компании</TableCaption>
					<Thead>
						<Tr className='first'>
							<Th></Th>
							<Th>ФИО <Icon as={MdExpandMore} /></Th>
							<Th>Должность <Icon as={MdExpandMore} /></Th>
							<Th>Группа категории <Icon as={MdExpandMore} /></Th>
							<Th>Загруженность <Icon as={MdExpandMore} /></Th>
							<Th>Рейтинг </Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr onDoubleClick={() => goToStaffProfile(1)}>
							<Td>1</Td>
							<Td className='lift__staff_fio'>
								<img src={window.location.origin + '/images/avatar.png'} alt='' />
								<p>Аманжолов Аман</p>
							</Td>
							<Td>Электромеханик</Td>
							<Td>III группа</Td>
							<Td className='lift__staff_busy_td'>
								<Icon as={MdPerson} className='lift__staff_busy user' />
								<Icon as={MdBuild} className='lift__staff_busy build' />
							</Td>
							<Td>
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStarBorder} className='lift__star active' />
							</Td>
							<Td>
								<Icon as={MdModeEdit} className='lift__staff_edit' onClick={() => openEdit(1)} />
							</Td>
						</Tr>
						<Tr onDoubleClick={() => goToStaffProfile(2)}>
							<Td>2</Td>
							<Td className='lift__staff_fio'>
								<img src={window.location.origin + '/images/avatar.png'} alt='' />
								<p>Кайрат Кайратович</p>
							</Td>
							<Td>Электромеханик</Td>
							<Td>II группа</Td>
							<Td className='lift__staff_busy_td'>
								<Icon as={MdPerson} className='lift__staff_busy user success' />
								<Icon as={MdBuild} className='lift__staff_busy build success' />
							</Td>
							<Td>
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStarBorder} className='lift__star active' />
							</Td>
							<Td>
								<Icon as={MdModeEdit} className='lift__staff_edit' onClick={() => openEdit(2)} />
							</Td>
						</Tr>
						<Tr onDoubleClick={() => goToStaffProfile(3)}>
							<Td>3</Td>
							<Td className='lift__staff_fio'>
								<img src={window.location.origin + '/images/avatar.png'} alt='' />
								<p>Аманжолов Аман</p>
							</Td>
							<Td>Электромеханик</Td>
							<Td>III группа</Td>
							<Td className='lift__staff_busy_td'>
								<Icon as={MdPerson} className='lift__staff_busy user' />
								<Icon as={MdBuild} className='lift__staff_busy build' />
							</Td>
							<Td>
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStar} className='lift__star active' />
								<Icon as={MdStarBorder} className='lift__star active' />
							</Td>
							<Td>
								<Icon as={MdModeEdit} className='lift__staff_edit' onClick={() => openEdit(3)} />
							</Td>
						</Tr>
					</Tbody>
				</Table>
			</div>

			<Edit ref={editRef} isEdit={isEdit} />
		</Box>
	)
}


export default StaffTable
