import axios from 'axios'
import {apiConstants} from '../constants/api.constants'

export const requestsService = {
	getRequestsList,
	getRequestsCount,
}

function getRequestsList(requestData, authToken) {
	return axios
		.post(`${apiConstants.apiUrl}/api/Requests/GetCurrentUserRequests`, requestData, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(error => {
			if (error && (error.response && error.response.status == 401 || error.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}

function getRequestsCount(authToken) {
	return axios
		.get(`${apiConstants.apiUrl}/api/Requests/GetCurrentUserRequestsCount`, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}
			return res
		})
		.catch(error => {
			if (error && (error.response && error.response.status == 401 || error.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}
		})
}
