import React, {useState, useEffect} from 'react'
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Icon,
	useToast,
	Tooltip,
} from '@chakra-ui/react'
import {MdModeEdit, MdKeyboardArrowDown, MdKeyboardArrowLeft, MdSave} from 'react-icons/md'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'
import {passportActions} from '../../redux/actions'
import {passportConstants} from '../../constants/passport.constants'
import SectionItem from './SectionItem'

const Section = props => {
	const [expanded, setExpanded] = useState(false)
	const [isEditMode, setEditMode] = useState(false)
	const [isEdited, setIsEdited] = useState(false)
	const [sectionItems, setSectionItems] = useState(props.children)

	const dispatch = useDispatch()
	const toast = useToast()

	const currentPassportId = useSelector(state => state.passport.currentPassportId)
	const company = useSelector(state => state.auth.company)

	const setDateTimeFormat = date => {
		if (date) {
			return moment(date.toString()).format('DD.MM.YYYY HH:mm')
		} else {
			return '-'
		}
	}

	const updateSection = (values, property) => {
		dispatch(passportActions.setSection({
			property,
			values,
		}))

		if (currentPassportId > 0) {
			let payload = {
				BIN: company.bin,
			}
			dispatch(passportActions.setPassportById(payload, toast))
		} else {
			let payload = {
				BIN: company.bin,
			}
			dispatch(passportActions.addPassport(payload, toast))
		}

	}

	const onActionComplete = () => {
		if (isEditMode && !validate()) {
			toast({
				title: 'Паспорт',
				description: 'Не все обязательные поля заполнены',
				status: 'error',
				duration: 4000,
				isClosable: true,
			})
			return false
		}
		if (isEditMode && isEdited && props.fieldName) {
			updateSection(props.sectionData[props.fieldName], props.fieldName)
		}
		if (!expanded) {
			setExpanded(!expanded)
		}
		setEditMode(!isEditMode)
	}

	const validate = () => {
		let isValid = true
		if (props.sectionData[props.fieldName] && Array.isArray(sectionItems) && sectionItems.length > 0
			&& sectionItems.some(c => c.fieldType == passportConstants.FIELD_TYPE_ITEM)) {
			const validateSectionItems = sectionItems.map(c => {
				if (c.fieldType == passportConstants.FIELD_TYPE_ITEM && c.isRequired
					&& (props.sectionData[props.fieldName][c.fieldName] == null || props.sectionData[props.fieldName][c.fieldName] == '')) {
					c.isValid = false
					isValid = false
				} else {
					c.isValid = true
				}
				return c
			})
			setSectionItems([...validateSectionItems])
		}
		return isValid
	}

	const itemChange = (fieldName, value) => {
		if (props.sectionData[props.fieldName]) {
			if (typeof props.sectionData[props.fieldName][fieldName] == 'object' && typeof value == 'object') {
				props.sectionData[props.fieldName][fieldName] = {
					...props.sectionData[props.fieldName][fieldName],
					...value,
				}
			} else {
				props.sectionData[props.fieldName][fieldName] = value
			}
			setIsEdited(true)
		}
	}

	return (<>
		<Tr>
			<Td style={props.isChild ? {paddingLeft: '50px'} : null}>{props.numberTitle}</Td>
			<Td style={{fontWeight: 'bold'}}>{props.fieldTitle}</Td>
			<Td style={{color: 'blue'}}>
				<Tooltip hasArrow label={expanded ? 'Скрыть' : 'Раскрыть'}>
					<span>
						<Icon as={expanded ? MdKeyboardArrowLeft : MdKeyboardArrowDown} onClick={() => setExpanded(!expanded)}/>
					</span>
				</Tooltip>
			</Td>
			<Td>{props.currentPassportData && props.currentPassportData.changed ? setDateTimeFormat(props.currentPassportData.changed) : '-'}</Td>
			<Td>
				{
					props.currentPassportData && props.currentPassportData.editedUser
						? props.currentPassportData.editedUser.fullname
						: props.currentPassportData && props.currentPassportData.createdUser
							? props.currentPassportData.createdUser.fullname
							: '-'
				}
			</Td>
			<Td>
				{
					props.currentPassportData && props.currentPassportData.dictStatus
						? props.currentPassportData.dictStatus.name
						: '-'
				}
			</Td>
			<Td>
				<Tooltip hasArrow label={isEditMode ? 'Сохранить' : 'Редактировать'}>
					<span>
						{
							props.fieldName && !props.isEditMode && <Icon as={isEditMode ? MdSave : MdModeEdit} style={isEditMode ? {width: '20px', height: '20px', color: 'blue'} : null} onClick={onActionComplete}/>
						}
					</span>
				</Tooltip>
			</Td>
		</Tr>
		{
			props.isGrouped && Array.isArray(props.groups) && props.groups.length > 0 && expanded
				? <Tr>
					<Td colSpan={7}>
						<Table variant='simple' className='lift__eq_table'>
							<Thead>
								<Tr>
									{
										props.groups.map((group, ix) => <Th key={`section-${group.fieldName}-${ix}`} style={{padding: '5px 5px 5px 50px'}}>{group.groupTitle}</Th>)
									}
								</Tr>
							</Thead>
							<Tbody>
								{
									Array.isArray(sectionItems) && sectionItems.length > 0 && expanded
										? sectionItems.map((child, ix) => {
											if (child.fieldType == passportConstants.FIELD_TYPE_ITEM) {
												return <Tr key={`section-item-${props.fieldName}-${ix}`}>
													<SectionItem isEditMode={isEditMode} {...child} isGrouped={props.isGrouped} groups={props.groups} onChange={itemChange} data={props.sectionData[props.fieldName]}/>
												</Tr>
											} else {
												return <Section key={`section-${props.fieldName}-${ix}`} currentPassportData={props.currentPassportData} {...child} isChild={true} isEditMode={isEditMode} onChange={props.onChange} sectionData={props.sectionData} updateSection={updateSection} />
											}
										})
										: null
								}
							</Tbody>
						</Table>
					</Td>
				</Tr>
				: Array.isArray(sectionItems) && sectionItems.length > 0 && expanded
					? sectionItems.map((child, ix) => {
						if (child.fieldType == passportConstants.FIELD_TYPE_ITEM) {
							return <Tr key={`section-item-${props.fieldName}-${ix}`}>
								<SectionItem isEditMode={isEditMode} {...child} isGrouped={props.isGrouped} groups={props.groups} onChange={itemChange} data={props.sectionData[props.fieldName]}/>
							</Tr>
						} else {
							return <Section key={`section-${props.fieldName}-${ix}`} currentPassportData={props.currentPassportData} {...child} isChild={true} isEditMode={isEditMode} onChange={props.onChange} sectionData={props.sectionData} updateSection={updateSection} />
						}
					})
					: null
		}
	</>)
}

export default Section
