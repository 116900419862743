import {profileConstants} from '../../constants/profile.constants'

const initialState = {
	isEdit: false,
	mainTabs: [
		{
			title: 'О Компании',
			idx: 0,
		},
		{
			title: 'Сотрудники',
			idx: 1,
		},
		{
			title: 'Отзывы',
			idx: 2,
		},
	],
	mainActiveTabIdx: 0,
	editTabs: [
		{
			title: 'Личная информация',
			idx: 0,
		},
		{
			title: 'Документы',
			idx: 1,
		},
		{
			title: 'Безопасность',
			idx: 2,
		},
		{
			title: 'Общая информация',
			idx: 3,
		},
	],
	activeTabIdx: 0,
	data: {
		leader_full_name: '',
		phone_numbers: '',
		bin: '',
		registration_date: '',
		site: '',
		email: '',
		u_address: '',
		f_address: '',
		password: '',
		newPassword: '',
		newPasswordRepeat: '',
		generalInfo: '',
		equipment: '',
		newDocDate: '',
		newDocNumber: '',
	},
}

const profileReducer = (state = initialState, action) => {
	switch (action.type) {
		case profileConstants.SET_DATA:
			return {
				...state,
				data: action.payload,
			}
		case profileConstants.SET_IS_EDIT:
			return {
				...state,
				isEdit: action.payload,
			}
		case profileConstants.SET_ACTIVE_EDIT_TAB_IDX:
			return {
				...state,
				activeTabIdx: action.payload,
			}
		case profileConstants.SET_ACTIVE_MAIN_TAB_IDX:
			return {
				...state,
				mainActiveTabIdx: action.payload,
			}
		default:
			return state
	}
}

export default profileReducer
