import React, {useState, useEffect} from 'react'
import {
	Box,
	Text,
	Button,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Icon,
	IconButton,
	useToast,
} from '@chakra-ui/react'
import {Step, StepGroup, Stepper} from '@cimpress/react-components'
import {Formik, Form} from 'formik'
import Item from '../Item'
import {MdAdd, MdExpandMore} from 'react-icons/md'
import {useSelector, useDispatch} from 'react-redux'
import {passportActions} from '../../../redux/actions'
import {passportConstants} from '../../../constants/passport.constants'
import Section from '../Section'

const EditLiftSpecifications = React.forwardRef((props, ref) => {

	const [verticalActiveStep, setVerticalActiveStep] = React.useState(0)
	const [pageType, setPageType] = useState('edit')
	const [showEdit, setShowEdit] = useState(false)

	const setPassportByIdLoading = useSelector(state => state.passport.setPassportByIdLoading)
	const currentPassportId = useSelector(state => state.passport.currentPassportId)
	const dispatch = useDispatch()
	const toast = useToast()
	const user = useSelector(state => state.auth.user)
	const company = useSelector(state => state.auth.company)

	const currentPassportData = useSelector(state => state.passport.currentPassportData)
	const stateSectionData = useSelector(state => state.passport.sectionData)
	const listSpecifications = useSelector(state => state.passport.listSpecifications)

	const setVerticalStep = index => {
		setVerticalActiveStep(index)
	}

	const updateSection = (values, property) => {
		dispatch(passportActions.setSection({
			property,
			values,
		}))
		console.log(currentPassportId)

		if (pageType !== 'add') {
			let payload = {
				BIN: company.bin,
				createdUserId: user.id,
			}
			dispatch(passportActions.setPassportById(payload, toast))
		}

	}

	const addFieldAction = section => {
		dispatch(passportActions.addField({
			section,
		}))
	}

	const addItem = () => {
		let payload = {
			BIN: company.bin,
		}
		dispatch(passportActions.addPassport(payload, toast))
	}

	useEffect(() => {
		setPageType(props.match.params.type)

		if (props.match.params.id) {
			dispatch(passportActions.setCurrentPassportId(props.match.params.id))

			let payload = {
				BIN: company.bin,
				passportId: props.match.params.id,
			}

			dispatch(passportActions.getPassportById(payload))
		}
	}, [])

	return (<Box className='lift__passport_content' p='4' bg='white' mb={4} mt={3}>
		<div>
			<Box display='flex'>
				<Table variant='simple' className='lift__eq_table'>
					<Thead>
						<Tr>
							<Th></Th>
							<Th>Раздел <Icon as={MdExpandMore} /></Th>
							<Th></Th>
							<Th>Дата последнего изменения <Icon as={MdExpandMore} /></Th>
							<Th>Редактор <Icon as={MdExpandMore} /></Th>
							<Th>Статус <Icon as={MdExpandMore} /></Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{
							listSpecifications.map((child, ix) => <Section key={`section-${child.fieldName}-${ix}`} {...child} sectionData={stateSectionData} currentPassportData={currentPassportData} />)
						}
					</Tbody>
				</Table>
			</Box>
		</div>

	</Box>)
})

export default EditLiftSpecifications
