import React from 'react'
import {Box, Container} from '@chakra-ui/react'

const Footer = () => (
	<footer className='App-footer footer'>
		<Box p={2} bg='white' h={65}>
			<Container maxW='container.xl' h={'100%'}>
				<div className='lift__footer_logo_container'>
					<div className='lift__footer_logo_item'>
						<img src={window.location.origin + '/images/logo2.png'} alt='' className='lift__footer_logo' />
						<p>при поддержке</p>
					</div>
				</div>
				<div className='lift__footer_center'>
					<span className='lift__footer_center_text'>© 2021 «Группа Компаний «ЛС Групп»»</span>
				</div>
			</Container>
		</Box>
	</footer>
)

export default Footer
