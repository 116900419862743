import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import customTheme from './customTheme'
import {theme, ChakraProvider, CSSReset} from '@chakra-ui/react'
import {applyMiddleware, createStore} from 'redux'
import allReducers from './redux/reducers'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

const middleware = [
	thunk,
]

const store = createStore(
	allReducers,
	composeWithDevTools(
		applyMiddleware(...middleware),
		// other store enhancers if any
	),
)

ReactDOM.render(
	<Provider store={store}>
		<ChakraProvider theme={customTheme}>
			<CSSReset />
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</ChakraProvider>
	</Provider>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
