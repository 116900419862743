import axios from 'axios'
import {apiConstants} from '../../constants/api.constants'

export const dictionaryActions = {
	getDictionaryDataForSelect,
}

async function getDictionaryDataForSelect(dictionaryType, searchValue, toast, authToken, isByIds = false, additionalCondition) {
	return await axios
		.get(`${apiConstants.apiUrl}/api/Dictionary/GetDictionaryDataForSelect?dictionaryType=${dictionaryType}&searchValue=${searchValue}&isByIds=${isByIds || false}${additionalCondition || ''}`, apiConstants.authHeaders(authToken || localStorage.getItem('auth_token')))
		.then(res => {
			if (res && res.status == 401) {
				localStorage.clear()
				window.location.reload()
			}

			if (!res || res && res.data.code !== '0') {
				toast({
					title: 'Фильтр',
					description: 'Техническая ошибка. Попробуйте позже',
					status: 'error',
					duration: 4000,
					isClosable: true,
				})
				return []
			} else {
				return res.data.data
			}
		})
		.catch(err => {
			if (err && (err.response && err.response.status == 401 || err.status == 401)) {
				localStorage.clear()
				window.location.reload()
			}

			toast({
				title: 'Фильтр',
				description: `Техническая ошибка. ${err}`,
				status: 'error',
				duration: 4000,
				isClosable: true,
			})
			return []
		})
}
