import React from 'react'
import {
	Button,
	Select,
	Box,
	Input,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Spinner,
	Alert,
	AlertIcon,
	Icon,
	useToast,
} from '@chakra-ui/react'
import {MdFilterList, MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md'
import {useSelector, useDispatch} from 'react-redux'
import {passportActions, dictionaryActions} from '../redux/actions'
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/ru'

moment.locale('ru')

const Equipment = () => {
	const items = useSelector(state => state.passport.items)
	const getPassportListLoading = useSelector(state => state.passport.getPassportListLoading)
	const getPassportListLoaded = useSelector(state => state.passport.getPassportListLoaded)
	const authToken = useSelector(state => state.auth.authToken)
	const company = useSelector(state => state.auth.company)
	const history = useHistory()
	const dispatch = useDispatch()
	const toast = useToast()

	const goToPassport = value => {
		history.push('/passport' + value)
	}

	React.useEffect(() => {
		let payload = {
			BIN: company.bin,
		}
		dispatch(passportActions.setSectionDataInitial())
		dispatch(passportActions.getPassportList(payload, authToken))

		return () => {
		}
	}, [])

	const setDateTimeFormat = (date, format) => {
		if (date) {
			return moment(date.toString()).format(format || 'DD.MM.YYYY HH:mm')
		} else {
			return '-'
		}
	}

	return (
		<div>

			<div className='lift__eq_top'>

				<div className='lift__filter_item'>
					<Button colorScheme='primary' color='white' borderRightRadius='0' borderRadius='4px' height='41px' width='84%' onClick={() => goToPassport('/add')}>Добавить лифт</Button>
					<Button colorScheme='primary' color='white' borderRadius='0' border='0' borderLeft='1px' borderStyle='solid' borderLeftWidth='1px' borderColor='white' width='15%' height='41px'>
						<Icon as={MdFilterList} className='lift__eq_filter_icon' />
					</Button>
				</div>
				<div className='lift__filter_item'>
					<Select placeholder='Изготовитель' borderRadius='0' bg='white'>
						<option value='option1'>Option 1</option>
						<option value='option2'>Option 2</option>
						<option value='option3'>Option 3</option>
					</Select>
				</div>
				<div className='lift__filter_item'>
					<Select placeholder='Тип лифта' borderRadius='0' bg='white'>
						<option value='option1'>Option 1</option>
						<option value='option2'>Option 2</option>
						<option value='option3'>Option 3</option>
					</Select>
				</div>
				<div className='lift__filter_item'>
					<Select placeholder='Привод' borderRadius='0' bg='white'>
						<option value='option1'>Option 1</option>
						<option value='option2'>Option 2</option>
						<option value='option3'>Option 3</option>
					</Select>
				</div>
				<div className='lift__filter_item'>
					<Input placeholder='Заводской номер' borderLeftRadius='0' bg='white' _placeholder={{color: '#000'}} />
				</div>
			</div>

			<div className='lift__eq_table_container'>
				<Box bg='white' borderRadius='4px' mt={5} mb={5}>
					{getPassportListLoading && <Box p={4} textAlign='center'>
						<Spinner />
					</Box>
					}

					{!getPassportListLoading && getPassportListLoaded && <div>
						<Table variant='simple' className='lift__eq_table'>
							<Thead>
								<Tr>
									<Th isNumeric width='20px'>№</Th>
									<Th>Заводской номер</Th>
									<Th>Тип лифта</Th>
									<Th>Месяц и год изготовление</Th>
									<Th>Владелец</Th>
									<Th>Привод </Th>
								</Tr>
							</Thead>
							<Tbody>
								{
									items.length > 0
										? items.map((x, idx) => (
											<Tr key={idx} onClick={() => goToPassport('/edit/' + x.id)}>
												<Td isNumeric>{idx + 1}</Td>
												<Td>{x.factoryNumber ? x.factoryNumber : '-'}</Td>
												<Td>{x.dictEquipmentAppointment ? x.dictEquipmentAppointment.name : '-'}</Td>
												<Td>{setDateTimeFormat(x.manufactureDate, 'MMMM YYYY')}</Td>
												<Td>{x.createdUser && x.createdUser.fullname ? x.createdUser.fullname : '-'}</Td>
												<Td>{x.dictDriveUnit ? x.dictDriveUnit.name : '-'}</Td>
											</Tr>
										))
										: <Tr>
											<Td colSpan='6'>
												<Alert status='info'>
													<AlertIcon />
													На данный момент список пуст
												</Alert>
											</Td>
										</Tr>
								}

							</Tbody>
						</Table>
					</div>

					}

					{!getPassportListLoading && !getPassportListLoaded && items.length === 0 && (
						<Box>
							<Alert status='info'>
								<AlertIcon />
								Упс.. Что-то пошло не так. Не удалось получить данные с сервера. Попробуйте позже
							</Alert>

						</Box>
					)}
				</Box>


				{!getPassportListLoading && getPassportListLoaded && items.length > 0 && <Box mt={5} mb={5} className='lift__pagination'>
					<Box borderRadius='4px' className='lift__pagination_item'>
						<Icon as={MdKeyboardArrowLeft} className='lift__eq_filter_icon' />
					</Box>
					{/* <Box borderRadius="4px" className="lift__pagination_item">1</Box>
						<Box borderRadius="4px" className="lift__pagination_item">2</Box> */}
					<Box borderRadius='4px' className='lift__pagination_item active' border='1px' borderStyle='solid' borderColor='primary.500' color='primary.500'>1</Box>
					{/* <Box borderRadius="4px" className="lift__pagination_item">4</Box>
						<Box borderRadius="4px" className="lift__pagination_item">5</Box> */}
					<Box borderRadius='4px' className='lift__pagination_item'>
						<Icon as={MdKeyboardArrowRight} className='lift__eq_filter_icon' />
					</Box>
				</Box>}

			</div>

		</div>
	)

}

export default Equipment
